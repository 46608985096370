import React, { memo } from 'react';
import { bool, shape } from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import cn from 'classnames';

import classNames from './dropzone-progress.module.scss';

const enhancer = compose(
  memo,
  withPropsOnChange(
    ['progress'],
    ({ progress }) => ({
      contentStyle: {
        // Make sure the progress is between 0 and 100.
        width: `${Math.round(Math.min(100, Math.max(0, +progress)))}%`,
      },
    }),
  ),
);

const DropzoneProgress = ({ visible, contentStyle }) => (
  <div className={cn(classNames.progress, visible && classNames.visible)}>
    <div className={classNames.content} style={contentStyle} />
  </div>
);

DropzoneProgress.displayName = 'DropzoneProgress';

DropzoneProgress.propTypes = {
  visible: bool,
  contentStyle: shape().isRequired,
};

DropzoneProgress.defaultProps = {
  visible: false,
};

export default enhancer(DropzoneProgress);
