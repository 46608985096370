import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import ConnectedSuggestionCard from '../card/connectedSuggestionCard';
import ConnectedReplyCardList from '../reply/cardList/connectedReplyCardList';
import withRepliesConnect from '../connect/withReplies.connect';
import withExpandReplies from './withExpandReplies';

const ConnectedSuggestionRepliesCardList = compose(
  withRepliesConnect,
)(ConnectedReplyCardList);

const enhancer = compose(
  withExpandReplies,
  memo,
);

const SuggestionCardWithRepliesCard = ({
  repliesExpanded,
  onSetRepliesExpanded,
  onClickReply,
  ...props
}) => (
  <>
    <ConnectedSuggestionCard
      {...props}
      withReplies
      repliesExpanded={repliesExpanded}
      onSetRepliesExpanded={onSetRepliesExpanded}
      onClickReply={onClickReply}
    />

    { repliesExpanded
      && (
        <ConnectedSuggestionRepliesCardList
          {...props}
        />
      ) }
  </>
);

SuggestionCardWithRepliesCard.displayName = 'SuggestionCardWithRepliesCard';

SuggestionCardWithRepliesCard.propTypes = {
  repliesExpanded: PropTypes.bool.isRequired,
  onSetRepliesExpanded: PropTypes.func.isRequired,
  onClickReply: PropTypes.func,
};

SuggestionCardWithRepliesCard.defaultProps = {
  onClickReply: noop,
};

export default enhancer(SuggestionCardWithRepliesCard);
