import { defineMessages } from 'react-intl';

export default defineMessages({
  LONG_DESCRIPTION_TITLE: { id: 'product.form.fields.presentation.title', defaultMessage: 'Long description *' },

  LONG_DESC_HINT: {
    id: 'product.form.fields.presentation.hint',
    defaultMessage: 'Describe the main functional areas covered by your digital product into themes. Your description have to be written in english. The goal is not to list its business features because they will be filled in later...',
  },

  COMPONENT_LONG_DESC_HINT: {
    id: 'component.form.fields.presentation.hint',
    defaultMessage: 'Describe the main functional areas covered by your component into themes. Your description have to be written in english. The goal is not to list its business features because they will be filled in later...',
  },

  LONG_DESCRIPTION_PLACEHOLDER: {
    id: 'product.form.fields.presentation.placeholder',
    defaultMessage: 'This description will be visible in the presentation section of the product sheet.',
  },

  COMPONENT_LONG_DESCRIPTION_PLACEHOLDER: {
    id: 'component.form.fields.presentation.placeholder',
    defaultMessage: 'This description will be visible in the presentation section of the component sheet.',
  },
});
