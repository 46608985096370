import connect from 'react/hoc/connectProxy';

import { voteProductSuggestion } from 'redux/suggestions/actions';

/**
 * Requires a productId prop.
 * Exposes a onVoteProductSuggestion func bound to the productId prop.
 */
export default connect(
  null,
  (dispatch, props) => ({
    onVoteProductSuggestion: (suggestionId, vote) => dispatch(voteProductSuggestion(
      props.productId,
      suggestionId,
      vote,
    )),
  }),
);
