import { defineMessages } from 'react-intl';

export default defineMessages({
  DOMAIN_TITLE: { id: 'product.form.fields.domain.title', defaultMessage: 'Domain *' },

  DOMAIN_HINT: {
    id: 'product.form.fields.domain.hint',
    defaultMessage: 'Select the domain to which your digital product is related',
  },
});
