import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';

import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import ProductFormPresentationForm from 'react/business/products/form/steps/step-1/PresentationForm';
import ProductFormStepLayout from '../layout/StepLayout';
import { withProductFormName } from '../../../../business/products/form/Context';

const enhancer = compose(
  withProductFormName(),
  // Bind onChangeStep to features tab.
  withHandlers({
    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.FEATURES),

    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.REQUEST),
  }),

  memo,
);

const ProductFormPresentationStep = ({
  onClickNextStep,
  onClickPreviousStep,
  onOpenPreview,
}) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.PRESENTATION}
    form={<ProductFormPresentationForm />}
    onClickNextStep={onClickNextStep}
    onClickPreviousStep={onClickPreviousStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormPresentationStep.displayName = 'ProductFormPresentationStep';

ProductFormPresentationStep.propTypes = {
  onClickNextStep: PropTypes.func,
  onClickPreviousStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ProductFormPresentationStep.defaultProps = {
  onClickNextStep: noop,
  onClickPreviousStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ProductFormPresentationStep);
