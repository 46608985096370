import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { externalImageShape } from 'shapes/generic';

import FillPicture from 'react/generic/fillPicture/FillPicture';

import classNames from './preview.module.scss';

const Preview = ({
  picture,
  getInputProps,
  getRootProps,
  onBlur,
}) => (
  <div
    {...getRootProps()}
    className={classNames.preview}
  >
    <input {...getInputProps()} onBlur={onBlur} />

    <FillPicture
      src={picture.url}
      className={classNames.picture}
      alt="Logo preview"
    />
  </div>
);

Preview.displayName = 'Preview';

Preview.propTypes = {
  picture: externalImageShape.isRequired,
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

Preview.defaultProps = {
  onBlur: noop,
};

export default Preview;
