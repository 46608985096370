import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import { Tag, Icon } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';

import Input from 'react/generic/form/input/Input';
import IconButton from 'react/generic/button/IconButton';

import classNames from './editable-tag-group.module.scss';

const enchancer = compose(
  withState('inputValue', 'setInputValue', ''),
  memo,
);

const onTagEnter = {
  scale: 0.8,
  opacity: 0,
  type: 'from',
  duration: 100,
  onComplete: (e) => {
    e.target.style = '';
  },
};

const onTagLeave = {
  opacity: 0,
  width: 0,
  scale: 0,
  duration: 200,
};

class EditableTagGroup extends React.Component {
  handleClose = (removedTag) => {
    const { value, onChange } = this.props;
    const tags = value.filter(tag => tag !== removedTag);
    onChange(tags);
  };

  handleInputChange = e => this.props.setInputValue(e.target.value);

  handleInputConfirm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { value, inputValue, onChange } = this.props;

    if (inputValue && value.indexOf(inputValue) === -1) {
      const tags = [...value, inputValue];
      onChange(tags);
    }

    this.props.setInputValue('');
  };

  handleInputEnter = (e) => {
    if (e.key === 'Enter') this.handleInputConfirm(e);
  }

  renderTag = tag => (
    <span key={tag} className={classNames.tag}>
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    </span>
  );

  /**
   * Render the aliases components.
   *
   * @returns {object} - The React element.
   */
  render() {
    const { inputValue } = this.props;
    const { value } = this.props;
    return (
      <div>
        {/* removed the <form> because this is used in another form tag
        and it triggers dom warnings */}
        {/* use onKeyDown to handle enter key event */}
        <div className={classNames.aliasesContainer}>
          <div>
            <Input
              style={{ width: 78 }}
              value={inputValue}
              onChange={this.handleInputChange}
              onKeyDown={this.handleInputEnter}
            />
          </div>
          <div>
            <IconButton
              onClick={this.handleInputConfirm}
              className={classNames.buttonAddAlias}
            >
              <Icon type="plus" />
            </IconButton>
          </div>
        </div>
        <div className={classNames.tags}>
          <TweenOneGroup
            enter={onTagEnter}
            leave={onTagLeave}
            appear={false}
          >
            {value.map(this.renderTag)}
          </TweenOneGroup>
        </div>
      </div>
    );
  }
}

EditableTagGroup.displayName = 'EditableTagGroup';

EditableTagGroup.propTypes = {
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default enchancer(EditableTagGroup);
