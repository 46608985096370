import { compose, withPropsOnChange, withProps } from 'recompose';

import connect from 'react/hoc/connectProxy';
import {
  selectCommunitiesList,
} from 'redux/communities/selectors';

import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import findLabelInOptions from 'react/generic/form/autocomplete/findLabelInOptions';

import TooltipOption from 'react/generic/form/select/option/TooltipOption';
import { ENUM_PLACEMENTS } from 'react/generic/form/select/option/tooltipOption.constants';

import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

export const mapCommunities = c => ({
  value: c.id,
  label: c.name,
  description: c.description,
});

const EnhancedOption = compose(
  withProps({
    placement: ENUM_PLACEMENTS.LEFT,
  }),
)(TooltipOption);

export default compose(
  connect(
    state => ({
      communities: selectCommunitiesList(state).filter(el => !el.isInactive),
    }),
  ),

  withPropsOnChange(
    ['communities'],
    ({ communities }) => ({ options: communities.map(mapCommunities) }),
  ),

  withProps({
    components: {
      Option: EnhancedOption,
    },
  }),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,
  findLabelInOptions,
)(Autocomplete);
