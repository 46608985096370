import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { compose, renameProp } from 'recompose';

import { DebouncedReduxFormInput } from 'react/generic/form/input/Input';

const enhancer = compose(
  renameProp('field', 'feature'),
);

const FeaturesFormItem = ({ feature, ...props }) => (
  <Field
    name={`${feature}.label`}
    type="text"
    component={DebouncedReduxFormInput}
    {
      ...props
    }
  />
);

FeaturesFormItem.displayName = 'FeaturesFormItem';

FeaturesFormItem.propTypes = {
  feature: PropTypes.string.isRequired,
};

export default enhancer(FeaturesFormItem);
