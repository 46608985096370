import { defineMessages } from 'react-intl';

export default defineMessages({
  SCREENSHOTS_HINT: {
    id: 'pages.product.form.steps.2.screenshots.hint',
    defaultMessage: 'The screenshots of your product must be in jpg or png format and less than 10 MB.',
  },

  HEADER_TITLE_SCREENSHOTS: {
    id: 'pages.product.form.step.2.screenshots.header.title',
    defaultMessage: 'Product images',
  },
});
