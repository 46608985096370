import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';

import { screenshotsFactory } from 'services/products/products-form';

import UploadScreenshot from './UploadScreenshot';

import classNames from './screenshots-upload.module.scss';

class UploadScreenshots extends PureComponent {
  addDropArea = () => {
    const { fields } = this.props;
    fields.push(screenshotsFactory());
  };

  /** @returns {object} JSX. */
  render() {
    const { fields, ...props } = this.props;
    return (
      <>
        <div className={classNames.screenshotsArea}>
          {fields.map((field, index) => (
            <div key={field}>
              {
                  /*
              // Using item.id as a key may look like not a so good idea.
              // Indeed, when uploading a file, a new id will be generated.
              // Meaning the component will be recreated as the key changes.
              // Therefore, state handlers from withUploadLoader will
              // be called on unmounted component.
              // That's why I am using "field" as key.
              // On another hand, when reloading the form after the upload,
              // redux-form triggers UNREGISTER_FIELD, which destroys
              // the component and recreates it.
              // For now, this does not solve our potential memory leak issue.
                   */
                }
              <Field
                key={field}
                type="file"
                name={field}
                component={UploadScreenshot}
                props={{
                  fields,
                  field,
                  index,
                  ...props,
                }}
              />
            </div>
          ))}
          <div className={classNames.addArea}>
            <button
              type="button"
              onClick={this.addDropArea}
              className="unstyled with-pointer"
            >
              <Icon type="plus-circle" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

UploadScreenshots.displayName = 'UploadScreenshots';

UploadScreenshots.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

export default UploadScreenshots;
