import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import cn from 'classnames';

import { stepValueType } from './steps.shapes';

import classNames from './steps.module.scss';

export Step from './Step';
export withValueInRoute from './containers/withValueInRoute';
export withValueInState from './containers/withValueInState';

const enhancer = compose(
  /**
   * When on a step, it is necessary to make all previous steps 'done'.
   * So compute the index of each child value,
   * to check when rendering if the current step is before the active step.
   */
  withPropsOnChange(
    ['children'],
    ({ children }) => ({
      childrenValuesIndex: React.Children.toArray(children).reduce(
        (res, child, index) => {
          res[child.props.value] = index;
          return res;
        },
        {},
      ),
    }),
  ),

  memo,
);

const Steps = ({
  value,
  onChange,
  touchedValues,
  onTouch,
  children,
  childrenValuesIndex,
  className,
  nextStepsDisabled,
  ...props
}) => (
  <ul
    {...props}
    className={cn(
      className,
      classNames.steps,
    )}
  >
    {React.Children.toArray(children).map(
      child => (
        !child.props.value ?
          child
          : React.cloneElement(
            child,
            {
              onTouch,
              onClick: onChange,

              isCurrent: value === child.props.value,

              isTouched: (
                touchedValues.includes(child.props.value)
                || childrenValuesIndex[value] > childrenValuesIndex[child.props.value]
              ),

              isDisabled: (
                child.props.isDisabled
                || (
                  nextStepsDisabled
                  && childrenValuesIndex[value] < childrenValuesIndex[child.props.value]
                )
              ),
            },
          )
      ),
    )}
  </ul>
);

Steps.displayName = 'Steps';

Steps.propTypes = {
  value: stepValueType,
  onChange: PropTypes.func,
  touchedValues: PropTypes.arrayOf(stepValueType),
  onTouch: PropTypes.func,
  childrenValuesIndex: PropTypes.objectOf(PropTypes.number),
  className: PropTypes.string,
  children: PropTypes.node,
  nextStepsDisabled: PropTypes.bool,
};

Steps.defaultProps = {
  value: null,
  onChange: noop,
  touchedValues: [],
  onTouch: noop,
  className: null,
  children: null,
  childrenValuesIndex: {},
  nextStepsDisabled: false,
};

export default enhancer(Steps);
