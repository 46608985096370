import connect from 'react/hoc/connectProxy';
import { selectFilteredRepliesListById } from 'redux/suggestions/replies/selectors';

/**
 * Required props:
 * - productId (string).
 * - suggestion (suggestionShape).
 */
export default connect(
  (state, props) => ({
    // Direct replies.
    replies: selectFilteredRepliesListById(state, props.productId, props.suggestion.id, null),
  }),
);
