import connect from 'react/hoc/connectProxy';

import { selectUserLanguage } from 'redux/users/selectors';
import { getProductSuggestion } from 'redux/suggestions/actions';
import { ACTIONS } from 'redux/suggestions/constants';

/**
 * Requires a productId and suggestionId prop.
 * Exposes a onTranslate func.
 */
export default connect(
  state => ({
    lang: selectUserLanguage(state),
  }),
  (dispatch, props) => ({
    onTranslate: lang => dispatch(getProductSuggestion(
      props.productId,
      props.suggestionId,
      { lang },
    )),
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    onTranslate: async () => {
      const { type } = await dispatchProps.onTranslate(stateProps.lang);

      return {
        success: type === ACTIONS.GET_PRODUCT_SUGGESTION_SUCCESS,
      };
    },
  }),
);
