import { defineMessages } from 'react-intl';

export default defineMessages({
  FIELD_TITLE_PLACEHOLDER: {
    id: 'modules.form.field.title.placeholder',
    defaultMessage: 'Title',
  },
  FIELD_DESCRIPTION_PLACEHOLDER: {
    id: 'modules.form.field.description.placeholder',
    defaultMessage: 'Description',
  },
  FIELD_STATUS_PLACEHOLDER: {
    id: 'modules.form.field.status.placeholder',
    defaultMessage: 'Status',
  },
});
