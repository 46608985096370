import React, { memo } from 'react';
import {
  compose,
} from 'recompose';

import { withProductFormMode } from 'react/business/products/form/Context';
import { selectCommunitiesMap, selectDomainsMap } from 'redux/communities/selectors';

import { FormattedMessage } from 'react-intl';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';
import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';

import { productShape } from 'shapes/product';
import withProductFromFormValues from 'react/pages/product/form/withProductFromFormValues';
import { validationRejectedReasons, validationStatuses } from 'redux/products/constants';
import withCurrentUser from 'react/business/user/withCurrentUser/withCurrentUser.connect';
import { currentUserShape } from 'shapes/user';
import { change as changeAction } from 'redux-form';
import manifest from './presentation.manifest';

import messages from './presentation.messages';
import connect from '../../../../../hoc/connectProxy';
import { selectFormParentProduct } from '../../../../../../redux/products/selectors';
import { selectBusinessUnitsMap } from '../../../../../../redux/businessUnits/selectors';

const {
  fields: {
    community: { component: CommunityField },
    domain: { component: DomainField },
    isMaintainedByPlatform: { component: IsMaintainedByPlatformField },
    name: { component: NameField },
    alias: { component: AliasField },
    logo: { component: LogoField },
    shortDescription: { component: ShortDescriptionField },
    presentation: { component: PresentationField },
    businessUnitInCharge: { component: BusinessUnitInChargeField },
    contact: { component: ContactField },
    localDomain: { component: LocalDomainField },
  },
} = manifest;

const disableIsMaintainedByPlatform = (product, user) => {
  if (user?.isPrivileged) {
    return false;
  }
  // product is published
  if (product.isDraft === false) {
    return true;
  }
  // product was published before validation flow
  if (Date.parse(product.created) < Date.parse('17 Jan 2022 15:00:00 GMT')) {
    return false;
  }
  // product has no validation data
  if (!product.validation) {
    return false;
  }

  // product requires validation or was rejected
  if ([
    validationStatuses.RDLV,
    validationStatuses.RBDL,
    validationStatuses.RCA,
    validationStatuses.RBCA,
    validationStatuses.RBUA,
    validationStatuses.RBBUA,
  ].includes(product.validation?.status)) {
    return false;
  }

  // EDIT AS DOMAIN LEADER
  if (product.validation?.status === validationStatuses.PDL) {
    // On community dropdown select, domainId becomes undefined
    // and blocks domain selection
    if (!product.domainId) return false;

    return !user.domainsWhereUserIsLeader.find(el => el === product.domainId);
  }

  // EDIT AS COMMUNITY LEADER
  if (product.validation?.status === validationStatuses.PCA) {
    // same as above
    if (!product.domainId) return false;
    return !user.communitiesWhereUserIsArchitect.find(el => el === product.communityId);
  }

  // EDIT AS BU ARCHITECT
  if (product.validation?.status === validationStatuses.PBUA) {
    // same as above
    if (!product.businessUnitInCharge) return false;
    return !user.businessUnitsWhereUserIsArchitect.find(el => el === product.businessUnitInCharge);
  }

  // product is pending some validation
  if ([
    validationStatuses.APPROVED,
    validationStatuses.PDL,
    validationStatuses.PBUA,
    validationStatuses.PCA,
  ].includes(product.validation?.status)) {
    return true;
  }

  return false;
};

const disableCommunityAndDomain = (product, user) => {
  if (user?.isPrivileged) {
    return false;
  }
  // product is published
  if (product.isDraft === false) {
    return true;
  }
  // product was published before validation flow
  if (Date.parse(product.created) < Date.parse('17 Jan 2022 15:00:00 GMT')) {
    return false;
  }
  // product has no validation data
  if (!product.validation) {
    return false;
  }

  // product requires validation or was rejected
  if ([
    validationStatuses.RDLV,
    validationStatuses.RBDL,
    validationStatuses.RCA,
    validationStatuses.RBCA,
    validationStatuses.RBUA,
    validationStatuses.RBBUA,
  ].includes(product.validation?.status)) {
    return false;
  }

  // EDIT AS DOMAIN LEADER
  if (product.validation?.status === validationStatuses.PDL) {
    // On community dropdown select, domainId becomes undefined
    // and blocks domain selection
    if (!product.domainId) return false;

    return !user.domainsWhereUserIsLeader.find(el => el === product.domainId);
  }

  // EDIT AS COMMUNITY LEADER
  if (product.validation?.status === validationStatuses.PCA) {
    // same as above
    if (!product.domainId) return false;
    return !user.communitiesWhereUserIsArchitect.find(el => el === product.communityId);
  }

  // EDIT AS BU ARCHITECT
  if (product.validation?.status === validationStatuses.PBUA) {
    // same as above
    if (!product.businessUnitInCharge) return false;
    return !user.businessUnitsWhereUserIsArchitect.find(el => el === product.businessUnitInCharge);
  }

  return product.validation?.rejectedReason !== validationRejectedReasons.ID;
};

const enhancer = compose(
  withProductFormMode(),
  connect(
    state => ({
      formParentProduct: selectFormParentProduct(state),
      communities: selectCommunitiesMap(state),
      domains: selectDomainsMap(state),
      businessUnits: selectBusinessUnitsMap(state),
    }),
    dispatch => ({
      dispatch,
      change: changeAction,
    }
    ),
  ),
  withProductFromFormValues,
  withCurrentUser,
  memo,

);

const ProductFormPresentationForm = ({
  user: currentUser, product: productFromForm, ...props
}) => (
  <>
    <FieldsGroupHeader
      title={<FormattedMessage {...messages.HEADER_TITLE_PRESENTATION} />}
      message={<FormattedMessage {...messages.HEADER_MESSAGE_PRESENTATION} />}
      isComponent={false}
    />

    <NameField {...props} />

    <FieldsGroup>
      <IsMaintainedByPlatformField
        {...props}
        disabled={disableIsMaintainedByPlatform(productFromForm, currentUser)}
      />
    </FieldsGroup>

    <BusinessUnitInChargeField />

    <LocalDomainField {...props} />

    <FieldsGroup inline>
      <CommunityField
        {...props}
        disabled={disableCommunityAndDomain(productFromForm,
          currentUser)}
      />
      <DomainField
        {...props}
        disabled={disableCommunityAndDomain(productFromForm, currentUser)}
      />
    </FieldsGroup>

    <AliasField {...props} />

    <ContactField {...props} />

    <LogoField {...props} />

    <ShortDescriptionField {...props} />

    <PresentationField {...props} />
  </>
);

ProductFormPresentationForm.displayName = 'ProductFormPresentationForm';

ProductFormPresentationForm.propTypes = {
  user: currentUserShape.isRequired,
  product: productShape.isRequired,
};

export default enhancer(ProductFormPresentationForm);
