import {
  compose,
  withHandlers,
  withProps,
} from 'recompose';
import { isEmpty } from 'lodash';
import connect from 'react/hoc/connectProxy';
import { change as changeAction, blur as blurAction } from 'redux-form';
import { screenshotsFactory } from 'services/products/products-form';

import DropZoneField from 'react/generic/form/upload/DropzoneField';
import withUploadLoader from 'react/generic/form/upload/withUploadLoader';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';

import { postProductScreenshots } from 'services/products';
import { withProductFormName } from 'react/business/products/form/Context';
import withScreenshotUploadConfig from './withScreenshotUploadConfig';

const mapDispatchToProps = {
  change: changeAction,
  blur: blurAction,
};

const enhancer = compose(
  withScreenshotUploadConfig,
  withProductFormName(),

  connect(null, mapDispatchToProps),

  reduxFormInputAdapter,

  withHandlers(({
    index,
    field,
    formName,
    change,
    blur,
  }) => ({
    onDrop: ({ fields }) => async ([file] = [], onProgress) => {
      if (file) {
        const screenshot = await postProductScreenshots(file, onProgress);
        fields.remove(index);
        fields.insert(index, screenshot);

        blur(formName, fields);
      }
    },

    onRemove: ({ fields }) => () => {
      if (!isEmpty(fields.get(index))) {
        fields.remove(index);
        fields.insert(index, screenshotsFactory());

        blur(formName, fields);
      }
    },

    onChangeAlt: () => (e) => {
      change(formName, `${field}.alt`, e.target.value);
    },

    onBlur: ({ fields }) => () => blur(formName, fields),
  })),

  withUploadLoader,

  withProps({ withAltZone: true }),
);

export default enhancer(DropZoneField);
