import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { PulseSpinner } from 'react-spinners-kit';

import { architectureBuildingBlockShape } from 'shapes/architectureBuildingBlocks';
import theme from 'style/theme.variables.scss';

import ArchitectureBuildingBlocksListItem from '../listItem/ArchitectureBuildingBlocksListItem';

import messages from '../abb-selector.messages';
import classNames from '../abb-selector.module.scss';

const enhancer = compose(
  memo,
);

const ArchitectureBuildingBlocksList = ({
  items,
  value,
  loading,
  onToggleElement,
  header,
  onClickElement,
  selectedLineId,
  counters,
}) => (
  <div className={classNames.list}>
    <div className={classNames.listHeader}>
      {header}
    </div>
    <div className={classNames.listContainer}>
      {items.map(item => (
        <ArchitectureBuildingBlocksListItem
          item={item}
          key={item.id}
          checked={value?.includes(item.id)}
          onToggleElement={onToggleElement}
          onClickElement={onClickElement}
          active={selectedLineId === item.id}
          counter={counters[item.id]}
        />
      ))}

      {!loading && items.length === 0 && (
        <div className={classNames.noContentContainer}>
          <FormattedMessage {...messages.NO_ITEMS} />
        </div>
      )}
      {loading && (
        <div className={classNames.noContentContainer}>
          <PulseSpinner color={theme.primary} />
        </div>
      )}
    </div>
  </div>
);

ArchitectureBuildingBlocksList.displayName = 'ArchitectureBuildingBlocksList';

ArchitectureBuildingBlocksList.propTypes = {
  items: PropTypes.arrayOf(architectureBuildingBlockShape),
  value: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  onToggleElement: PropTypes.func,
  header: PropTypes.element,
  onClickElement: PropTypes.func,
  selectedLineId: PropTypes.string,
  counters: PropTypes.objectOf(PropTypes.number),
};

ArchitectureBuildingBlocksList.defaultProps = {
  items: [],
  value: null,
  loading: false,
  onToggleElement: noop,
  header: <FormattedMessage />,
  onClickElement: null,
  selectedLineId: null,
  counters: {},
};

export default enhancer(ArchitectureBuildingBlocksList);
