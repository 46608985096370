import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectArchitectureBuildingBlocksByCommunityId } from 'redux/architectureBuildingBlocks/selectors';
import { getArchitectureBuildingBlocksByCommunityId } from 'redux/architectureBuildingBlocks/actions';

export default compose(
  connect(
    (state, props) => ({
      abbs: selectArchitectureBuildingBlocksByCommunityId(state, props.communityId),
    }),
    {
      getArchitectureBuildingBlocksByCommunityId,
    },
  ),

  lifecycle({
    /**
     * Load all ABBs for the community.
     *
     * @async
     */
    async componentDidMount() {
      await this.props.getArchitectureBuildingBlocksByCommunityId(this.props.communityId);
    },
  }),
);
