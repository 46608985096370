import React, { memo } from 'react';
import { compose } from 'recompose';

import { TAG_TYPES } from 'config/constants';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import AutoComplete from 'react/business/products/form/autocompletes/Tags';

import messages from './devices.messages';

const enhancer = compose(
  memo,
);

const TagsDevicesField = () => (
  <Field
    name="tags.devices"
    component={AutoComplete}
    title={<FormattedMessage {...messages.DEVICES_TITLE} />}
    tagType={TAG_TYPES.DEVICES}
  />
);

TagsDevicesField.displayName = 'TagsDevicesField';

export default enhancer(TagsDevicesField);
