import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';
import { Tooltip } from 'antd';

import Button from 'react/generic/button/Button';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import cn from 'classnames';
import classNames from './edit-button.module.scss';

const enhancer = compose(
  memo,
);

const ContributeButton = ({
  onClickEdit, message, overrideIcon, pageType,
}) => (
  <Tooltip title={message}>
    <Button
      onClick={onClickEdit}
      overrideClassName={cn(!pageType ? classNames.buttonEdit : classNames[`buttonEdit--${pageType}`])}
      overrideClassNames={classNames}
      pageType={pageType}
    >
      <Asset
        name={overrideIcon || ICON_MAP.edit}
        className={classNames.icon}
      />
      <div>
        {message}
      </div>
    </Button>
  </Tooltip>
);

ContributeButton.propTypes = {
  onClickEdit: PropTypes.func,
  message: PropTypes.node,
  overrideIcon: PropTypes.string,
  pageType: PropTypes.string,
};

ContributeButton.defaultProps = {
  onClickEdit: noop,
  message: null,
  overrideIcon: null,
  pageType: '',
};

export default enhancer(ContributeButton);
