import { noop } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { createForm, formShape } from 'rc-form';

import { editorStateToHtml, htmlToEditorState } from 'react/generic/form/wysiwyg/wysiwyg.utils';

export default (WrappedComponent) => {
  const enhancer = compose(
    createForm(),
  );

  class CreateReplyFormContainer extends Component {
    static propTypes = {
      form: formShape.isRequired,
      onSubmit: PropTypes.func,
    };

    static defaultProps = {
      onSubmit: noop,
    };

    onSubmit = (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit({
            ...values,
            content: editorStateToHtml(values.content),
          });
        }
      });
    };

    canSubmit = () => this.props.form.getFieldValue('content')?.getCurrentContent()?.hasText();

    /** @returns {object} JSX. */
    render() {
      const {
        form,
        ...props
      } = this.props;

      return (
        <WrappedComponent
          {...props}
          onSubmit={this.onSubmit}
          contentDecorator={form.getFieldDecorator(
            'content',
            {
              initialValue: htmlToEditorState(),
            },
          )}
          canSubmit={this.canSubmit()}
        />
      );
    }
  }

  return enhancer(CreateReplyFormContainer);
};
