import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { noop } from 'lodash';

import { businessUnitShape } from 'shapes/businessUnit';
import { productShape } from 'shapes/product';
import { versionShape, deploymentShape } from 'shapes/version';

import withReferents from 'react/business/businessUnits/card/enhancers/withReferents';
import withDeployments from 'react/business/businessUnits/card/enhancers/withDeployments';
import BusinessUnitCard from 'react/business/businessUnits/card/BusinessUnitCard';

const EnhancedBusinessUnitCard = compose(
  withReferents,
  withDeployments,
)(BusinessUnitCard);

const enhancer = compose(
  withPropsOnChange(
    ['businessUnits', 'product'],
    ({ businessUnits, product }) => {
      const reducer = (accumulator, currentValue) => {
        if (currentValue.id === product?.businessUnitInCharge) {
          accumulator.unshift(currentValue);
        } else {
          accumulator.push(currentValue);
        }
        return accumulator;
      };
      const orderedBusinessUnits = businessUnits.reduce(reducer, []);
      return ({
        orderedBusinessUnits,
      });
    },
  ),

  memo,
);

const ProductBusinessUnits = ({
  orderedBusinessUnits,
  onClickBusinessUnit,
  itemsDisplayCount,
  deployments,
  version,
  canEditProduct,
  onOpenEditDeploymentDialog,
  productId,
  buInCharge,
}) => (orderedBusinessUnits || [])
  // sort BUs by isInactive - false first then true
  .sort((a, b) => Number(a.isInactive) - Number(b.isInactive))
  .slice(0, itemsDisplayCount)
  .map(businessUnit => (
    <EnhancedBusinessUnitCard
      businessUnit={businessUnit}
      key={businessUnit.id}
      onClick={onClickBusinessUnit}
      isInCharge={buInCharge === businessUnit.id}
      compact
      deployment={deployments.find(dep => dep.buReference === businessUnit.id)}
      version={version}
      canEditProduct={canEditProduct}
      onOpenEditDeploymentDialog={onOpenEditDeploymentDialog}
      productId={productId}
      isInactive={businessUnit.isInactive}
    />
  ));

ProductBusinessUnits.displayName = 'ProductBusinessUnits';

ProductBusinessUnits.propTypes = {
  businessUnits: PropTypes.arrayOf(businessUnitShape),
  onClickBusinessUnit: PropTypes.func,
  product: productShape,
  version: versionShape,
  deployments: PropTypes.arrayOf(deploymentShape),
  canEditProduct: PropTypes.bool,
  onOpenEditDeploymentDialog: PropTypes.func,
  productId: PropTypes.string,
  buInCharge: PropTypes.string,
};

ProductBusinessUnits.defaultProps = {
  businessUnits: [],
  onClickBusinessUnit: noop,
  product: null,
  version: null,
  deployments: [],
  canEditProduct: false,
  onOpenEditDeploymentDialog: noop,
  productId: '',
  buInCharge: null,
};

export default enhancer(ProductBusinessUnits);
