import React, { memo } from 'react';
import { compose } from 'recompose';

import { TAG_TYPES } from 'config/constants';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import AutoComplete from 'react/business/products/form/autocompletes/Tags';

import messages from './misc.messages';

const enhancer = compose(
  memo,
);

const TagsMiscField = () => (
  <Field
    name="tags.misc"
    component={AutoComplete}
    menuPlacement="top"
    title={<FormattedMessage {...messages.MISC_TITLE} />}
    tagType={TAG_TYPES.MISC}
  />
);

TagsMiscField.displayName = 'TagsMiscField';

export default enhancer(TagsMiscField);
