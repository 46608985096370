import { defineMessages } from 'react-intl';

export default defineMessages({
  HOSTING_TYPE_TITLE: {
    id: 'product.form.fields.hostingType.title',
    defaultMessage: 'Hosting type',
  },

  HOSTING_TYPE_HINT: {
    id: 'product.form.fields.hostingType.hint',
    defaultMessage: `
    Select the current hosting type of your digital product.
  
    Click here to understand :
    
    `,
  },
  HOSTING_TYPE_LINK: {
    id: 'product.form.fields.hostingType.link',
    defaultMessage: 'Hosting Types',
  },
});
