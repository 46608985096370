import {
  compose,
  withHandlers,
  renameProp,
  withProps,
} from 'recompose';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';
import { ACTIONS as REPLIES_ACTIONS } from 'redux/suggestions/replies/constants';
import { selectUserById, selectUserLanguage } from 'redux/users/selectors';
import {
  selectCanUserEditSuggestion,
  selectCanUserDeleteSuggestion,
} from 'redux/suggestions/selectors';

import withUserLazyLoading from '../../user/lazyLoad/withUserLazyLoading';
import getRepliesConnect from '../reply/connect/getReplies.connect';
import voteProductSuggestionConnect from './voteProductSuggestion.connect';
import createReplyConnect from '../reply/createReplyForm/createReply.connect';
import translateSuggestionConnect from './translateSuggestion.connect';
import withRepliesConnect from './withReplies.connect';

/**
 * Required props:
 * - productId (string).
 * - suggestion (suggestionShape).
 * - onSetRepliesExpanded (func (bool) => void). Not exposed to child component.
 */
export default compose(
  // Load replies on click of the replies toggle.
  compose(
    getRepliesConnect,

    withHandlers({
      onClickExpandReplies: ({
        onGetReplies,
        suggestion,
        onSetRepliesExpanded,
      }) => async (shouldExpand) => {
        if (shouldExpand) {
          const { type } = await onGetReplies(suggestion.id);
          if (type === REPLIES_ACTIONS.GET_REPLIES_SUCCESS) {
            onSetRepliesExpanded(true);
          }
        } else {
          onSetRepliesExpanded(false);
        }
      },
    }),

    omitProps(['onGetReplies', 'onSetRepliesExpanded']),
  ),

  // Add vote suggestion callback and rename it to onVote.
  compose(
    voteProductSuggestionConnect,
    renameProp('onVoteProductSuggestion', 'onVote'),
  ),

  // Lazy load and get author from store.
  compose(
    // Get author from the store.
    connect(
      (state, props) => ({
        author: selectUserById(state, props.suggestion?.author),
      }),
    ),

    // Lazy load the suggestion author.
    withUserLazyLoading({
      getIdsToLoadFromProps: props => [props.suggestion?.author].filter(Boolean),
    }),
  ),

  compose(
    // Prop is suggestion.id but createReplyConnect requires suggestionId.
    withProps(props => ({
      suggestionId: props.suggestion.id,
    })),
    // Add create reply method.
    createReplyConnect,
    // Add translate method.
    translateSuggestionConnect,

    omitProps(['suggestionId']),
  ),

  connect(
    (state, props) => ({
      withTranslateButton: selectUserLanguage(state) !== props.suggestion.contentLanguage,
    }),
  ),

  compose(
    withRepliesConnect,

    connect(
      (state, props) => ({
        canEditSuggestion: selectCanUserEditSuggestion(
          state,
          props.suggestion,
        ),
        canDeleteSuggestion: selectCanUserDeleteSuggestion(
          state,
          props.suggestion,
        ),
      }),
    ),
  ),
);
