import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Header from './Header';

import classNames from './layout.module.scss';

const enhancer = compose(
  memo,
);

const ProductFormLayout = ({ steps, children }) => (
  <>
    <Header />

    <div className={classNames.steps}>
      {steps}
    </div>

    {children}
  </>
);

ProductFormLayout.displayName = 'ProductFormLayout';

ProductFormLayout.propTypes = {
  steps: PropTypes.node,
  children: PropTypes.node,
};

ProductFormLayout.defaultProps = {
  steps: null,
  children: null,
};

export default enhancer(ProductFormLayout);
