import React, { memo } from 'react';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router';
import {
  branch,
  compose,
  lifecycle,
  renderNothing,
} from 'recompose';
import connect from 'react/hoc/connectProxy';
import { Helmet } from 'react-helmet';

import ROUTES from 'react/routes';
import { getProduct, removeProductFromMap } from 'redux/products/actions';
import { getBusinessUnits } from 'redux/businessUnits/actions';
import { selectCanEditProduct, selectDetailedProductById } from 'redux/products/selectors';
import { getCommunities } from 'redux/communities/actions';
import { selectCommunityOfDomain } from 'redux/communities/selectors';

import ProductEditFormPage from 'react/pages/product/form/edit/ProductEditFormPage';
import withStepInRoute from 'react/generic/steps/containers/withValueInRoute';
import withRouteParams from 'react/hoc/withRouteParams';
import { getUsersByExternalIds } from 'redux/users/actions';
import withExternalUserLazyLoading from 'react/business/user/lazyLoad/withExternalUserLazyLoading';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import userCanAccess from 'react/hoc/canAccess';

import { FormattedMessage } from 'react-intl';
import withSubmitHandlers from '../submit-handlers';

import messages from './product-edit-form.messages';

const enhancer = compose(
  withRouter,

  withRouteParams(params => ({
    productId: params.productId,
  })),

  // Step in URL get/set.
  withStepInRoute(
    // Get step from URL.
    ({ match }) => match.params.step,
    // Set step in URL.
    (step, { history, productId }) => history.push(
      formatRoute(
        ROUTES.PRODUCT.EDIT_FORM,
        {
          productId,
          step,
        },
      ),
    ),
    // Map value/onChange to step/OnChangeStep
    {
      propsMapper: {
        value: 'step',
        onChange: 'onChangeStep',
      },
      omitRouterProps: false,
    },
  ),

  connect(
    (state, props) => {
      const product = selectDetailedProductById(state, props.productId);
      return {
        product,
        community: selectCommunityOfDomain(state, product?.domainId),
      };
    },
    {
      getProduct,
      getUsersByExternalIds,
      getCommunities,
      removeProductFromMap,
      getBusinessUnits,
    },
  ),

  withSubmitHandlers,

  lifecycle({
    /**
     * Remove the product from redux -> products -> map
     * to make sure the next time you re-enter the page
     * the latest values are fetched from API
     * and updated properly
     * otherwise old values will be displayed if the page
     * is not refreshed.
     */
    componentWillMount() {
      this.props.removeProductFromMap(this.props.product?.id);
    },

    /** Load product on mount. */
    componentDidMount() {
      this.props.getProduct(this.props.productId);
      this.props.getCommunities({ populateDomains: true });
      this.props.getBusinessUnits();
    },
  }),

  // Avoid creating the form if the product isn't ready and the community is not loaded.
  branch(
    ({ product, community }) => !product || !community,
    renderNothing,
  ),

  connect(
    (state, props) => ({
      userCanEditProduct: selectCanEditProduct(state, props.productId),
    }),
  ),

  userCanAccess(
    props => !!props.userCanEditProduct,
    { errorMessage: <FormattedMessage {...messages.ACCESS_DENIED} /> },
  ),

  // Load users in team members (we need their picture and name).
  withExternalUserLazyLoading({
    getIdsToLoadFromProps: ({ product }) => (product.teamMembers || [])
      .map(tm => tm.externalId)
      .filter(Boolean),
  }),

  memo,
);

const EnhancedProductEditFormPage = enhancer(ProductEditFormPage);
EnhancedProductEditFormPage.displayName = 'EnhancedProductEditFormPage';

const ProductEditFormView = () => (
  <>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <EnhancedProductEditFormPage />
  </>
);
ProductEditFormView.displayName = 'ProductEditFormView';

export default ProductEditFormView;
