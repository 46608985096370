import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers, lifecycle } from 'recompose';
import cn from 'classnames';
import parseDuration from 'parse-duration';
import connect from 'react/hoc/connectProxy';

import Layer from 'react/generic/layer/Layer';
import { productShape } from 'shapes/product';
import { domainShape, communityShape } from 'shapes/community';
import { selectDomainsMap, selectCommunitiesMap } from 'redux/communities/selectors';
import { getDomain } from 'redux/communities/actions';

import Button from 'react/generic/button/Button';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import { FormattedMessage } from 'react-intl';
import ProductPage from '../../productPage/ProductPage';

import messages from './preview.messages';
import classNames from './preview.module.scss';
import sassVars from './preview.variables.scss';

// Animation duration in ms.
const defaultAnimationDuration = parseDuration(sassVars.previewAnimationDuration);

const enhancer = compose(
  withHandlers({
    overlayClassName: () => ({ isClosing, isOpen }) => cn(
      classNames.overlay,
      (isOpen && !isClosing) && classNames.isOpen,
      isClosing && classNames.isClosing,
    ),
  }),

  connect(
    (state, props) => {
      const domain = selectDomainsMap(state)[props.product?.domainId];
      const community = selectCommunitiesMap(state)[domain?.community?.id];
      return {
        domain,
        community,
      };
    },
    {
      getDomain,
    },
  ),

  // Load product on mount, and display nothing until loaded.
  lifecycle({
    /** Load product on mount. */
    async componentDidMount() {
      if (this.props.product?.domainId) {
        await this.props.getDomain(this.props.product.domainId, { populateCommunity: true });
      }
    },
  }),

  memo,
);

const ProductFormPreview = ({
  product,
  domain,
  community,
  ...props
}) => (
  <Layer
    {...props}
  >
    {({ isOpen, isClosing }) => (
      <div
        className={cn(
          classNames.previewContainer,
          (isOpen && !isClosing) && classNames.isOpen,
          isClosing && classNames.isClosing,
        )}
      >
        { (isOpen || isClosing)
          && (
            <>
              <div className={classNames.productPageContainer}>
                <ProductPage
                  isPreview
                  product={product}
                  domain={domain}
                  community={community}
                />
              </div>

              <div className={classNames.buttonContainer}>
                <Button
                  onClick={props.onRequestClose}
                  className={classNames.closeButton}
                >
                  <Asset
                    name={ICON_MAP.eye_off}
                    className={classNames.icon}
                  />
                  <FormattedMessage {...messages.LEAVE_PREVIEW} />
                </Button>
              </div>
            </>
          )}
      </div>
    )}
  </Layer>
);

ProductFormPreview.displayName = 'ProductFormPreview';

ProductFormPreview.propTypes = {
  product: productShape,
  domain: domainShape,
  community: communityShape,
  onRequestClose: PropTypes.func,
  animationDuration: PropTypes.number,
  /** See Layer props. */
};

ProductFormPreview.defaultProps = {
  product: null,
  domain: null,
  community: null,
  onRequestClose: noop,
  animationDuration: defaultAnimationDuration,
};

export default enhancer(ProductFormPreview);
