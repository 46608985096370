import { compose, withStateHandlers } from 'recompose';

export default compose(
  withStateHandlers(
    () => ({
      isPreviewOpen: false,
    }),
    {
      onOpenPreview: () => () => ({ isPreviewOpen: true }),
      onClosePreview: () => () => ({ isPreviewOpen: false }),
    },
  ),
);
