import { defineMessages } from 'react-intl';

export default defineMessages({
  REQUEST_TITLE: { id: 'product.form.steps.titles.request', defaultMessage: 'Request' },
  PRESENTATION_TITLE: { id: 'product.form.steps.titles.presentation', defaultMessage: 'Step 1' },
  PRESENTATION_SUBTITLE: { id: 'product.form.steps.subtitles.presentation', defaultMessage: 'Presentation' },
  FEATURES_TITLE: { id: 'product.form.steps.titles.features', defaultMessage: 'Step 2' },
  FEATURES_SUBTITLE: { id: 'product.form.steps.subtitles.features', defaultMessage: 'Features' },
  LINKS_TITLE: { id: 'product.form.steps.titles.links', defaultMessage: 'Step 3' },
  LINKS_SUBTITLE: { id: 'product.form.steps.subtitles.links', defaultMessage: 'Links' },
  VALIDATION_TITLE: { id: 'product.form.steps.titles.validation', defaultMessage: 'Validation' },
});
