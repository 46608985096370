import { defineMessages } from 'react-intl';

export default defineMessages({
  PUBLISH: { id: 'product.form.validation.publish', defaultMessage: 'Publish' },
  VALIDATE: { id: 'product.form.validation.validate', defaultMessage: 'Validate' },
  REJECT: { id: 'product.form.validation.reject', defaultMessage: 'Reject' },
  SUBMIT_FOR_DL_VALIDATION: { id: 'product.form.validation.submit.for.dl.validation', defaultMessage: 'Submit for Domain Leader validation' },
  SUBMIT_FOR_CA_VALIDATION: { id: 'product.form.validation.submit.for.ca.validation', defaultMessage: 'Submit for Platform Architect validation' },
  SUBMIT_FOR_BUA_VALIDATION: { id: 'product.form.validation.submit.for.bua.validation', defaultMessage: 'Submit for Business Unit Architect validation' },
  LEAVE: { id: 'product.form.validation.leave', defaultMessage: 'Leave' },

  LAST_STEP_CREATE: {
    id: 'product.form.validation.header.create',
    defaultMessage: 'Your product is ready to be published.',
  },

  LAST_STEP_EDIT: {
    id: 'product.form.validation.header.edit',
    defaultMessage: 'Save your changes',
  },

  CREATE_PUBLISH_NOTICE: {
    id: 'product.form.validation.create.publishNotice',
    defaultMessage: 'You can publish your product now by clicking on "Publish", or come back later and publish it when you want by clicking on "Leave", your draft will be available in your space, in the "My Products" tab.',
  },

  PREVIEW_MESSAGE: {
    id: 'product.form.validation.seePreview',
    defaultMessage: 'You can get a preview on how your product will look like by clicking here: {previewButton}',
  },

  PUBLISH_HINT: {
    id: 'pages.product.form.step.7.publish',
    defaultMessage: "By publishing your digital product, you make it visible to all Tangram users and enrich the ecosystem of the group's digital products.",
  },

  DRAFT_HINT: {
    id: 'pages.product.form.step.7.draft',
    defaultMessage: 'Your digital product has been saved as a draft. Find it in your profile, in the "My products" tab.',
  },

  DRAFT_FAILED_HINT: {
    id: 'pages.product.form.step.7.draft.failed',
    defaultMessage: 'Your product could not be saved as a draft. You can try to publish your product instead.',
  },

  SUBMIT_FAILED: {
    id: 'pages.product.form.step.7.submitFailed',
    defaultMessage: 'Your product could not be submitted due to validation errors.',
  },

  RDLV_HEADER_HINT: {
    id: 'product.form.header.hint.RDLV_HEADER_HINT',
    defaultMessage: 'Product requires Domain Leader validation',
  },

  RCA_HEADER_HINT: {
    id: 'product.form.header.hint.RCA_HEADER_HINT',
    defaultMessage: 'Product requires Platform Architect validation',
  },

  RBUA_HEADER_HINT: {
    id: 'product.form.header.hint.RBUA_HEADER_HINT',
    defaultMessage: 'Product requires Business Unit Architect validation',
  },

  PDL_HEADER_HINT: {
    id: 'product.form.header.hint.PDL_HEADER_HINT',
    defaultMessage: 'Product is pending Domain Leader validation',
  },

  PCA_HEADER_HINT: {
    id: 'product.form.header.hint.PCA_HEADER_HINT',
    defaultMessage: 'Product is pending Platform Architect validation',
  },

  PBUA_HEADER_HINT: {
    id: 'product.form.header.hint.PBUA_HEADER_HINT',
    defaultMessage: 'Product is pending Business Unit Architect validation',
  },

  RBDL_HEADER_HINT: {
    id: 'product.form.header.hint.RBDL_HEADER_HINT',
    defaultMessage: 'Product rejected by Domain Leader',
  },

  RBCA_HEADER_HINT: {
    id: 'product.form.header.hint.RBCA_HEADER_HINT',
    defaultMessage: 'Product rejected by Platform Architect',
  },

  RBBUA_HEADER_HINT: {
    id: 'product.form.header.hint.RBBUA_HEADER_HINT',
    defaultMessage: 'Product rejected by Business Unit Architect',
  },

  RDLV_MAIN_HINT: {
    id: 'product.form.header.hint.RDLV_MAIN_HINT',
    defaultMessage: 'Your product must be validated by the Domain Leader before it can be published. Submit for validation.',
  },

  RBUA_MAIN_HINT: {
    id: 'product.form.header.hint.RBUA_MAIN_HINT',
    defaultMessage: 'Your product must be validated by the Business Unit Architect before it can be published. Submit for validation.',
  },

  RCA_MAIN_HINT: {
    id: 'product.form.header.hint.RCA_MAIN_HINT',
    defaultMessage: 'Your product must be validated by the Community Architect before it can be published. Submit for validation.',
  },

  PDL_MAIN_HINT: {
    id: 'product.form.header.hint.PDL_MAIN_HINT',
    defaultMessage: 'Before publishing a product, the Domain Leader must check the information validity.',
  },

  PCA_MAIN_HINT: {
    id: 'product.form.header.hint.PCA_MAIN_HINT',
    defaultMessage: 'Before publishing a product, the Platform Architect must check the information validity.',
  },

  PBUA_MAIN_HINT: {
    id: 'product.form.header.hint.PBUA_MAIN_HINT',
    defaultMessage: 'Before publishing a product, the Business Unit Architect must check the information validity.',
  },

  RBDL_MAIN_HINT: {
    id: 'product.form.header.hint.RBDL_MAIN_HINT',
    defaultMessage: 'The product was rejected by the Domain Leader. Check your email for details and make sure you change the wrong information and then submit again for validation.',
  },

  RBCA_MAIN_HINT: {
    id: 'product.form.header.hint.RBCA_MAIN_HINT',
    defaultMessage: 'The product was rejected by the Platform Architect. Check your email for details and make sure you change the wrong information and then submit again for validation.',
  },

  RBBUA_MAIN_HINT: {
    id: 'product.form.header.hint.RBBUA_MAIN_HINT',
    defaultMessage: 'The product was rejected by the Business Unit Architect. Check your email for details and make sure you change the wrong information and then submit again for validation.',
  },

  INCOMPLETE_PRODUCT: {
    id: 'product.form.header.hint.INCOMPLETE_PRODUCT',
    defaultMessage: 'Please fill in all the required fields first',
  },

  RESEND_VALIDATION_REQUEST: {
    id: 'product.form.header.hint.RESEND_VALIDATION_REQUEST',
    defaultMessage: 'Resend validation request',
  },

  TOAST_VALIDATION: {
    id: 'product.form.validation.toast',
    defaultMessage: 'You validated (productName).',
  },

  VALIDATION_REQUEST_TIMEOUT: {
    id: 'product.form.validation.validation_request_timeout',
    defaultMessage: 'You will be able to resend your validation request in {hoursLeftUntilResend} hours',
  },
});
