import React from 'react';
import PropTypes from 'prop-types';
import { without, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import memoize from 'memoize-one';

import { activityShape, atomicActivityShape, macroActivityShape } from 'shapes/businessActivities';
import { numericSorterInObject } from 'services/utils';

import businessMessages from 'config/business.messages';
import BusinessActivitiesSelectorList from './list/BusinessActivitiesSelectorList';
import classNames from './business-activities-selector.module.scss';

const sorterByOrder = numericSorterInObject('displayOrder');

export default class BusinessActivitiesSelector extends React.PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,

    macroActivitiesMap: PropTypes.objectOf(macroActivityShape),
    activitiesMap: PropTypes.objectOf(activityShape),
    atomicActivitiesMap: PropTypes.objectOf(atomicActivityShape),

    onSelectMacroActivity: PropTypes.func,
    onSelectActivity: PropTypes.func,

    className: PropTypes.string,

    isLoadingMacroActivity: PropTypes.bool,
    isLoadingActivity: PropTypes.bool,
    isLoadingAtomicActivity: PropTypes.bool,
  };

  static defaultProps = {
    macroActivitiesMap: {},
    activitiesMap: {},
    atomicActivitiesMap: {},
    onSelectMacroActivity: noop,
    onSelectActivity: noop,
    className: '',
    isLoadingMacroActivity: false,
    isLoadingActivity: false,
    isLoadingAtomicActivity: false,
  };

  state = {
    counterCalculated: false,
    counterActivities: {},
    counterMacroActivities: {},
    selectedMacroActivityId: null,
    selectedActivityId: null,
  };

  convertMacroActivities = memoize(
    macroActivities => Object.values(macroActivities)
      .sort(sorterByOrder),
  );

  filterActivities = memoize(
    (activities, macroActivityId) => Object.values(activities)
      .filter(a => a.macroActivityId === macroActivityId)
      .sort(sorterByOrder),
  );

  filterAtomicActivities = memoize(
    (atomicActivities, activityId) => Object.values(atomicActivities)
      .filter(aa => aa.activityId === activityId)
      .sort(sorterByOrder),
  );

  /**
   *
   */
  componentDidUpdate() {
    if (!Object.keys(this.props.macroActivitiesMap).length
      || !Object.keys(this.props.activitiesMap).length
      || !Object.keys(this.props.atomicActivitiesMap).length) return;

    if (this.state.counterCalculated) return;
    this.state.counterCalculated = true;

    this.props.value.forEach((atomicActivity) => {
      const activity = this.props.atomicActivitiesMap[atomicActivity].activityId;
      if (!this.state.counterActivities[activity]) {
        this.state.counterActivities[activity] = 0;
      }
      ++this.state.counterActivities[activity];
    });

    Object.keys(this.state.counterActivities).forEach((activity) => {
      const macroActivity = this.props.activitiesMap[activity].macroActivityId;
      if (!this.state.counterMacroActivities[macroActivity]) {
        this.state.counterMacroActivities[macroActivity] = 0;
      }
      this.state.counterMacroActivities[macroActivity] += this.state.counterActivities[activity];
    });
  }

  onSelectMacroActivity = (elt) => {
    this.setState({ selectedMacroActivityId: elt.id });
    this.props.onSelectMacroActivity(elt);

    // Clean atomic-ativities list.
    this.setState({ selectedActivityId: null });
  };

  onSelectActivity = (elt) => {
    this.setState({ selectedActivityId: elt.id });
    this.props.onSelectActivity(elt);
  };

  onToggleAtomicActivity = (id, value) => {
    const activity = this.props.atomicActivitiesMap[id].activityId;
    const macroActivity = this.props.activitiesMap[activity].macroActivityId;
    const { value: currentValue, onChange } = this.props;
    if (value === true) {
      onChange(currentValue.concat(id));
      // add atomic activity to activity list
      if (!this.state.counterActivities[activity]) {
        this.state.counterActivities[activity] = 0;
      }
      ++this.state.counterActivities[activity];
      // add atomic activity to macroActivity list
      if (!this.state.counterMacroActivities[macroActivity]) {
        this.state.counterMacroActivities[macroActivity] = 0;
      }
      ++this.state.counterMacroActivities[macroActivity];
    } else {
      onChange(without(currentValue, id));
      // remove atomic activity from activity list
      --this.state.counterActivities[activity];
      // remove atomic activity from macroActivity list
      --this.state.counterMacroActivities[macroActivity];
    }
  };

  /**
   * Render component.
   *
   * @returns {object} - JSX.
   */
  render() {
    const {
      selectedMacroActivityId,
      selectedActivityId,
    } = this.state;

    const {
      macroActivitiesMap,
      activitiesMap,
      atomicActivitiesMap,

      value,
      className,

      isLoadingMacroActivity,
      isLoadingActivity,
      isLoadingAtomicActivity,
    } = this.props;

    return (
      <div className={cn(classNames.container, className)}>
        <BusinessActivitiesSelectorList
          elements={this.convertMacroActivities(macroActivitiesMap)}
          loading={isLoadingMacroActivity}
          onClickElement={this.onSelectMacroActivity}
          header={<FormattedMessage {...businessMessages.MACRO_ACTIVITIES} />}
          tooltip={<FormattedMessage {...businessMessages.MACRO_ACTIVITIES_TOOLTIP} />}
          selectedLineId={selectedMacroActivityId}
          counters={this.state.counterMacroActivities}
        />
        <BusinessActivitiesSelectorList
          elements={this.filterActivities(activitiesMap, selectedMacroActivityId)}
          loading={isLoadingActivity}
          onClickElement={this.onSelectActivity}
          header={<FormattedMessage {...businessMessages.ACTIVITIES} />}
          tooltip={<FormattedMessage {...businessMessages.ACTIVITIES_TOOLTIP} />}
          selectedLineId={selectedActivityId}
          counters={this.state.counterActivities}
        />
        <BusinessActivitiesSelectorList
          value={value}
          elements={this.filterAtomicActivities(atomicActivitiesMap, selectedActivityId)}
          loading={isLoadingAtomicActivity}
          onToggleElement={this.onToggleAtomicActivity}
          header={<FormattedMessage {...businessMessages.ATOMIC_ACTIVITIES} />}
          tooltip={<FormattedMessage {...businessMessages.ATOMIC_ACTIVITIES_TOOLTIP} />}
        />
      </div>
    );
  }
}
