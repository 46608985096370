import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'recompose';
import { noop } from 'lodash';

import classNames from './radio.module.scss';

const {
  arrayOf, string, shape, any, func, node, bool,
} = PropTypes;

const enhancer = compose(
  memo,
);

const Radio = ({
  options,
  value,
  onChange,
  className,
  disabled,
}) => (
  options.map(option => (
    <div key={option.value}>
      <label className={cn(classNames.label, { [classNames.disabled]: disabled })}>
        <input
          type="radio"
          value={option.value}
          checked={`${option.value}` === value}
          className={cn(
            className,
            classNames.checkbox,
            { [classNames.disabled]: disabled },
          )}
          onChange={onChange}
          disabled={disabled}
        />
        {option.label}
      </label>
    </div>
  ))
);

Radio.propTypes = {
  className: string,
  options: arrayOf(shape({ label: node, value: any })).isRequired,
  value: string,
  onChange: func,
  disabled: bool,
};

Radio.defaultProps = {
  className: null,
  value: null,
  onChange: noop,
  disabled: false,
};

export default enhancer(Radio);
