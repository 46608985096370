import { defineMessages } from 'react-intl';

export default defineMessages({
  DEFAULT_FAILURE_MESSAGE: {
    id: 'upload.field.failed.or.rejected.message',
    defaultMessage: 'An error occurred during logo upload.',
  },

  FILE_TOO_LARGE_FAILURE_MESSAGE: {
    id: 'upload.failures.fileTooLarge',
    defaultMessage: 'The file is too large.',
  },

  INVALID_FORMAT_FAILURE_MESSAGE: {
    id: 'upload.failures.invalidFormat',
    defaultMessage: 'Invalid file format.',
  },
});
