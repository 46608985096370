import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { productShape } from 'shapes/product';

import { FormattedMessage } from 'react-intl';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';
import TagList from 'react/business/products/tagList/TagList';
import cn from 'classnames';
import messages from './product-innersource.messages';
import classNames from './productInnersource.module.scss';

const enhancer = compose(
  memo,
);

const ProductInnersource = ({ product, theme, pageType }) => (
  <Section className={classNames.innersource} pageType={pageType} theme={theme}>
    <SectionTitle centered pageType={pageType}>
      <FormattedMessage {...messages.INNERSOURCE} />
    </SectionTitle>

    <div className={classNames.content}>
      {(pageType !== 'product') && (
      <div className={classNames[`list--${theme}`]}>
        <h3 className={cn(!pageType ? classNames.listTitle : classNames[`listTitle--${pageType}`])}>
          <FormattedMessage {...messages.DEVICES} />
        </h3>

        <TagList list={product.tags?.devices} pageType={pageType} theme={theme} />
      </div>
      )}

      <div className={classNames[`list--${theme}`]}>
        <h3 className={cn(classNames.listTitle, classNames[`listTitle--${pageType}`])}>
          <FormattedMessage {...messages.HASHTAGS} />
        </h3>

        <TagList list={product.tags?.misc} pageType={pageType} theme={theme} />
      </div>
    </div>
  </Section>
);

ProductInnersource.displayName = 'ProductInnersource';

ProductInnersource.propTypes = {
  product: productShape.isRequired,
  isBelowMediumBreakpoint: PropTypes.bool,
  pageType: PropTypes.string,
  theme: PropTypes.string,
};

ProductInnersource.defaultProps = {
  isBelowMediumBreakpoint: false,
  pageType: '',
  theme: 'light',
};

export default enhancer(ProductInnersource);
