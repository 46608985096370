import React from 'react';

import ConnectedReplyCard from '../card/connectedReplyCard';
import ReplyCardList from './ReplyCardList';

import classNames from './replyCardList.module.scss';

// This is not a React component.
// eslint-disable-next-line react/prop-types
const renderReply = ({ reply, compact, productId }) => (
  <li
    className={classNames.listElement}
    key={reply.id}
  >
    <ConnectedReplyCard
      reply={reply}
      compact={compact}
      productId={productId}
    />
  </li>
);

export default props => (
  <ReplyCardList
    {...props}
    renderReply={renderReply}
  />
);
