import { MAX_LINK_LENGTH } from 'config/constants';
import { isExternalUrl, maxLength } from 'react/generic/form/validators';

export default {
  PRODUCT_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  MARKETING_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  SOURCE_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  API_REFERENCE_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  DISCUSSION_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  DOCUMENTATION_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  ROADMAP_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  BACKLOG_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
  SUPPORT_URL: [isExternalUrl, maxLength(MAX_LINK_LENGTH)],
};
