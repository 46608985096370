import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withPropsOnChange,
  withState,
  lifecycle,
  withHandlers,
} from 'recompose';

import { PRODUCT_FORM_STEPS, makeStepsToDisplay } from 'react/business/products/form/steps/form-steps.constants';
import { ProductFormCurrentStepContext } from 'react/business/products/form/Context';
import { Redirect } from 'react-router';
import ProductFormRequestStep from '../step-0-request/ProductFormRequestStep';
import ProductFormPresentationStep from '../step-1/ProductFormPresentationStep';
import ProductFormFeaturesStep from '../step-2/ProductFormFeaturesStep';
import ProductFormLinksStep from '../step-5/ProductFormLinksStep';
import ProductFormValidation from '../step-validation/ProductFormValidation';

const STEPS_RENDERER = {
  [PRODUCT_FORM_STEPS.REQUEST]: ProductFormRequestStep,
  [PRODUCT_FORM_STEPS.PRESENTATION]: ProductFormPresentationStep,
  [PRODUCT_FORM_STEPS.FEATURES]: ProductFormFeaturesStep,
  [PRODUCT_FORM_STEPS.LINKS]: ProductFormLinksStep,
  [PRODUCT_FORM_STEPS.VALIDATION]: ProductFormValidation,
};

const enhancer = compose(

  withPropsOnChange(
    [],
    () => ({
      steps: makeStepsToDisplay(),
    }),
  ),

  /** Add a state to know if this is the first render or not. */
  withState(
    'hasMounted',
    'onSetHasMounted',
    false,
  ),

  lifecycle({
    /** Did mount. */
    componentDidMount() {
      this.props.onSetHasMounted(true);
    },
  }),

  withHandlers({
    renderStep:
      ({ onChangeStep, onOpenPreview }) => (stepKey) => {
        if (STEPS_RENDERER[stepKey]) {
          return React.createElement(
            STEPS_RENDERER[stepKey],
            {
              key: stepKey,
              onChangeStep,
              onOpenPreview,
            },
          );
        }
        return <Redirect to="/error/404" />;
      },
  }),

  memo,
);

const StepsRenderer = ({
  steps,
  hasMounted,
  renderStep,
}) => (
  // On first render, render all steps to register all fields so we can have all validation errors
  // in the store. On next renders, render only the current step for performance.
  hasMounted ? (
    <ProductFormCurrentStepContext.Consumer>
      {renderStep}
    </ProductFormCurrentStepContext.Consumer>
  ) : steps.map(renderStep)
);

StepsRenderer.displayName = 'StepsRenderer';

StepsRenderer.propTypes = {
  hasMounted: PropTypes.bool.isRequired,
  renderStep: PropTypes.func.isRequired,
  /** Keys of steps to display. */
  steps: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PRODUCT_FORM_STEPS))).isRequired,
};

export default enhancer(StepsRenderer);
