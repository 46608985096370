import { defineMessages } from 'react-intl';

export default defineMessages({
  LOGO_TITLE: { id: 'product.form.fields.logo.title', defaultMessage: 'Upload my logo *' },

  LOGO_TITLE_NOT_REQUIRED: {
    id: 'product.form.fields.logo.title.notRequired',
    defaultMessage: 'Upload my logo',
  },

  LOGO_UPLOAD_HINT: {
    id: 'product.form.fields.logo.hint',
    defaultMessage: 'Select your logo in .png or .jpg format, less than 6 MB.',
  },
});
