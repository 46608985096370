import { compose, withHandlers } from 'recompose';

import DropZoneField from 'react/generic/form/upload/DropzoneField';
import withUploadLoader from 'react/generic/form/upload/withUploadLoader';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';

import { postProductLogo } from 'services/products';

import withLogoUploadConfig from './withLogoUploadConfig';

const enhancer = compose(
  withLogoUploadConfig,

  reduxFormInputAdapter,

  withHandlers({
    onDrop: ({ onChange }) => async ([file] = [], onProgress) => {
      if (file) {
        const picture = await postProductLogo(file, onProgress);
        onChange(picture);
      }
    },

    onRemove: ({ onChange }) => () => {
      onChange({});
    },

    onBlur: ({ onBlur, value }) => () => onBlur(value),
  }),

  withUploadLoader,
);

export default enhancer(DropZoneField);
