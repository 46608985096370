import { compose, mapProps, withProps } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { selectUserById, selectUserLanguage } from 'redux/users/selectors';

import withUserLazyLoading from 'react/business/user/lazyLoad/withUserLazyLoading';
import voteReplyConnect from './voteReply.connect';
import translateReplyConnect from './translateReply.connect';

/**
 * Required props:
 * - productId (string).
 */
export default compose(
  // Add vote review callback and rename it to onVote.
  compose(
    voteReplyConnect,
    mapProps(({ onVoteReply, ...props }) => ({
      ...props,
      onVote: onVoteReply,
    })),
  ),

  // Lazy load and get author from store.
  compose(
    // Get author from the store.
    connect(
      (state, props) => ({
        author: selectUserById(state, props.reply?.author),
      }),
    ),

    // Lazy load the suggestion author.
    withUserLazyLoading({
      getIdsToLoadFromProps: props => [props.reply?.author].filter(Boolean),
    }),
  ),

  compose(
    // translateReplyConnect needs suggestionId and replyId props.
    withProps(props => ({
      suggestionId: props.reply.suggestion,
      replyId: props.reply.id,
    })),

    // Add onTranslate method.
    translateReplyConnect,

    // Omit suggestionId and replyId.
    mapProps(({ suggestionId, replyId, ...props }) => props),
  ),

  connect(
    (state, props) => ({
      withTranslateButton: selectUserLanguage(state) !== props.reply.contentLanguage,
    }),
  ),
);
