import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';

import { required } from 'react/generic/form/validators';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import UploadLogo from 'react/business/products/form/upload/UploadLogo';

import messages from './logo.messages';

const makeRules = () => [
  required,
].filter(Boolean);

const enhancer = compose(
  withPropsOnChange(
    [],
    () => ({
      rules: makeRules(),
    }),
  ),

  memo,
);

const LogoField = ({
  rules,
}) => (
  <Field
    type="file"
    name="logo"
    title={(<FormattedMessage {...messages.LOGO_TITLE} />
    )}
    tooltip={<FormattedMessage {...messages.LOGO_UPLOAD_HINT} />}
    component={UploadLogo}
    validate={rules}
  />
);

LogoField.displayName = 'LogoField';

LogoField.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.func),
};

LogoField.defaultProps = {
  rules: [],
};

export default enhancer(LogoField);
