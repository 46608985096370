import React, { memo } from 'react';
import { compose } from 'recompose';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';
import { ReduxFormSelectWithValueSelector } from 'react/generic/form/select/Select';
import { validationRejectedReasons } from 'redux/products/constants';
import messages from './rejected.messages';

const enhancer = compose(
  memo,
);

const options = [
  { value: validationRejectedReasons.NA, label: <FormattedMessage {...messages.NA} /> },
  { value: validationRejectedReasons.OTHER, label: <FormattedMessage {...messages.OTHER} /> },
  {
    value: validationRejectedReasons.IP,
    label: <FormattedMessage {...messages.INCORRECT_PLATFORM} />,
  },
  {
    value: validationRejectedReasons.IG,
    label: <FormattedMessage {...messages.INCORRECT_GRANULARITY} />,
  },
  {
    value: validationRejectedReasons.IMBP,
    label: <FormattedMessage {...messages.INCORRECT_MAINTAINED_BY_PLATFORM} />,
  },
  {
    value: validationRejectedReasons.ID,
    label: <FormattedMessage {...messages.INCORRECT_DOMAIN} />,
  },
];

const RejectedReasonField = ({ isCommunityLeader }) => (
  <Field
    type="text"
    name="validation.rejectedReason"
    title={<FormattedMessage {...messages.REJECTED_REASON_TITLE} />}
    tooltip={<FormattedMessage {...messages.REJECTED_REASON_HINT} />}
    component={ReduxFormSelectWithValueSelector}
    options={
        isCommunityLeader ?
          options.filter(op => op.value !== validationRejectedReasons.ID)
          : options
      }
    isSearchable={false}
  />
);

RejectedReasonField.displayName = 'RejectedReasonField';

RejectedReasonField.propTypes = {
  isCommunityLeader: PropTypes.bool.isRequired,
};

export default enhancer(RejectedReasonField);
