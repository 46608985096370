import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectSubsetsByCommunityId } from 'redux/subsets/selectors';
import { getSubsetsByCommunityId } from 'redux/subsets/actions';

export default compose(
  connect(
    (state, props) => ({
      subsets: selectSubsetsByCommunityId(state, props.communityId),
    }),
    {
      getSubsetsByCommunityId,
    },
  ),

  lifecycle({
    /**
     * Load all ABBs for the community.
     *
     * @async
     */
    async componentDidMount() {
      await this.props.getSubsetsByCommunityId(this.props.communityId);
    },
  }),
);
