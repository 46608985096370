import connect from 'react/hoc/connectProxy';
import { compose, lifecycle, withPropsOnChange } from 'recompose';
import { selectBusinessUnitsListSortedByName, selectBusinessUnitsMap } from 'redux/businessUnits/selectors';
import { getBusinessUnits } from 'redux/businessUnits/actions';

import { mapBu } from './businessUnits.constants';

export default compose(
  connect(
    state => ({
      businessUnits: selectBusinessUnitsListSortedByName(state).filter(el => !el.isInactive),
      businessUnitsMap: selectBusinessUnitsMap(state),
    }),
    { getBusinessUnits },
  ),

  lifecycle({
    /** Did mount. */
    async componentDidMount() {
      await this.props.getBusinessUnits();
    },
  }),

  withPropsOnChange(
    ['businessUnits'],
    props => ({ options: props.businessUnits.map(mapBu) }),
  ),
);
