import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './fields-group.module.scss';

const enhancer = compose(
  memo,
);

const FieldsGroup = ({ className, inline, ...props }) => (
  <div
    {...props}
    className={cn(
      className,
      classNames.fieldsGroup,
      inline && classNames.inline,
    )}
  />
);

FieldsGroup.displayName = 'FieldsGroup';

FieldsGroup.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
};

FieldsGroup.defaultProps = {
  className: null,
  inline: false,
};

export default enhancer(FieldsGroup);
