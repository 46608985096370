import React, { memo } from 'react';
import { compose } from 'recompose';

import { required, maxLength } from 'react/generic/form/validators';
import { MAX_SHORT_DESCRIPTION_LENGTH } from 'config/constants';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { DebouncedReduxFormTextArea } from 'react/generic/form/input/TextArea';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import messages from './shortDescription.messages';

const rules = [
  required,
  maxLength(MAX_SHORT_DESCRIPTION_LENGTH),
];

const enhancer = compose(
  memo,
);

const ShortDescriptionField = () => (
  <FormattedMessageChildren {...messages.SHORT_DESCRIPTION_PLACEHOLDER}>
    {placeholder => (
      <Field
        type="text"
        name="shortDescription"
        title={<FormattedMessage {...messages.SHORT_DESCRIPTION_TITLE} />}
        tooltip={(
          <FormattedMessage
            {...messages.SHORT_DESC_HINT}
            values={{ maxLength: MAX_SHORT_DESCRIPTION_LENGTH }}
          />
        )}
        component={DebouncedReduxFormTextArea}
        placeholder={placeholder}
        rows={3}
        maxLength={MAX_SHORT_DESCRIPTION_LENGTH}
        validate={rules}
      />
    )}
  </FormattedMessageChildren>
);

ShortDescriptionField.displayName = 'ShortDescriptionField';

export default enhancer(ShortDescriptionField);
