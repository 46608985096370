import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';

import ContactButton from 'react/business/products/details/team/list/button/ContactButton';
import connect from 'react/hoc/connectProxy';

import { getUsersByExternalIds } from 'redux/users/actions';

import messages from '../product-team.messages';
import classNames from './no-team.module.scss';

const enhancer = compose(
  connect(null, { getUsersByExternalIds }),
  lifecycle({
    /**
     * Get Author data on mount.
     */
    async componentDidMount() {
      this.props.getUsersByExternalIds([this.props.author]);
    },
  }),
  memo,
);

const NoTeam = ({
  author,
  theme,
}) => (
  <div className={classNames.container}>
    <div className={classNames.info}>
      <FormattedMessage {...messages.NO_TEAM} />
    </div>
    <div className={classNames.contact}>
      <ContactButton
        externalId={author}
        className={classNames.button}
        theme={theme}
      />
    </div>
  </div>
);

NoTeam.displayName = 'NoTeam';

NoTeam.propTypes = {
  author: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

NoTeam.defaultProps = {
  theme: '',
};

export default enhancer(NoTeam);
