import { compose } from 'recompose';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import manifest from './request.manifest';
import { withProductFormMode } from '../../Context';
import withProductFromFormValues from '../../../../../pages/product/form/withProductFromFormValues';
import { productShape } from '../../../../../../shapes/product';
import messages from './request.messages';
import classNames from './request.module.scss';

const enhancer = compose(
  memo,
  withProductFormMode(),
  withProductFromFormValues,
);

const {
  fields: {
    proposition: {
      component: PropositionComponent,
    },
    purpose: {
      component: PurposeComponent,
    },
  },
} = manifest;

const ProductFormRequestForm
  = ({ product }) => (
    <>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_PRESENTATION} />}
        message={<FormattedMessage {...messages.HEADER_MESSAGE_PRESENTATION} />}
        isComponent={false}
      />
      <div className={classNames.infoHeader}>
        <div className={classNames.infoIcon}>
          <Asset className={classNames.infoIcon} name={ICON_MAP.info} />
        </div>
        <FormattedMessage {...messages.HEADER_INFO} />
      </div>
      <PropositionComponent product={product} />
      {product?.proposition !== 0 ? <PurposeComponent product={product} /> : null}
    </>
  );

ProductFormRequestForm.propTypes = {
  product: productShape.isRequired,
};

ProductFormRequestForm.defaultProps = {
};
export default enhancer(ProductFormRequestForm);
