import { compose } from 'recompose';
import { isString, isFunction } from 'lodash';
import {
  getFormAsyncErrors,
  getFormSyncErrors,
  getFormSyncWarnings,
} from 'redux-form';

import connect from 'react/hoc/connectProxy';
import { withProductFormName, withProductFormCurrentStep } from 'react/business/products/form/Context';
import { PRODUCT_FORM_STEPS_MANIFESTS } from './manifests';

const getManifest = (stepManifest, props) => {
  if (!stepManifest) {
    return PRODUCT_FORM_STEPS_MANIFESTS[props.currentStep];
  } if (isString(stepManifest)) {
    return PRODUCT_FORM_STEPS_MANIFESTS[stepManifest];
  } if (isFunction(stepManifest)) {
    return stepManifest(props);
  }

  return stepManifest;
};

const defaultPropsMapper = props => props;

export default (
  // Falsy: current step.
  // String: specific step key.
  // Function: props => manifest.
  // Other: manifest.
  stepManifest,
  propsMapper = defaultPropsMapper,
) => compose(
  withProductFormName(),
  withProductFormCurrentStep(),

  connect(
    (state, props) => {
      const manifest = getManifest(stepManifest, props);

      return propsMapper({
        syncErrorsCount: manifest?.getSyncErrorsCount(
          getFormSyncErrors(props.formName)(state),
        ) || 0,

        asyncErrorsCount: manifest?.getAsyncErrorsCount(
          getFormAsyncErrors(props.formName)(state),
        ) || 0,

        errorsCount: manifest?.getErrorsCount(
          getFormSyncErrors(props.formName)(state),
          getFormAsyncErrors(props.formName)(state),
        ) || 0,

        syncWarningsCount: manifest?.getSyncWarningsCount(
          getFormSyncWarnings(props.formName)(state),
        ) || 0,

        warningsCount: manifest?.getWarningsCount(
          getFormSyncWarnings(props.formName)(state),
        ) || 0,
      });
    },
  ),
);
