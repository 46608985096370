import { defineMessages } from 'react-intl';

export default defineMessages({
  STAGES_TITLE: { id: 'product.form.fields.stage.title', defaultMessage: 'Stage *' },

  STAGES_HINT: {
    id: 'product.form.fields.stage.hint',
    defaultMessage: `
    Select the current lifecycle step of your digital product.
    
    Click here to understand each status :
    
    `,
  },
  STAGES_LINK: {
    id: 'product.form.fields.stage.link',
    defaultMessage: 'Digital Product Status',
  },
});
