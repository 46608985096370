import React, { memo } from 'react';
import { compose } from 'recompose';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import AutoCompleteSoftwarePublisher from 'react/business/products/form/autocompletes/SoftwarePublishers';

import messages from './softwarePublisher.messages';

const enhancer = compose(
  memo,
);

const SoftwarePublisherField = () => (
  <Field
    name="softwarePublisher"
    title={<FormattedMessage {...messages.SOFTWARE_PUBLISHER_TITLE} />}
    tooltip={<FormattedMessage {...messages.SOFTWARE_PUBLISHER_HINT} />}
    component={AutoCompleteSoftwarePublisher}
    menuPlacement="top"
  />
);

SoftwarePublisherField.displayName = 'SoftwarePublisherField';

export default enhancer(SoftwarePublisherField);
