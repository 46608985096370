import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { PRODUCT_VIEW } from 'config/constants';

import { withProductPageContext } from 'react/pages/product/productPage/ProductPage.context';

import OverviewPage from './overviewPage/OverviewPage';
import FeedbackPage from './feedbackPage/FeedbackPage';
import SuggestionsPage from './suggestionsPage/SuggestionsPage';

const TAB_RENDERERS_MAP = {
  [PRODUCT_VIEW.OVERVIEW]: OverviewPage,
  [PRODUCT_VIEW.REVIEWS]: FeedbackPage,
  [PRODUCT_VIEW.SUGGESTIONS]: SuggestionsPage,
};

const enhancer = compose(
  withProductPageContext(),
  memo,
);

const ProductPageTabSwitch = ({
  view,
}) => React.createElement(
  TAB_RENDERERS_MAP[view] || OverviewPage,
);

ProductPageTabSwitch.displayName = 'ProductPageTabSwitch';

ProductPageTabSwitch.propTypes = {
  view: PropTypes.string,
};

ProductPageTabSwitch.defaultProps = {
  view: PRODUCT_VIEW.OVERVIEW,
};

export default enhancer(ProductPageTabSwitch);
