import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { softwarePublisherShape } from 'shapes/product';
import ProductIsMaintainedByPlatform from '../isMaintainedByPlatform/IsMaintainedByPlatform';
import classNames from './shortDescription.module.scss';
import messages from './shortDescription.messages';

const hostingTypeStrings = {
  OPA: 'On Premise Adeo',
  OPB: 'On Premise BU',
  PRC: 'Private Cloud',
  PUC: 'Public Cloud',
  SAAS: 'SaaS',
};

const ShortDescription = ({
  shortDescription,
  softwarePublisher,
  isMaintainedByPlatform,
  hostingType,
}) => (
  <>
    <div className={classNames.shortDescription}>
      {shortDescription}
    </div>
    <div className={classNames.cols}>
      <>{(softwarePublisher?.name || isMaintainedByPlatform)
          && (
            <div className={classNames.col}>
              {softwarePublisher?.name && (
                <div className={classNames.publisher}>
                  <FormattedMessage
                    {...messages.SOFTWARE_PUBLISHER}
                    values={{
                      publisher: softwarePublisher.name,
                    }}
                  />
                </div>
              )}
              <ProductIsMaintainedByPlatform isMaintainedByPlatform={isMaintainedByPlatform} />
            </div>
          )
        }
      </>

      <div className={classNames.col}>
        {hostingType && (
        <FormattedMessage
          {...messages.HOSTING_TYPE}
          values={{
            hostingType: hostingTypeStrings[hostingType] || hostingType,
          }}
        />
        )}
      </div>
    </div>

  </>
);

ShortDescription.displayName = 'ShortDescription';

ShortDescription.propTypes = {
  shortDescription: PropTypes.string,
  softwarePublisher: softwarePublisherShape,
  isMaintainedByPlatform: PropTypes.bool,
  hostingType: PropTypes.string,
};

ShortDescription.defaultProps = {
  softwarePublisher: {},
  shortDescription: '',
  isMaintainedByPlatform: false,
  hostingType: '',
};

export default memo(ShortDescription);
