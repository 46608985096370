import { compose, withProps, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';

import omitProps from 'react/hoc/omitProps';

/**
 * Create a HOC handling the value in the route.
 *
 * @example
 * import withValueInRoute from 'react/generic/steps/containers/withValueInRoute';
 *
 * const getValue = ({ match }) => match.params.step;
 * const setValue = (value, { history }) => history.push(`/my/route/${encodeURIComponent(value)}`);
 * const enhancer = withValueInRoute(getValue, setValue);
 *
 * @param {Function} getValueFromRoute - Given the route props, return the value.
 * @param {Function} setValueInRoute - Given the route props and value, redirect.
 * @param {object} [opts] - Options.
 * @param {boolean} [opts.omitRouterProps=true] - Omit location history and match props.
 * @returns {Function} HOC.
 */
export default (
  getValueFromRoute,
  setValueInRoute,
  {
    omitRouterProps = true,
    propsMapper = { value: 'value', onChange: 'onChange' },
  } = {},
) => compose(
  ...[
    withRouter,

    withProps(props => ({
      [propsMapper.value]: getValueFromRoute(props),
    })),

    withHandlers({
      [propsMapper.onChange]: props => value => setValueInRoute(value, props),
    }),

    !!omitRouterProps && omitProps(['location', 'history', 'match']),
  ].filter(Boolean),
);
