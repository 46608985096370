import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { PulseSpinner } from 'react-spinners-kit';

import { subsetsShape } from 'shapes/subsets';
import theme from 'style/theme.variables.scss';

import SubsetsListItem from '../listItem/SubsetsListItem';

import messages from '../subset-selector.messages';
import classNames from '../subset-selector.module.scss';

const enhancer = compose(
  memo,
);

const SubsetsList = ({
  items,
  value,
  loading,
  onToggleElement,
  header,
}) => (
  <>
    <div className={classNames.listHeader}>
      {header}
    </div>
    <div className={classNames.listContainer}>
      {items.map(item => (
        <SubsetsListItem
          item={item}
          key={item.id}
          checked={value?.includes(item.id)}
          onToggleElement={onToggleElement}
        />
      ))}

      {!loading && items.length === 0 && (
        <div className={classNames.noContentContainer}>
          <FormattedMessage {...messages.NO_ITEMS} />
        </div>
      )}
      {loading && (
        <div className={classNames.noContentContainer}>
          <PulseSpinner color={theme.primary} />
        </div>
      )}
    </div>
  </>
);

SubsetsList.displayName = 'SubsetsList';

SubsetsList.propTypes = {
  items: PropTypes.arrayOf(subsetsShape),
  value: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  onToggleElement: PropTypes.func,
  header: PropTypes.string,
};

SubsetsList.defaultProps = {
  items: [],
  value: null,
  loading: false,
  onToggleElement: noop,
  header: '',
};

export default enhancer(SubsetsList);
