import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers, withPropsOnChange } from 'recompose';

import makeProductForm from 'react/business/products/form/Form';
import { FORM_MODES } from 'react/business/products/form/form.constants';
import { makeStepsToDisplay } from 'react/business/products/form/steps/form-steps.constants';
import withFormStepsTouched from 'react/business/products/form/steps/withFormStepsTouched';

import { elementShape } from 'shapes/vendor';
import { productShape } from 'shapes/product';

import { ProductFormCurrentStepContext } from 'react/business/products/form/Context';
import ProductFormSteps from 'react/business/products/form/steps/FormSteps';
import ProductFormLayout from 'react/pages/product/form/layout/Layout';
import ProductFormPreview from 'react/pages/product/form/preview/Preview';
import { withToastManager } from 'react-toast-notifications';
import withPreviewOpenState from '../preview/withPreviewOpenState';

import classNames from './product-edit-form-page.module.scss';
import withProductFromFormValues from '../withProductFromFormValues';
import StepsRenderer from '../layout/StepsRenderer';
import {
  nextRejectedValidationStatus,
  nextValidationStatus,
  validationStatuses,
} from '../../../../../redux/products/constants';

const commonToastOptions = {
  autoDismiss: true,
  pauseOnHover: true,
  appearance: 'success',
};

const ConnectedProductFormPreview = withProductFromFormValues(ProductFormPreview);

const EditProductFormSteps = compose(
  withFormStepsTouched(makeStepsToDisplay),
)(ProductFormSteps);

const enhancer = compose(
  withPropsOnChange(
    () => false,
    () => ({
      // Create a new product form on mount.
      ProductForm: makeProductForm({
        mode: FORM_MODES.UPDATE,
      }),
    }),
  ),

  withPropsOnChange(
    ['product', 'community'],
    ({ product, community }) => ({
      initialValues: {
        ...product,
        community,
      },
    }),
  ),

  withPreviewOpenState,

  withToastManager,

  withHandlers({
    addSuccessToast: ({ toastManager, product }) => (validationStatus) => {
      const isComponent = product.parentDigitalProduct !== undefined;
      const displayToast
        = product.validation?.status === validationStatuses.PDL
        || product.validation?.status === validationStatuses.PBUA
        || product.validation?.status === validationStatuses.PCA;

      if (!displayToast) return;

      const isRejected
        = validationStatus === validationStatuses.RBDL
        || validationStatus === validationStatuses.RBCA
        || validationStatus === validationStatuses.RBBUA;

      const nextState = isRejected ?
        nextRejectedValidationStatus[product.validation?.status]
        : nextValidationStatus[product.validation?.status][isComponent ? 'component' : 'product'];

      const capitalCaseValidationStats = nextState?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
      let validator;

      switch (product.validation?.status) {
        case validationStatuses.PDL:
          validator = product.validators?.domainLeader;
          break;
        case validationStatuses.PBUA:
          validator = product.validators?.businessUnitArchitect;
          break;
        case validationStatuses.PCA:
          validator = product.validators?.communityArchitect;
          break;
        default:
          break;
      }

      const validatorName = validator ? ` (${validator.firstName} ${validator.lastName})` : '';

      const addValidationWord
        = [validationStatuses.PBUA,
          validationStatuses.PDL,
          validationStatuses.PCA].includes(nextState) && !isRejected;

      toastManager.add(
        <div>
          <div>You {isRejected ? 'rejected' : 'validated'} {(product.name || '').toUpperCase()}</div>
          <br />
          <div className="toast-text">Now {(product.name || '').toUpperCase()} is <span className="bold">{capitalCaseValidationStats} {addValidationWord && 'Validation'}</span>{validatorName}</div>
        </div>,
        {
          ...commonToastOptions,
        },
      );
    },
  }),

  memo,
);

const ProductEditFormPage = ({
  ProductForm,
  initialValues,
  step,
  onChangeStep,
  onCreateProduct,
  onEditProduct,
  onSubmitSuccess,
  onSubmitFail,
  isPreviewOpen,
  onOpenPreview,
  onClosePreview,
  addSuccessToast,
}) => (
  <ProductFormCurrentStepContext.Provider value={step}>
    <ProductForm
      initialValues={initialValues}
      onCreateProduct={onCreateProduct}
      onEditProduct={onEditProduct}
      onSubmitSuccess={
        (product) => {
          onSubmitSuccess(product);
          addSuccessToast(product.validation?.status);
        }
      }
      onSubmitFail={onSubmitFail}
      className={classNames.form}
    >
      <ProductFormLayout
        steps={(
          <EditProductFormSteps
            value={step}
            onChange={onChangeStep}
          />
        )}
      >
        <StepsRenderer
          step={step}
          onChangeStep={onChangeStep}
          onOpenPreview={onOpenPreview}
        />

        <ConnectedProductFormPreview
          isOpen={isPreviewOpen}
          onRequestClose={onClosePreview}
        />
      </ProductFormLayout>
    </ProductForm>
  </ProductFormCurrentStepContext.Provider>
);

ProductEditFormPage.propTypes = {
  ProductForm: elementShape.isRequired,
  initialValues: productShape.isRequired,
  step: PropTypes.string,
  onChangeStep: PropTypes.func,
  onCreateProduct: PropTypes.func,
  onEditProduct: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  isPreviewOpen: PropTypes.bool.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onClosePreview: PropTypes.func.isRequired,
  addSuccessToast: PropTypes.func.isRequired,
};

ProductEditFormPage.defaultProps = {
  step: '',
  onChangeStep: noop,
  onCreateProduct: noop,
  onEditProduct: noop,
  onSubmitSuccess: noop,
  onSubmitFail: noop,
};

export default enhancer(ProductEditFormPage);
