import { defineMessages } from 'react-intl';

export default defineMessages({
  HEADER_TITLE_PRESENTATION: {
    id: 'pages.product.form.step.0.request.header.title',
    defaultMessage: 'Registration Request',
  },

  HEADER_MESSAGE_PRESENTATION: {
    id: 'pages.product.form.step.0.request.header.message',
    defaultMessage: 'For which purpose are you requesting a digital product registration?',
  },

  HEADER_INFO: {
    id: 'pages.product.form.step.0.request.infoHeader.message',
    defaultMessage: 'In order to help the validator managing your request, please select one proposition from the following list and explain the purpose of your request in a few words.',
  },
});
