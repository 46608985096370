import React, { memo } from 'react';
import { compose, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';

import omitProps from 'react/hoc/omitProps';
import { deleteReply } from 'redux/replies/actions';
import { getProduct } from 'redux/products/actions';
import { ACTIONS as REPLIES_ACTIONS } from 'redux/replies/constants';
import { isLoadingReplies } from 'redux/replies/selectors';
import connect from 'react/hoc/connectProxy';

import messages from 'react/business/review/reply/delete/dialog/delete-confirmation-dialog.messages';

export default compose(
  memo,

  connect(
    state => ({
      isLoading: isLoadingReplies(state),
    }),
    (dispatch, props) => ({
      onRequestDelete: async () => {
        const action = await dispatch(deleteReply(
          props.productId,
          props.reviewId,
          props.replyId,
        ));

        if (action.type === REPLIES_ACTIONS.DELETE_REPLY_SUCCESS) {
          // Fetch the product to see marks refresh.
          await dispatch(getProduct(props.productId));
          return props.onDeleteSuccess?.();
        }

        return props.onDeleteFailure?.();
      },
    }),
  ),

  withProps({
    title: <FormattedMessage {...messages.FEEDBACK_DELETE_REPLY_DIALOG_TITLE} />,
    content: <FormattedMessage {...messages.FEEDBACK_DELETE_REPLY_DIALOG_CONTENT} />,
  }),

  omitProps(['productId', 'reviewId', 'onDeleteSuccess', 'onDeleteFailure']),
);
