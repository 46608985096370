import { defineMessages } from 'react-intl';

export default defineMessages({
  UNSAVED_CHANGES: {
    id: 'product.form.prompt.unsavedChanges',
    defaultMessage: 'Some changes on your product are not saved yet, are you sure you want to leave the form?',
  },
  WRONG_LANGUAGE: {
    id: 'product.form.warning.notEnglish',
    defaultMessage: 'This text is not fully in English',
  },
});
