import React, { memo } from 'react';
import { compose } from 'recompose';
import { MAX_REJECTED_REASON_DESCRIPTION_LENGTH } from 'config/constants';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { DebouncedReduxFormTextArea } from 'react/generic/form/input/TextArea';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import PropTypes from 'prop-types';
import { requiredRejectedReasonDescription } from 'react/generic/form/validators';
import messages from './rejected.messages';

const rules = [requiredRejectedReasonDescription];

const enhancer = compose(
  memo,
);

const RejectedReasonDescription = ({ disabled }) => (
  <FormattedMessageChildren {...messages.REJECTED_REASON_DESC_PLACEHOLDER}>
    {placeholder => (
      <Field
        type="text"
        name="validation.rejectedReasonDescription"
        title={<FormattedMessage {...messages.REJECTED_REASON_DESC_TITLE} />}
        tooltip={(
          <FormattedMessage
            {...messages.REJECTED_REASON_DESC_HINT}
            values={{ maxLength: MAX_REJECTED_REASON_DESCRIPTION_LENGTH }}
          />
        )}
        component={DebouncedReduxFormTextArea}
        placeholder={placeholder}
        rows={3}
        maxLength={MAX_REJECTED_REASON_DESCRIPTION_LENGTH}
        disabled={disabled}
        validate={rules}
      />
    )}
  </FormattedMessageChildren>
);

RejectedReasonDescription.propTypes = {
  disabled: PropTypes.bool,
};

RejectedReasonDescription.defaultProps = {
  disabled: false,
};

RejectedReasonDescription.displayName = 'RejectedReasonDescription';

export default enhancer(RejectedReasonDescription);
