import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { getFormValues } from 'redux-form';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Steps, { Step } from 'react/generic/steps/Steps';

import { PRODUCT_FORM_STEPS } from './form-steps.constants';
import { withProductFormName } from '../Context';
import withFormStepManifest from './withFormStepManifest';

import requestManifest from './step-0-request/request.manifest';
import presentationManifest from './step-1/presentation.manifest';
import linksManifest from './step-5/links.manifest';

import messages from './form-steps.messages';
import featuresManifest from './step-2/features.manifest';

const stepEnhancer = manifest => compose(
  withFormStepManifest(
    manifest,
    ({ errorsCount, warningsCount }) => ({
      errorsCount,
      warningsCount,
    }),
  ),

  withHandlers({
    onTouch: ({ onTouch }) => value => onTouch?.(value, manifest),
  }),

  omitProps(['formName', 'currentStep']),
);

const RequestStep = stepEnhancer(requestManifest)(Step);
const PresentationStep = stepEnhancer(presentationManifest)(Step);
const FeaturesStep = stepEnhancer(featuresManifest)(Step);
const LinksStep = stepEnhancer(linksManifest)(Step);

const enhancer = compose(
  withProductFormName(),

  connect(
    (state, props) => ({
      isGlobal: getFormValues(props.formName)(state)?.isGlobal === 'true',
    }),
  ),

  omitProps(['formName']),

  memo,
);

const ProductFormSteps = ({
  isGlobal,
  ...props
}) => (
  <Steps {...props}>
    <RequestStep
      value={PRODUCT_FORM_STEPS.REQUEST}
      title={<FormattedMessage {...messages.REQUEST_TITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <PresentationStep
      value={PRODUCT_FORM_STEPS.PRESENTATION}
      title={<FormattedMessage {...messages.PRESENTATION_TITLE} />}
      subtitle={<FormattedMessage {...messages.PRESENTATION_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <FeaturesStep
      value={PRODUCT_FORM_STEPS.FEATURES}
      title={<FormattedMessage {...messages.FEATURES_TITLE} />}
      subtitle={<FormattedMessage {...messages.FEATURES_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <LinksStep
      value={PRODUCT_FORM_STEPS.LINKS}
      title={<FormattedMessage {...messages.LINKS_TITLE} />}
      subtitle={<FormattedMessage {...messages.LINKS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <Step
      value={PRODUCT_FORM_STEPS.VALIDATION}
      title={<FormattedMessage {...messages.VALIDATION_TITLE} />}
    />
  </Steps>
);

ProductFormSteps.displayName = 'ProductFormSteps';

ProductFormSteps.propTypes = {
  isGlobal: PropTypes.bool,
};

ProductFormSteps.defaultProps = {
  isGlobal: false,
};

export default enhancer(ProductFormSteps);
