import agent from 'services/http';
import { mapLocalOrganizationFromServer } from './local-organizations.mapper';

const e = encodeURIComponent;

/**
 * GET /api/localOrganization/{id}/children.
 * Get the list of ABB for a community.
 *
 * @async
 * @param {*} id - Technical unique id of the local organization.
 * @returns {Array<object>} Children (local domains) .
 */
export const getLocalOrganizationChildren = async (id) => {
  const { body: children } = await agent
    .get(`/api/localOrganization/${e(id)}/children`);

  return children.map(mapLocalOrganizationFromServer);
};

/**
 * GET /api/localOrganization.
 * Get the list of all local organizations and their children.
 *
 * @async
 * @returns {Array<object>} Cocal organizations.
 */
export const getAllLocalOrganizationsAndDomains = async () => {
  const { body: all } = await agent
    .get('/api/localOrganization');

  return all.map(mapLocalOrganizationFromServer);
};
