import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { compose, pure, withHandlers } from 'recompose';

import globalMessages from 'config/global.messages';

import facetMessages from 'react/business/facets/facets.messages';
import messages from './review-filters.messages';
import classNames from './review-filters.module.scss';
import { filtersShape } from '../reviews.shapes';
import { ORDER_BY_OPTIONS, FILTERS } from '../reviews.constants';

const { Option } = Select;

const ORDER_BY_OPTIONS_WITH_MESSAGES = {
  [ORDER_BY_OPTIONS.DATE_ASC]: globalMessages.ORDER_BY_DATE_ASC,
  [ORDER_BY_OPTIONS.DATE_DESC]: globalMessages.ORDER_BY_DATE_DESC,
  [ORDER_BY_OPTIONS.MARK_ASC]: messages.ORDER_BY_MARK_ASC,
  [ORDER_BY_OPTIONS.MARK_DESC]: messages.ORDER_BY_MARK_DESC,
};

const enhancer = compose(
  pure,

  withHandlers({
    onSetOrderBy: ({ onSetFilter }) => value => onSetFilter(FILTERS.ORDER_BY, value),
  }),
);

const ReviewFilters = ({
  filters,
  onSetOrderBy,
  className,
}) => (
  <div className={cn(classNames.container, className)}>
    <Select
      onChange={onSetOrderBy}
      value={filters && filters[FILTERS.ORDER_BY]}
      placeholder={<FormattedMessage {...facetMessages.SORTING_OPTIONS} />}
      showArrow={false}
      showSearch={false}
    >
      {Object.values(ORDER_BY_OPTIONS).map(optionValue => (
        <Option value={optionValue} key={optionValue}>
          <FormattedMessage
            {...ORDER_BY_OPTIONS_WITH_MESSAGES[optionValue]}
            className={classNames.label}
          />
        </Option>
      ))}
    </Select>
  </div>
);

ReviewFilters.propTypes = {
  filters: filtersShape,
  onSetOrderBy: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ReviewFilters.defaultProps = {
  filters: {},
  className: '',
};

export default enhancer(ReviewFilters);
