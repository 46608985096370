import React, { memo } from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';
import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import { productShape } from 'shapes/product';
import ProductSummary from 'react/business/products/details/summary/ProductSummary';
import ProductDescription from 'react/business/products/details/description/ProductDescription';
import ProductTeam from 'react/business/products/details/team/ProductTeam';
import ProductInnersource from 'react/business/products/details/innersource/ProductInnersource';
import ProductSubHeader from 'react/business/products/details/subHeader/ProductSubHeader';
import EditProductButton from 'react/business/products/editButton/editProductButton/EditProductButton';
import messages from 'react/pages/product/productPage/productpage.messages';
import ProductFeatures from 'react/business/products/details/features/ProductFeatures';
import { selectCanAddComponent } from 'redux/products/selectors';
import ProductComponents from '../../../../../business/products/details/components/ProductComponents';
import ProductBusinessUnits from '../../../../../business/products/details/businessUnits/ProductBusinessUnits';
import { withProductPageContext } from '../../ProductPage.context';
import connect from '../../../../../hoc/connectProxy';
import classNames from './overview-page.module.scss';

const enhancer = compose(
  withRouter,

  withProductPageContext(),

  withHandlers({
    onClickEdit: ({ product, history }) => () => {
      history.push(formatRoute(
        ROUTES.PRODUCT.EDIT_FORM,
        {
          productId: product.id,
          step: PRODUCT_FORM_STEPS.PRESENTATION,
        },
      ) + (product.parentDigitalProduct ? '?isComponent=true' : ''));
    },
  }),

  withProps({
    shouldDisplayTeamPart: ({ product }) => !isEmpty(product.teamMembers) || product.author,
  }),

  memo,
  connect((state, props) => ({
    canAddComponent: selectCanAddComponent(state, props.product.id),
  })),
);

const OverviewPage = ({
  product,
  isPreview,
  canEditProduct,
  canAddComponent,
  onClickEdit,
  shouldDisplayTeamPart,
}) => (
  <>
    {
      (canEditProduct) && (!isPreview) && (
        <ProductSubHeader>
          <EditProductButton
            productId={product.id}
            onClickEdit={onClickEdit}
            labelButton={<FormattedMessage {...messages.EDIT_PRODUCT_BUTTON} />}
            pageType="product"
          />
        </ProductSubHeader>
      )
    }
    <div className={classNames.container}>
      <ProductSummary product={product} pageType="product" />

      <ProductDescription description={product.presentation} pageType="product" />

      {shouldDisplayTeamPart({ product }) && (
        <ProductTeam product={product} pageType="product" />
      )}

      <ProductComponents
        product={product}
        canAddComponent={canAddComponent}
        isPreview={isPreview}
      />

      {product.businessUnitsUsingProduct?.length ?
        <ProductBusinessUnits product={product} pageType="product" />
        : null}

      <ProductFeatures
        product={product}
        isPreview={isPreview}
        pageType="product"
      />

      <ProductInnersource product={product} pageType="product" theme="dark" />

    </div>
  </>
);

OverviewPage.displayName = 'OverviewPage';

OverviewPage.propTypes = {
  product: productShape.isRequired,
  isPreview: PropTypes.bool,
  canEditProduct: PropTypes.bool,
  canAddComponent: PropTypes.bool,
  onClickEdit: PropTypes.func,
  shouldDisplayTeamPart: PropTypes.func,
};

OverviewPage.defaultProps = {
  isPreview: false,
  canEditProduct: false,
  canAddComponent: false,
  onClickEdit: noop,
  shouldDisplayTeamPart: noop,
};

export default enhancer(OverviewPage);
