import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE: {
    id: 'views.title.product.editForm',
    defaultMessage: 'Product form ― Tangram',
  },
  ACCESS_DENIED: {
    id: 'views.title.product.editForm.denied',
    defaultMessage: 'Only team members, platform leaders and domain leaders can edit this product.',
  },
});
