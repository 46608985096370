import React, { memo } from 'react';
import {
  compose, withProps, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';
import { PRODUCT_TABS, PRODUCT_TABS_MAP } from 'config/constants';
import { withProductPageContext } from 'react/pages/product/productPage/ProductPage.context';

import Tabs, { Tab } from 'react/generic/tabs/Tabs';

const getActiveTab = tab => PRODUCT_TABS.find(({ id }) => id === tab)?.id;

/**
 * Render a tab.
 *
 * @param {object} tab - Tab shape.
 * @param {boolean} isActive - Is it the current active tab.
 * @param {Function} onClick - On click handler.
 * @param {string} className - Root element class name.
 * @returns {object} JSX.
 */
const renderTab = (tab, isActive, onClick, className) => (
  <Tab {...tab} isActive={isActive} key={tab.id} onClick={onClick} className={className} />
);

const ProductTabs = compose(
  withRouter,
  withProductPageContext(),
  memo,

  withHandlers({
    onClickTab: ({ product, history, disabled }) => (e) => {
      if (!disabled) {
        history.push(formatRoute(ROUTES.PRODUCT.PRESENTATION, {
          productId: product.id,
          view: e,
        }));
      }
    },
  }),

  // Add tabs and active tab props.
  withProps(({ onClickTab, view }) => ({
    renderTab,
    noWrap: true,
    active: getActiveTab(view) || PRODUCT_TABS_MAP.overview.id,
    onClick: onClickTab,
    tabs: [
      PRODUCT_TABS_MAP.overview,
      PRODUCT_TABS_MAP.reviews,
      PRODUCT_TABS_MAP.suggestions,
    ].filter(Boolean),
  })),
)(Tabs);

export default ProductTabs;
