import React, { memo } from 'react';
import { compose } from 'recompose';

import { TAG_TYPES } from 'config/constants';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import AutoComplete from 'react/business/products/form/autocompletes/Tags';

import messages from './techno.messages';

const rules = [];

const enhancer = compose(
  memo,
);

const TagsTechnoField = () => (
  <Field
    name="tags.techno"
    component={AutoComplete}
    title={<FormattedMessage {...messages.TECHNOLOGIES_TITLE} />}
    tagType={TAG_TYPES.TECHNO}
    validate={rules}
  />
);

TagsTechnoField.displayName = 'TagsTechnoField';

export default enhancer(TagsTechnoField);
