import connect from 'react/hoc/connectProxy';
import { voteReply } from 'redux/replies/actions';

/**
 * Requires a productId prop.
 * Exposes a onVoteReply func bound to the productId prop.
 */
export default connect(
  null,
  (dispatch, props) => ({
    onVoteReply: (reviewId, replyId, vote) => dispatch(voteReply(
      props.productId,
      reviewId,
      replyId,
      vote,
    )),
  }),
);
