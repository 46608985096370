import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { Tooltip, Checkbox } from 'antd';
import cn from 'classnames';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { architectureBuildingBlockShape } from 'shapes/architectureBuildingBlocks';

import classNames from '../abb-selector.module.scss';

const enhancer = compose(
  withPropsOnChange(
    ['item', 'onClickElement', 'onToggleElement'],
    ({ item, onClickElement, onToggleElement }) => ({
      selectable: !!onClickElement,
      onClickElement: () => onClickElement?.(item),
      onToggleElement: event => onToggleElement(item.id, event.target.checked),
    }),
  ),

  memo,
);

const ArchitectureBuildingBlocksListItem = ({
  item,
  checked,
  onToggleElement,
  selectable,
  onClickElement,
  active,
  counter,
}) => (
  <button
    onClick={onClickElement}
    type="button"
    className={cn(
      classNames.listElement,
      selectable && classNames.selectable,
      active && classNames.active,
    )}
  >
    <p className={classNames.elementTitleContainer}>
      {item.name}

      {!item.description && counter !== 0 && (
        <span className={classNames.counter}>{counter}</span>
      )}
      {item.description && (
      <Tooltip placement="top" title={item.description}>
        <span className={classNames.infoTooltip}>
          <Asset name={ICON_MAP.info} />
        </span>
      </Tooltip>
      )}
    </p>
    {selectable && <span className={classNames.chevron}><Asset name={ICON_MAP.chevron} /></span>}
    {checked !== undefined && <Checkbox checked={checked} onChange={onToggleElement} />}
  </button>
);

ArchitectureBuildingBlocksListItem.displayName = 'ArchitectureBuildingBlocksListItem';

ArchitectureBuildingBlocksListItem.propTypes = {
  item: architectureBuildingBlockShape.isRequired,
  checked: PropTypes.bool,
  onToggleElement: PropTypes.func.isRequired,
  onClickElement: PropTypes.func,
  active: PropTypes.bool,
  selectable: PropTypes.bool.isRequired,
  counter: PropTypes.number,
};

ArchitectureBuildingBlocksListItem.defaultProps = {
  checked: undefined,
  onClickElement: PropTypes.func,
  active: PropTypes.bool,
  counter: 0,
};

export default enhancer(ArchitectureBuildingBlocksListItem);
