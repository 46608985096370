import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PRODUCT_HOSTING_TYPES } from 'config/constants';
import productMessages from 'react/business/products/product.messages';

export const hostingTypesOptions = [
  {
    value: PRODUCT_HOSTING_TYPES.OPA,
    label: <FormattedMessage {...productMessages.OPA} />,
  },
  {
    value: PRODUCT_HOSTING_TYPES.OPB,
    label: <FormattedMessage {...productMessages.OPB} />,
  },
  {
    value: PRODUCT_HOSTING_TYPES.PRC,
    label: <FormattedMessage {...productMessages.PRC} />,
  },
  {
    value: PRODUCT_HOSTING_TYPES.PUC,
    label: <FormattedMessage {...productMessages.PUC} />,
  },
  {
    value: PRODUCT_HOSTING_TYPES.SAAS,
    label: <FormattedMessage {...productMessages.SAAS} />,
  },
];

export const hostingTypesInfoLink = 'https://adeo.collibra.com/asset/e62d7769-d0b0-4f53-8538-87e05f7b1a32';
