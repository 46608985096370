import React, { memo } from 'react';
import { compose } from 'recompose';

import { required } from 'react/generic/form/validators';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import AutoCompleteCommunity from 'react/business/products/form/autocompletes/Community';

import PropTypes from 'prop-types';
import messages from './community.messages';

const rules = [required];

const enhancer = compose(
  memo,
);

const CommunityField = ({ disabled }) => (
  <Field
    type="object"
    name="community"
    title={<FormattedMessage {...messages.COMMUNITY_TITLE} />}
    tooltip={<FormattedMessage {...messages.COMMUNITY_HINT} />}
    component={AutoCompleteCommunity}
    validate={rules}
    disabled={disabled}
  />
);

CommunityField.displayName = 'CommunityField';

CommunityField.propTypes = {
  disabled: PropTypes.bool,
};

CommunityField.defaultProps = {
  disabled: false,
};

export default enhancer(CommunityField);
