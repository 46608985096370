import { noop } from 'lodash';
import React, { memo } from 'react';
import { compose, withPropsOnChange } from 'recompose';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { messageShape } from 'shapes/vendor';

import classNames from './tabs.module.scss';

const enhancer = compose(
  memo,
  withPropsOnChange(
    ['id', 'onClick'],
    ({ id, onClick }) => ({
      onClick: () => onClick(id),
    }),
  ),
);

const Tab = ({
  label,
  isActive,
  onClick,
  className,
}) => (
  <button
    type="button"
    className={cn(
      className,
      classNames.button,
      isActive && classNames.active,
    )}
    onClick={onClick}
  >
    <FormattedMessage {...label} />
  </button>
);

Tab.displayName = 'Tab';

Tab.propTypes = {
  label: messageShape.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

Tab.defaultProps = {
  isActive: false,
  onClick: noop,
  className: null,
};

const EnhancedTab = enhancer(Tab);

EnhancedTab.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

EnhancedTab.defaultProps = {
  onClick: noop,
  className: null,
};

export default EnhancedTab;
