import { defineMessages } from 'react-intl';

export default defineMessages({
  SOFTWARE_PUBLISHER_TITLE: { id: 'product.form.fields.softwarePublisher.title', defaultMessage: 'Software Publisher' },

  SOFTWARE_PUBLISHER_HINT: {
    id: 'product.form.fields.softwarePublisher.hint',
    defaultMessage: 'Select the Software Publisher of your product. If it does not exist yet, create it.',
  },
});
