import React, { PureComponent } from 'react';
import { Checkbox, Icon } from 'antd';
import { compose, pure, withPropsOnChange } from 'recompose';
import PropTypes from 'prop-types';

import { markShape } from 'shapes/product';
import { filtersShape } from 'react/business/review/reviews.shapes';

import { FILTERS } from 'react/business/review/reviews.constants';

import LinearProgress from 'react/generic/progress/LinearProgress';
import RateLabel from 'react/generic/rate/RateLabel';
import Rate from 'react/generic/rate/RateWithRoundedValue';
import { FormattedMessage } from 'react-intl';
import classNames from './evaluation.module.scss';
import messages from '../../business/review/form/base/steps/form/fields.messages';

const enhancer = compose(
  withPropsOnChange(
    ['onSetFilter'],
    ({ onSetFilter }) => ({
      onSetMarks: value => onSetFilter(FILTERS.MARKS, value),
    }),
  ),

  pure,
);

class Evaluation extends PureComponent {
  static propTypes = {
    mark: markShape.isRequired,
    filters: filtersShape,
    onSetMarks: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filters: {},
  };

  /**
   * Renders the component.
   *
   * @returns {object} - The React element.
   */
  render() {
    const { mark, filters, onSetMarks } = this.props;
    const {
      average,
      count,
      detail,
      detailedMarks,
    } = mark;

    return (
      <div>
        <div className={classNames.averageContainer}>
          <Rate
            disabled
            value={average}
            className={classNames.rateStars}
            allowHalf
          />
          <RateLabel
            mark={average}
            className={classNames.averageLabel}
          />
        </div>

        <div className={classNames.ratesDetailsContainer}>
          <Checkbox.Group value={filters[FILTERS.MARKS]} onChange={onSetMarks}>
            { Object.keys(detail)
              .sort((a, b) => b - a)
              .map(rate => (
                <React.Fragment
                  key={rate}
                >
                  <div className={classNames.rateMark}>
                    <Checkbox
                      className={classNames.checkbox}
                      value={Number.parseInt(rate, 10)}
                    />
                    {rate}
                  </div>

                  <div>
                    <Icon
                      aria-hidden
                      className={classNames.starIcon}
                      type="star"
                      theme="filled"
                    />
                  </div>

                  <div className={classNames.rateDistribution}>
                    <LinearProgress
                      className={classNames.progress}
                      contentClassName={classNames.progressContent}
                      progress={detail[rate] ? Math.round(detail[rate] / count * 100) : 0}
                    />
                  </div>

                  <div className={classNames.rateCount}>{detail[rate]}</div>
                </React.Fragment>
              ))
            }
          </Checkbox.Group>
        </div>

        { detailedMarks && (
        <div className={classNames.detailedMarks}>
          { Object.keys(detailedMarks)
            .map(markName => (
              <div
                key={markName}
                className={classNames.detailedMarksRow}
              >
                <div className={classNames.detailedMarkLabel}>
                  <RateLabel mark={detailedMarks[markName]} />
                </div>
                <div className={classNames.detailedMarkStar}>
                  <Icon
                    aria-hidden
                    className={classNames.bigStarIcon}
                    type="star"
                    theme="filled"
                  />
                </div>
                <div className={classNames.detailedMarkName}>
                  <FormattedMessage {...messages[markName]} />
                </div>
              </div>
            ))
          }
        </div>
        ) }
      </div>
    );
  }
}

export default enhancer(Evaluation);
