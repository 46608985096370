import { defineMessages } from 'react-intl';

export default defineMessages({
  GIVE_YOURS: { id: 'product.feedbacks.giveYours', defaultMessage: 'Add a feedback' },
  REVIEWS: {
    id: 'product.feedbacks.reviews',
    defaultMessage: '{count, plural, =0 {feedback} one {feedback} other {feedbacks}}',
  },
  REVIEWS_NO: { id: 'generic.filter.reviews_no', defaultMessage: 'Reviews on page : {reviewsNo}' },
});
