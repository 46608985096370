import React, { memo } from 'react';
import RequestForm from 'react/business/products/form/steps/step-0-request/RequestForm';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'redux';
import { withHandlers } from 'recompose';
import ProductFormStepLayout from '../layout/StepLayout';
import { PRODUCT_FORM_STEPS } from '../../../../business/products/form/steps/form-steps.constants';

const enhancer = compose(
  withHandlers({
    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.PRESENTATION),
  }),
  memo,
);

const ProductFormRequestStep = ({
  onClickNextStep,
  onOpenPreview,
}) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.REQUEST}
    form={<RequestForm />}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormRequestStep.propTypes = {
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ProductFormRequestStep.defaultProps = {
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ProductFormRequestStep);
