import { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { branch, compose, renderNothing } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectCanEditProduct } from 'redux/products/selectors';

import ContributeButton from './ContributeButton';

const enhancer = compose(
  connect((state, props) => ({
    canEditProduct: selectCanEditProduct(state, props.productId),
  })),

  memo,

  branch(
    ({ canEditProduct }) => !canEditProduct,
    renderNothing,
  ),
);

const EnhancedEditButton = enhancer(ContributeButton);

EnhancedEditButton.displayName = 'EditButton';

EnhancedEditButton.propTypes = {
  onClickEdit: PropTypes.func,
};

EnhancedEditButton.defaultProps = {
  onClickEdit: noop,
};

export default EnhancedEditButton;
