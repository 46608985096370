import React from 'react';
import { keyBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import productMessages from 'react/business/products/product.messages';
import PropTypes from 'prop-types';
import classNames from './propositionType.module.scss';

export const propositionOptions = [
  {
    value: 0,
    label: <FormattedMessage {...productMessages.FULFILL_ALL} />,
    default: true,
  },
  { value: 1, label: <FormattedMessage {...productMessages.DIGITAL_RESOURCES} /> },
  { value: 2, label: <FormattedMessage {...productMessages.CREATE_TEAM} /> },
  { value: 3, label: <FormattedMessage {...productMessages.SUPPORT_MANAGEMENT} /> },
  { value: 4, label: <FormattedMessage {...productMessages.INVOICE_COST} /> },
  { value: 5, label: <FormattedMessage {...productMessages.ANOTHER_REASON} /> },
];

export const propositionTypesOptionsMap = keyBy(propositionOptions, 'value');

const Proposition = ({ proposition }) => (
  <div className={classNames.proposition}>
    {propositionTypesOptionsMap[proposition]?.label}
  </div>
);

Proposition.displayName = 'Proposition';

Proposition.propTypes = {
  proposition: PropTypes.number,
};

Proposition.defaultProps = {
  proposition: null,
};

export default Proposition;
