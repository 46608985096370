import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes, getFormSyncErrors } from 'redux-form';
import {
  compose,
  lifecycle,
  withPropsOnChange,
  withProps,
} from 'recompose';
import connect from 'react/hoc/connectProxy';

import makeListField from 'react/generic/form/ListField/ListField';
import { ACTIONS_POSITIONS, ITEM_TYPES } from 'react/generic/form/ListField/constants';
import AddMoreButton from 'react/generic/form/ListField/add-more/AddMoreButton';

import { teamMemberFactory } from 'services/products/products-form';
import { getRolesOnTop, getUsersByExternalIds } from 'redux/users/actions';
import { selectRolesOnTopList } from 'redux/users/selectors';

import themeVariables from 'style/theme.variables.scss';
import TeamMemberFields from './teamMember/TeamMemberFields';
import messages from './team-form.messages';
import classNames from './team-form.module.scss';

const ListField = makeListField(ITEM_TYPES.TEAM_MEMBERS);

const enhancer = compose(
  connect((state, props) => ({
    roles: selectRolesOnTopList(state),
    error: getFormSyncErrors(props.formName)(state).teamMembers,
  }),
  {
    getRolesOnTop, getUsersByExternalIds,
  }),

  lifecycle({
    /** Load top roles on mount. */
    componentDidMount() {
      this.props.getRolesOnTop();
    },
  }),

  withPropsOnChange(
    ['roles'],
    (props) => {
      if (props.roles.length > 0 && props.fields.length < props.roles.length) {
        props.roles.sort((a, b) => {
          if (a.id === '03f0db29-c2f0-433c-853c-6affb8553b95') return -1;
          if (b.id === '03f0db29-c2f0-433c-853c-6affb8553b95') return 1;
          return 0;
        });
        props.roles.forEach((role) => {
          props.fields.push({
            id: role.id,
            role: {
              ...role,
              value: role.id,
            },
          });
        });
      }
    },
  ),

  withProps(({ meta }) => ({
    formName: meta.form,
    error: meta.error,
  })),
);

const required = {
  color: themeVariables.bittersweet,
  display: 'flex',
  justifyContent: 'flex-end',
};

const TeamForm = ({ fields, formName, error }) => {
  const onAdd = useCallback(
    () => {
      fields.push(teamMemberFactory());
    },
    [fields],
  );

  return (
    <>
      {error && <div style={required}>{error}</div>}
      <ListField
        onAdd={onAdd}
        actionsPosition={ACTIONS_POSITIONS.TOP}
        fields={fields}
        fieldProps={{ formName }}
      >
        <TeamMemberFields />
      </ListField>
      <div className={classNames.buttonContainer}>
        <AddMoreButton onClick={onAdd} message={messages.ADD_NEW_TEAM_MEMBER} />
      </div>
    </>
  );
};

TeamForm.displayName = 'TeamForm';
TeamForm.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  formName: PropTypes.string,
  error: PropTypes.node,
};

TeamForm.defaultProps = {
  formName: '',
  error: null,
};

export default enhancer(TeamForm);
