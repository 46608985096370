import { compose, lifecycle, withProps } from 'recompose';
import connect from 'react/hoc/connectProxy';
import withReviewsFilters from 'react/business/review/filters/withFiltersControllerInQueryString';

import { getProductReviews } from 'redux/reviews/actions';
import {
  selectProductReviewsListById,
  selectProductReviewsCount,
  selectReviewsCurrentCount,
} from 'redux/reviews/selectors';
import { REVIEWS_PAGINATION_ITEM_NUMBER } from 'config/constants';

export default compose(
  withReviewsFilters((props) => {
    if (props.product) return props.product.id;
    return props.component.id;
  }),

  connect(
    (state, props) => ({
      reviews: selectProductReviewsListById(state, props.product?.id || props.component?.id),
      totalCount: selectProductReviewsCount(state, props.product?.id || props.component?.id),
      currentCount: selectReviewsCurrentCount(state, props.product?.id || props.component?.id),
    }),
    {
      getProductReviews,
    },
  ),

  withProps(props => ({
    onLoadPage: (id, { preserve = true }) => {
      props.getProductReviews(
        id,
        {
          ...props.filters,
          skip: preserve ? props.currentCount : 0,
          limit: REVIEWS_PAGINATION_ITEM_NUMBER,
          preserve,
        },
      );
    },
  })),

  lifecycle({
    /**
     * Get the list of reviews on Mount.
     */
    async componentDidMount() {
      await this.props.onLoadPage(
        this.props.product?.id || this.props.component?.id,
        { preserve: false },
      );
    },

    /**
     * Get the list of reviews on Update.
     *
     * @param {object} prevProps - Prev props.
     */
    async componentDidUpdate(prevProps) {
      if (prevProps.location.search !== this.props.location.search) {
        await this.props.onLoadPage(
          this.props.product?.id || this.props.component?.id,
          { preserve: false },
        );
      }
    },
  }),
);
