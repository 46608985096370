import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { compose, renameProp } from 'recompose';

import { PRODUCT_STAGES } from 'config/constants';
import { stagesOptions } from 'react/business/products/stages/stages.constants';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import { DebouncedReduxFormInput } from 'react/generic/form/input/Input';
import { DebouncedReduxFormTextArea } from 'react/generic/form/input/TextArea';
import { ReduxFormSelectWithValueSelector } from 'react/generic/form/select/Select';

import classNames from './modules-form-item.module.scss';
import messages from './modules-form-item.messages';

const enhancer = compose(
  renameProp('field', 'module'),
);

const stagesOptionsModule = stagesOptions.filter(stage => stage.value !== PRODUCT_STAGES.INI);

const ModulesFormItem = ({ module, ...props }) => (
  <div className={classNames.fieldsContainer}>
    <FormattedMessageChildren {...messages.FIELD_TITLE_PLACEHOLDER}>
      {titlePlaceholder => (
        <Field
          name={`${module}.title`}
          type="text"
          placeholder={titlePlaceholder}
          component={DebouncedReduxFormInput}
          {
            ...props
          }
        />
      )}
    </FormattedMessageChildren>

    <FormattedMessageChildren {...messages.FIELD_DESCRIPTION_PLACEHOLDER}>
      {descriptionPlaceholder => (
        <Field
          name={`${module}.description`}
          type="text"
          rows={3}
          placeholder={descriptionPlaceholder}
          component={DebouncedReduxFormTextArea}
        />
      )}
    </FormattedMessageChildren>

    <FormattedMessageChildren {...messages.FIELD_STATUS_PLACEHOLDER}>
      {statusPlaceholder => (
        <Field
          name={`${module}.status`}
          type="text"
          placeholder={statusPlaceholder}
          component={ReduxFormSelectWithValueSelector}
          options={stagesOptionsModule}
          isSearchable={false}
          fieldType="module"
          {
            ...props
          }
        />
      )}
    </FormattedMessageChildren>
  </div>
);

ModulesFormItem.displayName = 'ModulesFormItem';

ModulesFormItem.propTypes = {
  module: PropTypes.string.isRequired,
};

export default enhancer(ModulesFormItem);
