import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import layoutClassNames from '../layout.module.scss';
import classNames from './section-title.module.scss';

const enhancer = compose(
  memo,
);

const SectionTitle = ({
  centered,
  children,
  className,
  pageType,
  ...props
}) => (
  <h2
    {...props}
    className={cn(
      className,
      classNames.title,
      centered && classNames.centered,
      layoutClassNames.sectionTitle,
      layoutClassNames[`sectionTitle--${pageType}`],
    )}
  >
    {children}
  </h2>
);

SectionTitle.displayName = 'ProductSectionTitle';

SectionTitle.propTypes = {
  centered: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  pageType: PropTypes.string,
};

SectionTitle.defaultProps = {
  className: null,
  centered: false,
  pageType: '',
};

export default enhancer(SectionTitle);
