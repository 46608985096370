import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';

import globalMessages from 'config/global.messages';

import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import IconButton from 'react/generic/button/IconButton';

import classNames from './delete-button.module.scss';

const enhancer = compose(
  memo,
);

const DeleteButton = ({
  onRemove,
}) => (
  <Tooltip title={<FormattedMessage {...globalMessages.REMOVE} />}>
    <IconButton
      outline
      overrideClassName={classNames.deleteButton}
      overrideClassNames={classNames}
      onClick={onRemove}
    >
      <Asset name={ICON_MAP.x} />
    </IconButton>
  </Tooltip>
);

DeleteButton.displayName = 'DeleteButton';

DeleteButton.propTypes = {
  onRemove: PropTypes.func,
};

DeleteButton.defaultProps = {
  onRemove: noop,
};

export default enhancer(DeleteButton);
