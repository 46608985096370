import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import LoadMore from 'react/generic/pagination/loadMore/LoadMore';

import messages from './LoadMore.messages';
import classNames from './LoadMore.module.scss';

const withLoadMore = ({
  currentCount,
  totalCount,
  onClickLoadMore,
  ...props
}) => {
  const renderCount = () => (
    <FormattedMessage {...messages.COUNT} values={{ currentCount, totalCount }} />
  );

  return (
    <>

      <div className={classNames.loadMoreContainer}>
        <LoadMore
          {...props}
          currentCount={currentCount}
          totalCount={totalCount}
          onClickLoadMore={onClickLoadMore}
          renderCount={renderCount}
        />
      </div>
    </>
  );
};

withLoadMore.displayName = 'LoadMoreSuggestions';

withLoadMore.propTypes = {
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
  onClickLoadMore: PropTypes.func,
  children: PropTypes.node,
};

withLoadMore.defaultProps = {
  currentCount: 0,
  totalCount: 0,
  onClickLoadMore: noop,
  children: null,
};

export default withLoadMore;
