import React, { memo } from 'react';
import { compose } from 'recompose';

import { FieldArray } from 'redux-form';
import TeamForm from 'react/business/team/form/TeamForm';

import { DPLRequired } from 'react/generic/form/validators';

const enhancer = compose(
  memo,
);
const rules = [DPLRequired];

const TeamMembersField = () => (
  <FieldArray
    name="teamMembers"
    component={TeamForm}
    validate={rules}
  />
);

TeamMembersField.displayName = 'TeamMembersField';

export default enhancer(TeamMembersField);
