import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import globalMessages from 'config/global.messages';

import { FormattedMessage } from 'react-intl';
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';
import MessageCardContent from 'react/generic/message/messageCard/content/Content';
import MessageCardAuthor from 'react/generic/message/messageCard/author/Author';
import MessageCardDate from 'react/generic/message/messageCard/date/Date';
import connect from 'react/hoc/connectProxy';

import { userShape } from 'shapes/user';
import { dateShape } from 'shapes/generic';
import { isLoadingCreateReply } from 'redux/suggestions/replies/selectors';

import createReplyFormChildrenContainer from './createReplyForm.children.container';
import CreateReplyForm from './CreateReplyForm.presentation';

import messages from './create-reply-form.messages';
import classNames from './createReplyFormDialog.module.scss';

const CreateReplyFormChildren = compose(
  createReplyFormChildrenContainer,
)(CreateReplyForm);

const enhancer = compose(
  memo,

  connect(
    state => ({
      isLoading: isLoadingCreateReply(state),
    }),
    null,
  ),
);

const CreateReplyFormDialog = ({
  onSubmit, content, title, author, suggestionDate, isLoading, ...props
}) => (
  <Dialog
    {...props}
    contentClassName={classNames.dialog}
  >
    <CreateReplyFormChildren onSubmit={onSubmit}>
      {({ form, canSubmit }) => (
        <>
          <DialogTitle>

            <FormattedMessage {...messages.REPLY_FORM_DIALOG_TITLE} />
          </DialogTitle>

          <DialogContent>
            <div className={classNames.suggestionInfo}>
              <MessageCardAuthor author={author} compact className={classNames.author} />
              <MessageCardDate date={suggestionDate} />
            </div>
            <div className={classNames.originalMessage}>
              <div className={classNames.title}>{title}</div>
              <MessageCardContent
                withTranslateButton={false}
                content={content}
                compact
              />
            </div>
            {form}
          </DialogContent>

          <DialogActions>
            <DialogAction
              outline
              onClick={props.onRequestClose}
            >
              <FormattedMessage {...globalMessages.CANCEL} />
            </DialogAction>

            <DialogAction
              type="submit"
              disabled={isLoading || !canSubmit}
            >
              <FormattedMessage {...globalMessages.SUBMIT} />
            </DialogAction>

          </DialogActions>
        </>
      )}
    </CreateReplyFormChildren>
  </Dialog>
);

CreateReplyFormDialog.displayName = 'CreateReplyFormDialog';

CreateReplyFormDialog.propTypes = {
  onSubmit: PropTypes.func,
  onRequestClose: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string,
  author: userShape,
  suggestionDate: dateShape,
  isLoading: PropTypes.bool,
};

CreateReplyFormDialog.defaultProps = {
  onSubmit: noop,
  onRequestClose: noop,
  content: null,
  title: null,
  author: {},
  suggestionDate: null,
  isLoading: false,
};

export default enhancer(CreateReplyFormDialog);
