import { Field, change as changeAction } from 'redux-form';
import React, {
  memo,
} from 'react';
import {
  compose, lifecycle,
} from 'recompose';

import connect from 'react/hoc/connectProxy';
import { MAX_PRODUCT_NAME_LENGTH } from '../../../../../../config/constants';
import classNames from './parent-digital-product.module.scss';
import ProductCard from '../../../card/ProductCard';
import { productShape } from '../../../../../../shapes/product';
import { selectFormParentProduct } from '../../../../../../redux/products/selectors';
import { required } from '../../../../../generic/form/validators';
import { selectExternalUsersMap } from '../../../../../../redux/users/selectors';
import { getUsersByExternalIds } from '../../../../../../redux/users/actions';
import { withProductFormMode } from '../../Context';

const enhancer = compose(
  memo,
  withProductFormMode(),
  connect(
    state => ({
      formParentProduct: selectFormParentProduct(state),
      externalUsersMap: selectExternalUsersMap(state),
    }
    ),
    dispatch => ({
      dispatch,
      getUsersByExternalIds: ids => dispatch(getUsersByExternalIds(ids)),
      change: changeAction,
    }
    ),
  ),
  lifecycle({
    /**
     * Set the parent product as the parentDigitalProduct field.
     */
    async componentWillMount() {
      this.props.dispatch(changeAction(this.props.formName, 'parentDigitalProduct', this.props.parent, true, false));
    },
  }),

);

/*
const ComponentSearch = ({ onSelectResult }) => (
  <Router history={history}>
    <IntlProvider>
      <Provider store={storeWithoutRouter}>
        <SearchAutocomplete
          searchContext="nonComponentSearch"
          onResultClick={onSelectResult}
        />
      </Provider>
    </IntlProvider>
  </Router>
);

ComponentSearch.propTypes = {
  onSelectResult: PropTypes.func.isRequired,
};
*/

const ParentDigitalProduct = ({
  // eslint-disable-next-line no-shadow
  formParentProduct,
}) => (
  <>
    {formParentProduct?.id && (
    <ProductCard product={formParentProduct} className={classNames.productCard} />
      )}
    <Field
      type="hidden"
      name="parentDigitalProduct"
      component="input"
      maxLength={MAX_PRODUCT_NAME_LENGTH}
      validate={[required]}
    />
  </>

);

ParentDigitalProduct.propTypes = {
  formParentProduct: productShape.isRequired,
};

export default enhancer(ParentDigitalProduct);
