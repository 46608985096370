import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { isEmpty, noop } from 'lodash';

import { externalImageShape } from 'shapes/generic';
import {
  ACCEPT_MIME_TYPES,
  ENABLE_MULTIPLE_UPLOAD,
  MAX_SIZE_FILE_UPLOAD,
  MAX_UPLOAD_ALT_TEXT_LENGTH,
} from 'config/constants';

import DropZone from 'react-dropzone';
import Input from 'react/generic/form/input/Input';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import Field from '../field/Field';
import UploadContainer from './container/UploadContainer';
import DropzoneProgress from './progress/DropzoneProgress';
import Preview from './preview/Preview';
import Placeholder from './placeholder/Placeholder';
import Constraints from './constraints/Constraints';
import DeleteButton from './actions/delete/DeleteButton';
import ErrorMessage from './error/Error';

import messages from './dropzone-field.messages';
import classNames from './dropzone-field.module.scss';

const enhancer = compose(
  memo,
);

const DropZoneField = ({
  progress,
  maxSize,
  multiple,
  accept,
  onDrop,
  onDropRejected,
  onRemove,
  onChangeAlt,
  value,
  error,
  withAltZone,
  title,
  tooltip,
  loading,
  onBlur,
}) => (
  <Field
    title={title}
    tooltip={tooltip}
  >
    <div className={classNames.dropZoneConstraintsContainer}>
      <DropZone
        onDrop={onDrop}
        multiple={multiple}
        maxSize={maxSize}
        accept={accept}
        onDropRejected={onDropRejected}
        onBlur={onBlur}
      >
        {({
          isDragAccept,
          isDragReject,
          getRootProps,
          getInputProps,
        }) => (
          <UploadContainer
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          >
            { !isEmpty(value?.url) ? (
              <Preview
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                picture={value}
              />
            ) : (
              <Placeholder
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                onBlur={onBlur}
              />
            ) }

            <DropzoneProgress visible={loading} progress={progress} />

            {/* Display delete button only when a picture is set */}
            { !isEmpty(value?.url)
              && (
                <div className={classNames.deleteContainer}>
                  <DeleteButton onRemove={onRemove} />
                </div>
              )}
          </UploadContainer>
        ) }
      </DropZone>

      <div>
        <div className={classNames.sideContainer}>
          <Constraints
            accept={accept}
            maxSize={maxSize}
          />
        </div>

        { !!error
          && (
            <div className={classNames.sideContainer}>
              <ErrorMessage error={error} />
            </div>
          ) }
      </div>
    </div>

    { withAltZone
      && (
        <div className={classNames.altZoneContainer}>
          <FormattedMessageChildren {...messages.ALT_PLACEHOLDER}>
            { placeholder => (
              <Input
                value={value?.alt}
                onChange={onChangeAlt}
                disabled={!value?.url}
                placeholder={placeholder}
                onBlur={onBlur}
                maxLength={MAX_UPLOAD_ALT_TEXT_LENGTH}
              />
            )}
          </FormattedMessageChildren>
        </div>
      )}
  </Field>
);

DropZoneField.propTypes = {
  // Current upload progress.
  progress: PropTypes.number,
  // Maximum file size (in bytes).
  maxSize: PropTypes.number,
  // Allow drag 'n' drop of multiple files.
  multiple: PropTypes.bool,
  // Set accepted file types (MIME types).
  accept: PropTypes.string,
  // Handle the drop event.
  onDrop: PropTypes.func.isRequired,
  // Handle the remove event.
  onRemove: PropTypes.func.isRequired,
  // Handle the on change event on the input.
  onChangeAlt: PropTypes.func,
  // Handle the onDropRejected event
  onDropRejected: PropTypes.func,
  // The uploaded picture.
  value: externalImageShape,
  error: PropTypes.node,
  withAltZone: PropTypes.bool,
  title: PropTypes.node,
  tooltip: PropTypes.node,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
};

DropZoneField.defaultProps = {
  progress: 0,
  maxSize: MAX_SIZE_FILE_UPLOAD,
  multiple: ENABLE_MULTIPLE_UPLOAD,
  accept: ACCEPT_MIME_TYPES,
  onChangeAlt: noop,
  onDropRejected: noop,
  value: {},
  error: null,
  withAltZone: false,
  title: null,
  tooltip: null,
  loading: false,
  onBlur: noop,
};

export default enhancer(DropZoneField);
