import * as RepliesService from 'services/suggestions/replies';
import { selectUserPreferences } from 'redux/users/selectors';

import { ACTIONS } from './constants';

export const GET_REPLIES = (productId, suggestionId, replyId, query) => ({
  type: ACTIONS.GET_REPLIES,
  productId,
  suggestionId,
  replyId,
  query,
});

export const GET_REPLIES_SUCCESS = (productId, suggestionId, replyId, replies) => ({
  type: ACTIONS.GET_REPLIES_SUCCESS,
  productId,
  suggestionId,
  replyId,
  replies,
});

export const GET_REPLIES_FAILURE = (productId, suggestionId, replyId, error) => ({
  type: ACTIONS.GET_REPLIES_FAILURE,
  productId,
  suggestionId,
  replyId,
  error,
});

export const getReplies
  = (productId, suggestionId, replyId, query) => async (dispatch, getState) => {
    const preferences = selectUserPreferences(getState());

    dispatch(GET_REPLIES(productId, suggestionId, replyId, query));

    try {
      const replies = await RepliesService.getReplies(productId, suggestionId, replyId, {
        ...query,
        lang: preferences.alwaysTranslateContent ? preferences.language : null,
      });

      return dispatch(GET_REPLIES_SUCCESS(productId, suggestionId, replyId, replies));
    } catch (err) {
      return dispatch(GET_REPLIES_FAILURE(productId, suggestionId, replyId, err));
    }
  };

export const GET_REPLY = (productId, suggestionId, replyId, query) => ({
  type: ACTIONS.GET_REPLY,
  productId,
  suggestionId,
  replyId,
  query,
});

export const GET_REPLY_SUCCESS = (productId, suggestionId, reply) => ({
  type: ACTIONS.GET_REPLY_SUCCESS,
  productId,
  suggestionId,
  reply,
});

export const GET_REPLY_FAILURE = (productId, suggestionId, replyId, error) => ({
  type: ACTIONS.GET_REPLY_FAILURE,
  productId,
  suggestionId,
  replyId,
  error,
});

export const getReply = (productId, suggestionId, replyId, query) => async (dispatch) => {
  dispatch(GET_REPLY(productId, suggestionId, replyId, query));

  try {
    const reply = await RepliesService.getReply(productId, suggestionId, replyId, query);

    return dispatch(GET_REPLY_SUCCESS(productId, suggestionId, reply));
  } catch (err) {
    return dispatch(GET_REPLY_FAILURE(productId, suggestionId, replyId, err));
  }
};

export const CREATE_REPLY = (productId, suggestionId, replyId, reply) => ({
  type: ACTIONS.CREATE_REPLY,
  productId,
  suggestionId,
  replyId,
  reply,
});

export const CREATE_REPLY_SUCCESS = (productId, suggestionId, replyId, reply) => ({
  type: ACTIONS.CREATE_REPLY_SUCCESS,
  productId,
  suggestionId,
  replyId,
  reply,
});

export const CREATE_REPLY_FAILURE = (productId, suggestionId, replyId, error) => ({
  type: ACTIONS.CREATE_REPLY_FAILURE,
  productId,
  suggestionId,
  replyId,
  error,
});

export const createReply = (productId, suggestionId, replyId, reply) => async (dispatch) => {
  dispatch(CREATE_REPLY(productId, suggestionId, replyId, reply));

  try {
    const createdReply = await RepliesService.createReply(productId, suggestionId, replyId, reply);
    return dispatch(CREATE_REPLY_SUCCESS(productId, suggestionId, replyId, createdReply));
  } catch (err) {
    return dispatch(CREATE_REPLY_FAILURE(productId, suggestionId, replyId, err));
  }
};

export const VOTE_REPLY = (productId, suggestionId, replyId, vote) => ({
  type: ACTIONS.VOTE_REPLY,
  productId,
  suggestionId,
  replyId,
  vote,
});

export const VOTE_REPLY_SUCCESS = (productId, suggestionId, reply) => ({
  type: ACTIONS.VOTE_REPLY_SUCCESS,
  productId,
  suggestionId,
  reply,
});

export const VOTE_REPLY_FAILURE = (productId, suggestionId, replyId, error) => ({
  type: ACTIONS.VOTE_REPLY_FAILURE,
  productId,
  suggestionId,
  replyId,
  error,
});

export const voteReply = (productId, suggestionId, replyId, vote) => async (dispatch) => {
  dispatch(VOTE_REPLY(productId, suggestionId, replyId, vote));

  try {
    const reply = await RepliesService.voteReply(productId, suggestionId, replyId, vote);
    return dispatch(VOTE_REPLY_SUCCESS(productId, suggestionId, reply));
  } catch (err) {
    return dispatch(VOTE_REPLY_FAILURE(productId, suggestionId, replyId, err));
  }
};
