import { defineMessages } from 'react-intl';

export default defineMessages({
  HEADER_TITLE_PRESENTATION: {
    id: 'pages.product.form.step.1.presentation.header.title',
    defaultMessage: 'Presentation',
  },

  HEADER_MESSAGE_PRESENTATION: {
    id: 'pages.product.form.step.1.presentation.header.message',
    defaultMessage: 'This information makes up the product file as well as the presentation part of the detailed sheet.',
  },

  COMPONENT_HEADER_MESSAGE_PRESENTATION: {
    id: 'pages.product.form.step.1.presentation.component.header.message',
    defaultMessage: 'This information makes up the component file as well as the presentation part of the detailed sheet.',
  },
});
