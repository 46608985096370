import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from './isMaintainedByPlatform.messages';
import classNames from './isMaintainedByPlatform.module.scss';

const IsMaintainedByPlatform = ({
  isMaintainedByPlatform,
}) => (
  <>
    { isMaintainedByPlatform && (
    <div className={classNames.maintainedByPlatform}>
      <FormattedMessage {...messages.MAINTAINED_BY_THE_PLATFORM} />
    </div>
    )}
  </>
);

IsMaintainedByPlatform.displayName = 'IsMaintainedByPlatform';

IsMaintainedByPlatform.propTypes = {
  isMaintainedByPlatform: PropTypes.bool,
};

IsMaintainedByPlatform.defaultProps = {
  isMaintainedByPlatform: false,
};

export default memo(IsMaintainedByPlatform);
