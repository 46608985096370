import { defineMessages } from 'react-intl';

export default defineMessages({
  ERROR: {
    id: 'product.form.error.title',
    defaultMessage: 'Failure',
  },

  ERROR_HAPPENED: {
    id: 'product.form.error.body',
    defaultMessage: 'An unexpected error occurred while saving your product.',
  },
});
