import React from 'react';
import { FormattedMessage } from 'react-intl';
import { enhancer } from 'react/business/modules/form/ModulesForm';
import messages from './draft-flag.messages';

const DraftFlag = () => (
  <div><FormattedMessage {...messages.DRAFT} /></div>
);

export default enhancer(DraftFlag);
