import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';
import globalMessages from 'config/global.messages';

import messages from './dialog-warning.messages';

const enhancer = compose(
  memo,
);

const DialogWarning = ({
  isModalOpen,
  onCloseModal,
  onRequest,
}) => (
  <Dialog
    isOpen={isModalOpen}
    onRequestClose={onCloseModal}
  >
    <DialogTitle>
      <FormattedMessage {...messages.SUPPRESS_WARNINGS_TITLE} />
    </DialogTitle>

    <DialogContent>
      <FormattedMessage
        {...messages.SUPPRESS_WARNINGS_BODY}
      />
    </DialogContent>

    <DialogActions>
      <DialogAction outline onClick={onCloseModal}>
        <FormattedMessage {...globalMessages.CANCEL} />
      </DialogAction>

      <DialogAction onClick={onRequest}>
        <FormattedMessage {...globalMessages.OK} />
      </DialogAction>
    </DialogActions>
  </Dialog>
);

DialogWarning.displayName = 'DialogWarning';

DialogWarning.propTypes = {
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onRequest: PropTypes.func,
};

DialogWarning.defaultProps = {
  isModalOpen: false,
  onCloseModal: noop,
  onRequest: noop,
};

export default enhancer(DialogWarning);
