import makeSuggestionFormDialog from 'react/business/suggestion/form/dialog/base/SuggestionFormDialog';

import withSubmitEditSuggestion from 'react/business/suggestion/form/base/withSubmitEditSuggestion';
import EditFormStep from './steps/form/Form';
import EditSuccessStep from './steps/success/Success';
import EditFailureStep from './steps/failure/Failure';

// Handle onSubmit to edit a suggestion.
const SubmitEditFormStep = withSubmitEditSuggestion(EditFormStep);

export default makeSuggestionFormDialog(
  SubmitEditFormStep,
  EditSuccessStep,
  EditFailureStep,
);
