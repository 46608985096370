import { defineMessages } from 'react-intl';

export default defineMessages({
  SUGGESTION_FORM_DIALOG_TITLE: {
    id: 'suggestion.form.dialog.edit.title',
    defaultMessage: 'Edit your feature suggestion',
  },

  SUBMIT: {
    id: 'suggestion.form.dialog.edit.submit',
    defaultMessage: 'Update',
  },
});
