import { defineMessages } from 'react-intl';

export default defineMessages({
  FORMATS: {
    id: 'uploads.constraints.formats',
    defaultMessage: 'Formats: {formats}.',
  },

  MAX_SIZE: {
    id: 'upload.constraints.maxSize',
    defaultMessage: 'Max size: {maxSize}.',
  },
});
