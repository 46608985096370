import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Wysiwyg from 'react/generic/form/wysiwyg/Wysiwyg';

import classNames from './createReplyForm.module.scss';

const enhancer = compose(
  memo,
);

const CreateReplyForm = ({ contentDecorator }) => (
  <>
    {contentDecorator(
      <Wysiwyg
        editorClassName={classNames.content}
      />,
    )}
  </>
);

CreateReplyForm.displayName = 'CreateReplyForm';

CreateReplyForm.propTypes = {
  contentDecorator: PropTypes.func.isRequired,
};

export default enhancer(CreateReplyForm);
