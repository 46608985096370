import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { trim } from 'lodash';

import { FormattedMessage } from 'react-intl';
import FileSize from 'react/generic/form/upload/fileSize/FileSize';

import { mapMimeTypeToFileExt } from './constraints.constants';

import messages from './constraints.messages';
import classNames from './constraints.module.scss';

const enhancer = compose(
  memo,
);

const Constraints = ({
  accept,
  maxSize,
}) => (
  <ul className={classNames.constraints}>
    { !!accept
      && (
        <li className={classNames.constraint}>
          <FormattedMessage
            {...messages.FORMATS}
            values={{
              formats: accept
                .split(',')
                .map(mimeType => mapMimeTypeToFileExt[trim(mimeType.toLowerCase())])
                .filter(Boolean)
                .join(', '),
            }}
          />
        </li>
      )}

    { !!maxSize
      && (
        <li className={classNames.constraint}>
          <FormattedMessage
            {...messages.MAX_SIZE}
            values={{ maxSize: <FileSize size={maxSize} /> }}
          />
        </li>
      ) }
  </ul>
);

Constraints.displayName = 'Constraints';

Constraints.propTypes = {
  /** Lists of allowed file formats as mime types. */
  accept: PropTypes.string,
  /** Max size in bytes. Leave 0 if no limit. */
  maxSize: PropTypes.number,
};

Constraints.defaultProps = {
  accept: [],
  maxSize: 0,
};

export default enhancer(Constraints);
