import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Badge from './Badge';

const enhance = compose(
  memo,
);

const BadgeCount = enhance(({ text, ...props }) => (
  <Badge
    {...props}
    text={text > 99 ? '99+' : text}
  />
));

BadgeCount.propTypes = {
  text: PropTypes.number,
};

BadgeCount.defaultProps = {
  text: 0,
};

BadgeCount.displayName = 'BadgeCount';

export default BadgeCount;
