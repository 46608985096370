import React, { memo } from 'react';
import { compose, withProps } from 'recompose';

import { FormattedMessage } from 'react-intl';

import SuggestionDialogStepSuccess from 'react/business/suggestion/form/dialog/base/steps/success/Success';

import messages from './success.messages';

export default compose(
  memo,

  withProps(() => ({
    successMessage: (
      <FormattedMessage {...messages.SUGGESTION_UPDATE_SUCCESS} />
    ),
  })),
)(SuggestionDialogStepSuccess);
