import agent from 'services/http';
import { mapCommunityFromServer, mapDomainFromServer } from './communities.mapper';

const e = encodeURIComponent;

/**
 * GET /api/communities.
 * Get the list of communities.
 *
 * @async
 * @param {object} query - Query parameters.
 * @param {boolean} [query.populateDomains] - If true, get domains details for each community.
 * @param {boolean} [query.countProducts]
 * - If true, get the product count of each domain (only if domains populated).
 * @returns {{communities: Array<object>, count: number}} List of communities.
 */
export async function getCommunities(query = {}) {
  const { body: { communities, count } } = await agent
    .get('/api/communities')
    .query(query);

  return {
    count,
    communities: communities.map(mapCommunityFromServer),
  };
}

/**
 * GET /api/communities/:communityId/domains.
 * Get the domains of a community.
 *
 * @async
 * @param {string} communityId - Community id.
 * @returns {{domains: Array<object>, count: number}} List of domains.
 */
export async function getCommunityDomains(communityId) {
  const { body: { domains, count } } = await agent
    .get(`/api/communities/${e(communityId)}/domains`);

  return {
    count,
    domains: domains.map(mapDomainFromServer),
  };
}

/**
 * GET /api/domains/:domainId.
 * Get a domain.
 *
 * @async
 * @param {string} domainId - Domain id.
 * @param {object} query - Query.
 * @param {boolean} query.populateCommunity - Retrieve the community data.
 * @returns {{domain: object}} The domain.
 */
export async function getDomain(domainId, query) {
  const { body: domain } = await agent
    .get(`/api/domains/${e(domainId)}`)
    .query(query);

  return mapDomainFromServer(domain);
}
