import React, { memo } from 'react';
import PropTypes, { node } from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';
import { productShape } from 'shapes/product';
import classNames from './productComponents.module.scss';
import messages from './product-components.messages';
import connectAutocompleteResults from '../../../search/autocompleteResults/autocompleteResults.connect';
import ComponentCard from './ComponentCard/ComponentCard';
import CreateComponentButton from './createComponentButton/CreateComponentButton';

const enhancer = compose(
  memo,
  connectAutocompleteResults,
);

const ProductComponents = ({ product, canAddComponent, isPreview }) => (
  <Section className={classNames.description}>
    <SectionTitle
      centered
      className={classNames.sectionTitle}
      pageType="product"
    >
      <FormattedMessage {...messages.DESCRIPTION} />
    </SectionTitle>

    {canAddComponent && !product.isDraft && !isPreview
        && (
        <div className={classNames.buttonWrap}>
          <CreateComponentButton message={messages.CREATE_A_COMPONENT} product={product} />
        </div>
        )
    }

    <div className={classNames.componentsList}>
      {(product.components || []).map(component => (component.status !== 'ARCH' ?
        (
          <ComponentCard
            product={product}
            component={component}
            canEditProduct={canAddComponent}
            key={component.id}
          />
        )
        : <></>))}
    </div>
  </Section>
);

ProductComponents.displayName = 'ProductComponents';

ProductComponents.propTypes = {
  components: PropTypes.arrayOf(node),
  product: productShape,
  canAddComponent: PropTypes.bool,
  isPreview: PropTypes.bool,
};

ProductComponents.defaultProps = {
  components: [],
  product: {},
  canAddComponent: false,
  isPreview: false,
};

export default enhancer(ProductComponents);
