import React, { memo } from 'react';
import { compose } from 'recompose';

import { Field } from 'redux-form';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import BusinessActivitiesSelector from 'react/business/businessActivities/selector/BusinessActivitiesSelector';
import businessActivitiesSelectorConnect from 'react/business/businessActivities/selector/business-activities-selector.connect';

const ConnectedBusinessActivitiesSelector = compose(
  reduxFormInputAdapter,
  businessActivitiesSelectorConnect,
)(BusinessActivitiesSelector);

const enhancer = compose(
  memo,
);

const AtomicActivitiesField = () => (
  <Field
    name="atomicActivities"
    component={ConnectedBusinessActivitiesSelector}
  />
);

AtomicActivitiesField.displayName = 'AtomicActivitiesField';

export default enhancer(AtomicActivitiesField);
