import React, { memo } from 'react';
import { compose } from 'recompose';

import { required } from 'react/generic/form/validators';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import AutoCompleteDomain from 'react/business/products/form/autocompletes/Domain';

import PropTypes from 'prop-types';
import messages from './domain.messages';

const rules = [required];

const enhancer = compose(
  memo,
);

const DomainField = ({ disabled }) => (
  <Field
    name="domain"
    title={<FormattedMessage {...messages.DOMAIN_TITLE} />}
    tooltip={<FormattedMessage {...messages.DOMAIN_HINT} />}
    component={AutoCompleteDomain}
    validate={rules}
    disabled={disabled}
  />
);

DomainField.displayName = 'DomainField';

DomainField.propTypes = {
  disabled: PropTypes.bool,
};

DomainField.defaultProps = {
  disabled: false,
};

export default enhancer(DomainField);
