import _ from 'lodash';
import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'redux-form';

import { MAX_LINK_LENGTH, MAX_LINK_TITLE_LENGTH } from 'config/constants';
import { isExternalUrl } from 'react/generic/form/validators';

import LinksForm from 'react/business/links/form/LinksForm';

import messages from './links.messages';

/**
 * Function performing a regex on the name of the fields.
 * Will seperate the string by looking at a dot in it.
 * Example: links.name will get an array like this ['links', 'name'].
 *
 * Used by the validator engine of redux-form.
 * For more info: https://redux-form.com/6.6.3/examples/fieldlevelvalidation/.
 *
 * @param {*} value - Value of the current field.
 * @param {*} allValues - Values in the form.
 * @param {*} props - Props of the component.
 * @param {string} name - Name of the current field in redux-form.
 * @returns {*} - Error message when it get an error.
 */
const isRequiredIfTitle = (value, allValues, props, name) => {
  let errors = null;

  if (value) {
    const words = name.split(/^([^.]+)/);
    const data = _.get(allValues, words[1]);

    if (data.title && !data.link) {
      errors = <FormattedMessage {...messages.LINKS_ERROR} />;
    }
  }

  return errors;
};

const enhancer = compose(
  memo,
);

const LinksField = ({
  linkName,
  name,
  title,
  showAddButton,
  tooltip,
}) => (
  <FieldArray
    key={name}
    name={name}
    linkName={linkName}
    component={LinksForm}
    title={title}
    tooltip={tooltip}
    urlFieldProps={{ validate: [isExternalUrl], maxLength: MAX_LINK_LENGTH }}
    titleFieldProps={{ validate: [isRequiredIfTitle], maxLength: MAX_LINK_TITLE_LENGTH }}
    showAddButton={showAddButton}
  />
);

LinksField.displayName = 'LinksField';

LinksField.propTypes = {
  name: PropTypes.string.isRequired,
  linkName: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  showAddButton: PropTypes.bool.isRequired,
  tooltip: PropTypes.node,
};

LinksField.defaultProps = {
  tooltip: null,
};

export default enhancer(LinksField);
