import { withPropsOnChange } from 'recompose';

/**
 * In case we only have the key (it happens when the form is populated
 * during an edition), do a lookup in the options and find the label
 * to write it down in the select.
 */
export default withPropsOnChange(
  ['options', 'value'],
  ({ options, value }) => ({
    value: (value && value.value && !value.label) ? ({
      ...value,
      label: options.find(opt => opt.value === value.value)?.label,
    }) : value,
  }),
);
