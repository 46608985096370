import React, { memo } from 'react';
import { compose } from 'recompose';

import { Field } from 'redux-form';
import BusinessUnitsMultiAutocomplete from 'react/business/products/form/autocompletes/businessUnits/BusinessUnitsMulti';

const enhancer = compose(
  memo,
);

const BusinessUnitsUsingProductField = () => (
  <Field
    name="businessUnitsUsingProduct"
    component={BusinessUnitsMultiAutocomplete}
  />
);

BusinessUnitsUsingProductField.displayName = 'BusinessUnitsUsingProductField';

export default enhancer(BusinessUnitsUsingProductField);
