import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { PulseSpinner } from 'react-spinners-kit';
import { Tooltip } from 'antd';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { activityShape, atomicActivityShape, macroActivityShape } from 'shapes/businessActivities';
import globalMessages from 'config/global.messages';
import theme from 'style/theme.variables.scss';

import BusinessActivitiesSelectorListElement from '../listElement/BusinessActivitiesSelectorListElement';
import classNames from '../business-activities-selector.module.scss';

const BusinessActivitiesSelectorList = ({
  elements,
  onToggleElement,
  value,
  onClickElement,
  header,
  tooltip,
  loading,
  selectedLineId,
  counters,
}) => (
  <div className={classNames.list}>
    <div className={classNames.listHeader}>
      {header}
      <Tooltip placement="top" title={tooltip}>
        <span className={classNames.infoTooltip}>
          <Asset name={ICON_MAP.info} />
        </span>
      </Tooltip>
    </div>
    <div className={classNames.listContainer}>
      {!loading && elements.map(elt => (
        <BusinessActivitiesSelectorListElement
          checked={value?.includes(elt.id)}
          key={elt.id}
          element={elt}
          onClickElement={onClickElement}
          active={selectedLineId === elt.id}
          onToggleElement={onToggleElement}
          counter={counters[elt.id]}
        />
      ))}
      {!loading && elements.length === 0 && (
      <div className={classNames.noContentContainer}>
        <FormattedMessage {...globalMessages.NOTHING_TO_SHOW} />
      </div>
      )}
      {loading && (
      <div className={classNames.noContentContainer}>
        <PulseSpinner color={theme.primary} />
      </div>
      )}
    </div>
  </div>
);

BusinessActivitiesSelectorList.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.oneOfType([
    macroActivityShape,
    activityShape,
    atomicActivityShape,
  ])),

  selectedLineId: PropTypes.string,
  onClickElement: PropTypes.func,

  header: PropTypes.node.isRequired,
  tooltip: PropTypes.node.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onToggleElement: PropTypes.func,
  loading: PropTypes.bool,

  counters: PropTypes.objectOf(PropTypes.number),
};

BusinessActivitiesSelectorList.defaultProps = {
  elements: [],
  onClickElement: null,
  value: null,
  onToggleElement: noop,
  loading: false,
  selectedLineId: null,
  counters: {},
};

export default memo(BusinessActivitiesSelectorList);
