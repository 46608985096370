import React from 'react';
import { trim } from 'lodash';
import {
  compose,
  withHandlers,
  withStateHandlers,
  mapProps,
} from 'recompose';

import { FormattedMessage } from 'react-intl';

import messages from './with-upload-loader.messages';

export default compose(
  withStateHandlers(
    () => ({
      progress: 0,
      uploadError: '',
      loading: false,
    }),
    {
      setProgress: () => progress => ({ progress }),

      startUpload: () => () => ({
        uploadError: '',
        progress: 0,
        loading: true,
      }),

      stopUpload: () => () => ({
        progress: 0,
        loading: false,
      }),

      setError: () => uploadError => ({
        uploadError,
      }),
    },
  ),

  withHandlers({
    onDrop: ({
      onDrop,
      startUpload,
      stopUpload,
      setProgress,
      setError,
    }) => async (files) => {
      try {
        startUpload();
        await onDrop(files, setProgress);
      } catch (err) {
        setError(<FormattedMessage {...messages.DEFAULT_FAILURE_MESSAGE} />);
      } finally {
        // FIXME, Component will be unmounted so this causes a memory leak.
        // Indeed, uploading and reloading form triggers a UNREGISTER_FIELD
        // from redux-form, which destroys and recreates the component.
        // Therefore, this component will be unmounted before calling stopUpload.
        stopUpload();
      }
    },

    onDropRejected: ({ maxSize, accept = '', setError }) => (files) => {
      const acceptedFormats = accept.split(',').map(trim).filter(Boolean);

      const isTooLarge = files.some(({ size }) => size > maxSize);
      const wrongFormat = files.some(({ type }) => !acceptedFormats.includes(type));

      switch (true) {
        case isTooLarge:
          return setError(<FormattedMessage {...messages.FILE_TOO_LARGE_FAILURE_MESSAGE} />);
        case wrongFormat:
          return setError(<FormattedMessage {...messages.INVALID_FORMAT_FAILURE_MESSAGE} />);
        default:
          return setError(<FormattedMessage {...messages.DEFAULT_FAILURE_MESSAGE} />);
      }
    },

    onRemove: ({ onRemove, setError }) => () => {
      setError(null);
      return onRemove();
    },
  }),

  // Merge uploadError and error from props into a single error prop.
  mapProps(({ error, uploadError, ...props }) => ({
    ...props,
    error: uploadError || error,
  })),
);
