import { defineMessages } from 'react-intl';

export default defineMessages({
  REQUIRED: { id: 'global.form.validation.required', defaultMessage: 'This field is required' },
  MAX_LENGTH: {
    id: 'global.form.validation.maxLength',
    defaultMessage: 'This field cannot be more than {maxLength} {maxLength, plural, one {character} other {characters}} long.',
  },
  EXTERNAL_URL: { id: 'global.form.validation.externalUrl', defaultMessage: 'This URL is invalid.' },
  DCL_REQUIRED: { id: 'global.form.validation.DCLRequired', defaultMessage: 'A Digital Component Leader is required' },
  MANAGERS_REQUIRED: { id: 'global.form.validation.managersRequired', defaultMessage: 'Managers are required' },
});
