import React, { createContext } from 'react';

import { FORM_MODES } from './form.constants';
import { PRODUCT_FORM_STEPS } from './steps/form-steps.constants';

// Expose the form name.
export const ProductFormNameContext = createContext('');

/**
 * Create a HOC exposing the form name in the context.
 *
 * @param {string} name - Form name.
 * @returns {Function} HOC.
 */
export const ProductFormNameContextProviderHoc = name => WrappedComponent => props => (
  <ProductFormNameContext.Provider value={name}>
    <WrappedComponent {...props} />
  </ProductFormNameContext.Provider>
);

/**
 * Create a HOC exposing the current product form name from the context.
 *
 * @param {string} propName - Name of the prop of the wrapped component to set the form name to.
 * @returns {Function} HOC.
 */
export const withProductFormName = (propName = 'formName') => WrappedComponent => props => (
  <ProductFormNameContext.Consumer>
    { name => React.createElement(WrappedComponent, { ...props, [propName]: name }) }
  </ProductFormNameContext.Consumer>
);

// Expose the current mode.
export const ProductFormModeContext = createContext(FORM_MODES.CREATE);

/**
 * Create a HOC exposing the form mode in the context.
 *
 * @param {string} mode - Form mode (create/update).
 * @returns {Function} HOC.
 */
export const ProductFormModeContextProviderHoc = mode => WrappedComponent => props => (
  <ProductFormModeContext.Provider value={mode}>
    <WrappedComponent {...props} />
  </ProductFormModeContext.Provider>
);

/**
 * Create a HOC exposing the current product form mode from the context.
 *
 * @param {string} propName - Name of the prop of the wrapped component to set the form mode to.
 * @returns {Function} HOC.
 */
export const withProductFormMode = (propName = 'formMode') => WrappedComponent => props => (
  <ProductFormModeContext.Consumer>
    { mode => React.createElement(WrappedComponent, { ...props, [propName]: mode }) }
  </ProductFormModeContext.Consumer>
);

// Expose the current step key.
export const ProductFormCurrentStepContext = createContext(PRODUCT_FORM_STEPS.PRESENTATION);

/**
 * Create a HOC exposing the form mode in the context.
 *
 * @param {string} step - Current step (see PRODUCT_FORM_STEPS values).
 * @returns {Function} HOC.
 */
export const ProductFormCurrentStepContextProviderHoc = step => WrappedComponent => props => (
  <ProductFormCurrentStepContext.Provider value={step}>
    <WrappedComponent {...props} />
  </ProductFormCurrentStepContext.Provider>
);

/**
 * Create a HOC exposing the current product form step from the context.
 *
 * @param {string} propName - Name of the prop of the wrapped component to set the form step to.
 * @returns {Function} HOC.
 */
export const withProductFormCurrentStep = (propName = 'currentStep') => WrappedComponent => props => (
  <ProductFormCurrentStepContext.Consumer>
    {step => React.createElement(WrappedComponent, { ...props, [propName]: step })}
  </ProductFormCurrentStepContext.Consumer>
);

/**
 * Create a HOC exposing the current product form mode from the context.
 *
 * @param {string|Function} step - Check if this step is the current step.
 * @param {string} propName - Name of the prop of the wrapped component to set the boolean to.
 * @returns {Function} HOC.
 */
export const withProductFormIsCurrentStep = (step, propName = 'isCurrentStep') => WrappedComponent => props => (
  <ProductFormCurrentStepContext.Consumer>
    {currentStep => React.createElement(
      WrappedComponent,
      {
        ...props,
        [propName]: typeof step === 'function' ?
          step(props) === currentStep
          : step === currentStep,
      },
    )}
  </ProductFormCurrentStepContext.Consumer>
);
