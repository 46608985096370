export const LINKS_EXAMPLE = {
  PRODUCT: '"https://tangram.adeo.com"',
  PRODUCT_MARKETING: '"https://sites.google.com/adeo.com/tangram/home"',
  SOURCE: '"https://github.com/adeo/tangram--meta"',
  API_REFERENCE: 'https://developer.adeo.com/"',
  DISCUSSION: '"https://adeo-tech-community.slack.com/messages/CDGQ3681J/"',
  DOCUMENTATION: '"https://github.com/adeo/tangram--meta/wiki"',
  ROADMAP: '"https://jira.adeo.com/projects/"',
  BACKLOG: '"https://jira.adeo.com/"',
  SUPPORT: '"https://adeo.service-now.com/"',
};
