import React, { memo } from 'react';
import { compose } from 'recompose';

import { Field } from 'redux-form';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';

import SubsetsSelector from 'react/business/subsets/selector/SubsetsSelector';

const ConnectedSubsetsSelector = compose(
  reduxFormInputAdapter,

)(SubsetsSelector);

const enhancer = compose(
  memo,
);

const SubsetsField = () => (
  <Field
    name="subsets"
    component={ConnectedSubsetsSelector}
  />
);

SubsetsField.displayName = 'SubsetsField';

export default enhancer(SubsetsField);
