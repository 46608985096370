import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import connect from 'react/hoc/connectProxy';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import omitProps from 'react/hoc/omitProps';
import { getProduct } from 'redux/products/actions';
import { deleteProductSuggestion } from 'redux/suggestions/actions';
import { ACTIONS as SUGGESTIONS_ACTIONS } from 'redux/suggestions/constants';

import Dialog, {
  DialogAction, DialogActions, DialogContent, DialogTitle,
} from 'react/generic/dialog/Dialog';

import { isLoadingSuggestions } from 'redux/suggestions/selectors';

import globalMessages from 'config/global.messages';
import { withRouter } from 'react-router';
import { redirectFromSingleMessage } from 'services/functions/redirectFromSingleMessage';
import messages from './delete-confirmation-dialog.messages';

const enhancer = compose(
  withRouter,
  memo,
  connect(
    state => ({
      isLoading: isLoadingSuggestions(state),
    }),
    (dispatch, props) => ({
      onRequestDelete: async () => {
        const action = await dispatch(deleteProductSuggestion(
          props.productId,
          props.suggestionId,
        ));

        if (action.type === SUGGESTIONS_ACTIONS.DELETE_PRODUCT_SUGGESTION_SUCCESS) {
          redirectFromSingleMessage(props);

          // Fetch the product to see marks refresh.
          await dispatch(getProduct(props.productId));

          return props.onDeleteSuccess?.();
        }

        return props.onDeleteFailure?.();
      },
    }),
  ),

  omitProps(['productId', 'suggestionId', 'onDeleteSuccess', 'onDeleteFailure']),
);

const DeleteConfirmationDialog = ({ onRequestDelete, isLoading, ...props }) => (
  <Dialog {...props}>
    <DialogTitle>
      <FormattedMessage {...messages.SUGGESTION_DELETE_DIALOG_TITLE} />
    </DialogTitle>

    <DialogContent>
      <FormattedMessage {...messages.SUGGESTION_DELETE_DIALOG_CONTENT} />
    </DialogContent>

    <DialogActions>
      <DialogAction
        outline
        onClick={props.onRequestClose}
      >
        <FormattedMessage {...globalMessages.CANCEL} />
      </DialogAction>

      <DialogAction
        onClick={onRequestDelete}
        disabled={isLoading}
      >
        <FormattedMessage {...globalMessages.SUBMIT} />
      </DialogAction>
    </DialogActions>
  </Dialog>
);

DeleteConfirmationDialog.displayName = 'DeleteConfirmationDialog';

DeleteConfirmationDialog.propTypes = {
  onRequestDelete: PropTypes.func,
  onRequestClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

DeleteConfirmationDialog.defaultProps = {
  onRequestDelete: noop,
  onRequestClose: noop,
  isLoading: false,
};

export default enhancer(DeleteConfirmationDialog);
