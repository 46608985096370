import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { compose, pure, withHandlers } from 'recompose';

const { Option } = Select;

const enhancer = compose(
  pure,

  withHandlers({
    onChangeHandler: ({ onSetNumberOfItems }) => value => onSetNumberOfItems(value),
  }),
);

const NumberOfItemsOnPageFilter = ({
  onChangeHandler,
  className,
  currentItemsNoValue,
  messageProps,
  options,
}) => (
  <div className={cn(className)}>
    <Select
      onChange={onChangeHandler}
      value={currentItemsNoValue}
      placeholder={(
        <FormattedMessage
          {...messageProps.message}
          values={{ [messageProps.valuesPlaceholder]: currentItemsNoValue }}
        />
      )}
      showArrow={false}
      showSearch={false}
      dropdownMatchSelectWidth={false}
    >
      { options.map(optionValue => (
        <Option value={optionValue} key={optionValue}>
          <FormattedMessage
            {...messageProps.message}
            values={{ [messageProps.valuesPlaceholder]: optionValue }}
          />
        </Option>
      )) }
    </Select>
  </div>
);
NumberOfItemsOnPageFilter.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  currentItemsNoValue: PropTypes.number.isRequired,
  messageProps: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  className: PropTypes.string,
};

NumberOfItemsOnPageFilter.defaultProps = {
  className: '',
};

export default enhancer(NumberOfItemsOnPageFilter);
