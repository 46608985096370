import React, { createContext } from 'react';

import { FORM_MODES } from './form.constants';
import { COMPONENT_FORM_STEPS } from './steps/component-form-steps.constants';

// Expose the form name.
export const ComponentFormNameContext = createContext('');

/**
 * Create a HOC exposing the form name in the context.
 *
 * @param {string} name - Form name.
 * @returns {Function} HOC.
 */
export const ComponentFormNameContextProviderHoc = name => WrappedComponent => props => (
  <ComponentFormNameContext.Provider value={name}>
    <WrappedComponent {...props} />
  </ComponentFormNameContext.Provider>
);

/**
 * Create a HOC exposing the current Component form name from the context.
 *
 * @param {string} propName - Name of the prop of the wrapped component to set the form name to.
 * @returns {Function} HOC.
 */
export const withComponentFormName = (propName = 'formName') => WrappedComponent => props => (
  <ComponentFormNameContext.Consumer>
    { name => React.createElement(WrappedComponent, { ...props, [propName]: name }) }
  </ComponentFormNameContext.Consumer>
);

// Expose the current mode.
export const ComponentFormModeContext = createContext(FORM_MODES.CREATE);

/**
 * Create a HOC exposing the form mode in the context.
 *
 * @param {string} mode - Form mode (create/update).
 * @returns {Function} HOC.
 */
export const ComponentFormModeContextProviderHoc = mode => WrappedComponent => props => (
  <ComponentFormModeContext.Provider value={mode}>
    <WrappedComponent {...props} />
  </ComponentFormModeContext.Provider>
);

/**
 * Create a HOC exposing the current Component form mode from the context.
 *
 * @param {string} propName - Name of the prop of the wrapped component to set the form mode to.
 * @returns {Function} HOC.
 */
export const withComponentFormMode = (propName = 'formMode') => WrappedComponent => props => (
  <ComponentFormModeContext.Consumer>
    { mode => React.createElement(WrappedComponent, { ...props, [propName]: mode }) }
  </ComponentFormModeContext.Consumer>
);

// Expose the current step key.
export const ComponentFormCurrentStepContext = createContext(COMPONENT_FORM_STEPS.PRESENTATION);

/**
 * Create a HOC exposing the form mode in the context.
 *
 * @param {string} step - Current step (see COMPONENT_FORM_STEPS values).
 * @returns {Function} HOC.
 */
export const ComponentFormCurrentStepContextProviderHoc = step => WrappedComponent => props => (
  <ComponentFormCurrentStepContext.Provider value={step}>
    <WrappedComponent {...props} />
  </ComponentFormCurrentStepContext.Provider>
);

/**
 * Create a HOC exposing the current Component form step from the context.
 *
 * @param {string} propName - Name of the prop of the wrapped component to set the form step to.
 * @returns {Function} HOC.
 */
export const withComponentFormCurrentStep = (propName = 'currentStep') => WrappedComponent => props => (
  <ComponentFormCurrentStepContext.Consumer>
    {step => React.createElement(WrappedComponent, { ...props, [propName]: step })}
  </ComponentFormCurrentStepContext.Consumer>
);

/**
 * Create a HOC exposing the current Component form mode from the context.
 *
 * @param {string|Function} step - Check if this step is the current step.
 * @param {string} propName - Name of the prop of the wrapped component to set the boolean to.
 * @returns {Function} HOC.
 */
export const withComponentFormIsCurrentStep = (step, propName = 'isCurrentStep') => WrappedComponent => props => (
  <ComponentFormCurrentStepContext.Consumer>
    {currentStep => React.createElement(
      WrappedComponent,
      {
        ...props,
        [propName]: typeof step === 'function' ?
          step(props) === currentStep
          : step === currentStep,
      },
    )}
  </ComponentFormCurrentStepContext.Consumer>
);
