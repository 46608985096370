import { defineMessages } from 'react-intl';

export default defineMessages({
  SUPPRESS_WARNINGS_TITLE: {
    id: 'product.form.dialog.title',
    defaultMessage: 'Form not filled in English',
  },
  SUPPRESS_WARNINGS_BODY: {
    id: 'product.form.dialog.body',
    defaultMessage: 'Some fields of your form are not in English. Do you want to validate your form anyway?',
  },
});
