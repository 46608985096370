import {
  uniqueId, cloneDeep, noop, set, isEmpty,
} from 'lodash';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import connect from 'react/hoc/connectProxy';
import {
  compose,
  lifecycle,
  withPropsOnChange,
  withHandlers,
} from 'recompose';
import {
  reduxForm,
  initialize,
  destroy,
  SubmissionError,
  updateSyncWarnings,
  getFormSyncErrors,
  getFormAsyncErrors,
} from 'redux-form';
import swal from 'sweetalert';

import { PRODUCT_FORM_PREFIX } from 'config/constants';
import { fromFormToStore, fromStoreToForm } from 'services/products/product-form.mapper';
import { productShape } from 'shapes/product';
import withDialogState from 'react/generic/dialog/withDialogState';
import DialogWarning from 'react/business/products/form/dialog/DialogWarning';

import withMasterData from 'react/hoc/form/withMasterData';

import { selectCurrentUser } from 'redux/users/selectors';

import { ProductFormNameContextProviderHoc, ProductFormModeContextProviderHoc } from './Context';
import { asyncValidate, asyncChangeFields } from './form-validation';
import { FORM_MODES, INVALID_FIELDS_LEVEL } from './form.constants';
import messages from './form.messages';

export default ({
  formName = uniqueId(PRODUCT_FORM_PREFIX),
  mode = FORM_MODES.CREATE,
} = {}) => {
  const enhancer = compose(
    withMasterData,

    // Expose the form name in the context,
    // so components in the tree can dispatch actions of the current form.
    ProductFormNameContextProviderHoc(formName),

    // Expose the form mode in the context.
    ProductFormModeContextProviderHoc(mode),

    withDialogState('SuppressWarnings'),

    connect(
      state => ({
        syncErrors: getFormSyncErrors(formName)(state),
        asyncErrors: getFormAsyncErrors(formName)(state),
        currentUser: selectCurrentUser(state),
      }),
      {
        updateSyncWarnings,
        destroy,
      },
    ),

    withHandlers({
      // Handle submit.
      onSubmit: ({
        syncErrors,
        asyncErrors,
        onEditProduct,
        onOpenSuppressWarningsDialog,
        updateSyncWarnings: updateSyncWarningsAction,
      }) => async (formValues, dispatch, props, suppressWarnings = false) => {
        // If there are any errors, cancel submission.
        if (!isEmpty(syncErrors) || !isEmpty(asyncErrors)) {
          throw Error();
        }

        // at this point only edit will be called
        // because the auto-save saga will call create method
        // at the beginning of the product creation
        const { success, product, invalidFields } = await onEditProduct(
          {
            ...fromFormToStore(formValues),
            // Publish.
            isDraft: false,
          },
          formName,
          formValues.id,
          suppressWarnings,
        );

        if (success) {
          // Re-init the form with the last saved values.
          // Used to check if the form was edited to prevent user navigation.
          await dispatch(initialize(
            formName,
            fromStoreToForm({
              ...product,
              community: formValues.community ? {
                id: formValues.community.value,
                name: formValues.community.label,
              } : null,
            }),
            {
              keepDirty: false,
              updateUnregisteredFields: true,
            },
          ));

          return product;
        }

        const invalidFieldsWarnings = invalidFields.filter(
          field => field.level === INVALID_FIELDS_LEVEL.WARNING,
        );

        const warnings = invalidFieldsWarnings.reduce((acc, cur) => {
          set(acc, cur.path, <FormattedMessage {...messages[cur.code]} />);
          return acc;
        }, {});

        if (invalidFieldsWarnings.length > 0) {
          await updateSyncWarningsAction(formName, warnings);

          await onOpenSuppressWarningsDialog();

          throw Error('The submit failed because of warnings');
        }

        throw new SubmissionError();
      },
    }),

    withPropsOnChange(
      // Only do this on mount.
      ['initialValues'],
      ({ initialValues }) => ({
        // Reformat the initial values before passing them to redux-form (only on mount).
        initialValues: fromStoreToForm(cloneDeep(initialValues) || {}),
      }),
    ),

    // Create a new redux-form form.
    reduxForm({
      form: formName,
      asyncValidate,
      asyncChangeFields,
      // Keep registered fields inside the form when they are unmounted.
      destroyOnUnmount: false,
    }),

    withHandlers({
      handleSubmitSuppressWarnings: ({
        handleSubmit,
        onSubmit,
        onCloseSuppressWarningsDialog,
      }) => handleSubmit((formValues, dispatch, props) => {
        onCloseSuppressWarningsDialog();

        return onSubmit(formValues, dispatch, props, true);
      }),

      safeHandleSubmit: ({ handleSubmit }) => async (...args) => {
        try {
          return await handleSubmit(...args);
        } catch (err) {
          // handleSubmit throws on validation errors so just ignore the error.
          // (We display a message when the submission fails).
          return null;
        }
      },
    }),

    lifecycle({
      /** Display a remainder to fill the form in english (this is temporary). */
      componentDidMount() {
        if (mode === FORM_MODES.CREATE) {
          swal('Info', 'Please fill the form in English.', 'info');
        }
      },

      /** Destroy the form on unmount. */
      componentWillUnmount() {
        this.props.destroy(formName);
      },
    }),

    memo,
  );

  const ProductForm = ({
    safeHandleSubmit,
    className,
    children,
    isSuppressWarningsDialogOpen,
    onCloseSuppressWarningsDialog,
    handleSubmitSuppressWarnings,
  }) => (
    <>
      <form onSubmit={safeHandleSubmit} className={className}>
        {children}
      </form>

      <DialogWarning
        isModalOpen={isSuppressWarningsDialogOpen}
        onCloseModal={onCloseSuppressWarningsDialog}
        onRequest={handleSubmitSuppressWarnings}
      />
    </>
  );

  ProductForm.displayName = 'ProductForm';

  ProductForm.propTypes = {
    /** Redux-form's handleSubmit which does not throw. */
    safeHandleSubmit: PropTypes.func.isRequired,
    /** Form content. */
    children: PropTypes.node,
    /** Classname. */
    className: PropTypes.string,
    isSuppressWarningsDialogOpen: PropTypes.bool,
    onCloseSuppressWarningsDialog: PropTypes.func,
    handleSubmitSuppressWarnings: PropTypes.func,
  };

  ProductForm.defaultProps = {
    children: null,
    className: null,
    isSuppressWarningsDialogOpen: null,
    onCloseSuppressWarningsDialog: noop,
    handleSubmitSuppressWarnings: noop,
  };

  const EnhancedProductForm = enhancer(ProductForm);

  EnhancedProductForm.propTypes = {
    /** Called by redux-form after validating the submitted form. */
    onSubmit: PropTypes.func,
    /** Form content. */
    children: PropTypes.node,
    /** Initial values used to initialize the form, in product format. */
    initialValues: productShape,
  };

  EnhancedProductForm.defaultProps = {
    onSubmit: noop,
    children: null,
    initialValues: null,
  };

  return EnhancedProductForm;
};
