import React, { memo } from 'react';
import { compose } from 'recompose';

import globalMessages from 'config/global.messages';
import { required } from 'react/generic/form/validators';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { ReduxFormRadio } from 'react/generic/form/radio/Radio';
import PropTypes from 'prop-types';
import messages from './isMaintainedByPlatform.messages';

const CHECKBOX_ISMAINTAINEDBYPLATFORM_OPTIONS = [
  { value: true, label: <FormattedMessage {...globalMessages.YES} /> },
  { value: false, label: <FormattedMessage {...globalMessages.NO} /> },
];

const rules = [required];

const enhancer = compose(
  memo,
);

const IsMaintainedByPlatformField = ({ disabled }) => (
  <Field
    name="isMaintainedByPlatform"
    component={(props) => (
      <ReduxFormRadio
        {...props}
        hasUnlocker
        unlockerTooltip={<FormattedMessage {...messages.UNLOCKER_TOOLTIP} />}
        unlockerTooltipLock={<FormattedMessage {...messages.UNLOCKER_TOOLTIP_LOCK} />}
      />
    )}
    options={CHECKBOX_ISMAINTAINEDBYPLATFORM_OPTIONS}
    title={<FormattedMessage {...messages.IS_MAINTAINED_BY_PLATFORM_TITLE} />}
    tooltip={<FormattedMessage {...messages.IS_MAINTAINED_BY_PLATFORM_HINT} />}
    validate={rules}
    disabled={disabled}
  />
);

IsMaintainedByPlatformField.displayName = 'IsMaintainedByPlatformField';

IsMaintainedByPlatformField.propTypes = {
  disabled: PropTypes.bool,
};

IsMaintainedByPlatformField.defaultProps = {
  disabled: false,
};

export default enhancer(IsMaintainedByPlatformField);
