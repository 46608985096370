import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import reduxFormInputAdapter from '../reduxFormInputAdapter';
import withOnChangeFromEvent from '../withOnChangeFromEvent';
import withDebouncedOnChange from '../withDebouncedOnChange';

import Field from '../field/Field';

import classNames from './input.module.scss';

export default class TextArea extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    tooltip: PropTypes.node,
    error: PropTypes.node,
    warning: PropTypes.node,
    value: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    title: null,
    tooltip: null,
    error: null,
    warning: null,
    value: '',
  };

  /** @returns {object} JSX. */
  render() {
    const {
      title,
      tooltip,
      error,
      warning,
      className,
      value,
      ...props
    } = this.props;

    return (
      <Field
        title={title}
        tooltip={tooltip}
        error={error}
        warning={warning}
      >
        <div className={classNames.container}>
          <textarea
            {...props}
            value={value}
            className={cn(
              className,
              classNames.textarea,
              error && classNames.hasError,
            )}
          />
          {
            // I cannot use .textarea[maxLength]::after
            // { content: attr(data-length) "/" attr(maxLength); }
            // Because we cannot add ::before or ::after on tags that cannot contain anything
            // such as... input, text-area, etc...
            // That's why I am gonna use a span :
            props.maxLength && (
              <span className={classNames.characterCount}>
                {`${value?.length || 0} / ${props.maxLength}`}
              </span>
            )
          }
        </div>
      </Field>
    );
  }
}

export const DebouncedTextArea = compose(
  withDebouncedOnChange(),
  withOnChangeFromEvent(),
)(TextArea);

export const ReduxFormTextArea = reduxFormInputAdapter(TextArea);

export const DebouncedReduxFormTextArea = compose(
  reduxFormInputAdapter,
)(DebouncedTextArea);
