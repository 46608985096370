import { defineMessages } from 'react-intl';

export default defineMessages({
  COMMUNITY_TITLE: { id: 'product.form.fields.community.title', defaultMessage: 'Platform *' },

  COMMUNITY_HINT: {
    id: 'product.form.fields.community.hint',
    defaultMessage: 'Select the platform to which your digital product is related',
  },
});
