import connect from 'react/hoc/connectProxy';
import { getFormValues, change as changeAction } from 'redux-form';
import {
  compose,
  lifecycle,
  withPropsOnChange,
  withProps,
} from 'recompose';

import { injectIntl } from 'react-intl';
import omitProps from 'react/hoc/omitProps';
import { selectCommunityDomains } from 'redux/communities/selectors';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import findLabelInOptions from 'react/generic/form/autocomplete/findLabelInOptions';
import { withProductFormName } from 'react/business/products/form/Context';

import TooltipOption from 'react/generic/form/select/option/TooltipOption';
import { ENUM_PLACEMENTS } from 'react/generic/form/select/option/tooltipOption.constants';

import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';

import messages from './domain.messages';

const mapDomains = d => ({
  value: d.id,
  label: d.name,
  description: d.description,
});

const EnhancedOption = compose(
  withProps({
    placement: ENUM_PLACEMENTS.LEFT,
  }),
)(TooltipOption);

export default compose(
  injectIntl,

  withProductFormName(),

  connect(
    (state, { formName }) => {
      const selectedCommunityId = getFormValues(formName)(state).community?.value;
      return {
        selectedCommunityId,
        domains: selectCommunityDomains(state, selectedCommunityId).filter(el => !el.isInactive),
      };
    },
    {
      change: changeAction,
    },
  ),

  withProps(({ intl, selectedCommunityId, disabled }) => ({
    isDisabled: !selectedCommunityId || disabled,
    placeholder: !selectedCommunityId ?
      intl.formatMessage(messages.SELECT_COMMUNITY)
      : undefined,
  })),

  omitProps(['intl']),

  withPropsOnChange(
    ['domains'],
    ({ domains }) => ({ options: domains?.map(mapDomains) }),
  ),

  withProps({
    components: {
      Option: EnhancedOption,
    },
  }),

  lifecycle({
    /**
     * Reset domain when community change.
     *
     * @param {object} prevProps - Previous props.
     */
    componentDidUpdate(prevProps) {
      if (prevProps.selectedCommunityId !== this.props.selectedCommunityId) {
        // Reset selected domain.
        this.props.change(this.props.formName, 'domain', null);
        // Reset selected ABBs list.
        this.props.change(this.props.formName, 'architectureBuildingBlocks', []);
      }
    },
  }),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,
  findLabelInOptions,
)(Autocomplete);
