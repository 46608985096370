import React from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { Checkbox, Tooltip } from 'antd';
import cn from 'classnames';

import { activityShape, atomicActivityShape, macroActivityShape } from 'shapes/businessActivities';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import classNames from '../business-activities-selector.module.scss';

const enhancer = compose(
  // Wrap the onClickElement event to put element in callback.
  withPropsOnChange(
    ['element', 'onClickElement', 'onToggleElement'],
    ({ element, onClickElement, onToggleElement }) => ({
      selectable: !!onClickElement,
      onClickElement: () => onClickElement?.(element),
      onToggleElement: event => onToggleElement(element.id, event.target.checked),
    }),
  ),
);

const BusinessActivitiesSelectorListElement = ({
  checked,
  element,
  onClickElement,
  active,
  onToggleElement,
  selectable,
  counter,
}) => (
  <button
    onClick={onClickElement}
    type="button"
    className={cn(
      classNames.listElement,
      selectable && classNames.selectable,
      active && classNames.active,
    )}
  >
    <p className={classNames.elementTitleContainer}>
      {element.name}
      {!element.description && counter !== 0 && (
        <span className={classNames.counter}>{counter} </span>
      )}
      {element.description && (
        <Tooltip placement="top" title={element.description}>
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      )}
    </p>
    {selectable && <span className={classNames.chevron}><Asset name={ICON_MAP.chevron} /></span>}
    {checked !== undefined && <Checkbox checked={checked} onChange={onToggleElement} />}
  </button>
);

BusinessActivitiesSelectorListElement.propTypes = {
  element: PropTypes.oneOfType([
    macroActivityShape,
    activityShape,
    atomicActivityShape,
  ]).isRequired,
  checked: PropTypes.bool,
  onToggleElement: PropTypes.func.isRequired,
  onClickElement: PropTypes.func,
  active: PropTypes.bool,
  selectable: PropTypes.bool.isRequired,
  counter: PropTypes.number,
};

BusinessActivitiesSelectorListElement.defaultProps = {
  checked: undefined,
  onClickElement: null,
  active: false,
  counter: 0,
};

export default enhancer(BusinessActivitiesSelectorListElement);
