import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { withProductFormName } from 'react/business/products/form/Context';
import { selectProductFormValuesAsStoreProduct } from 'redux/products/selectors';

export default compose(
  withProductFormName(),

  connect(
    (state, props) => ({
      product: selectProductFormValuesAsStoreProduct(state, props.formName),
    }),
  ),
);
