import React from 'react';
import { branch, renderComponent } from 'recompose';

import ErrorPage from 'react/views/special/ErrorPage';

/**
 * @callback CanAccess
 * @param {object} props - Props.
 * @returns {boolean} True if user has permissions to access the page.
 */

/**
 * @param {CanAccess} canAccess - Does user have permission.
 * @param {object} options - Options.
 * @param {string|object} options.errorMessage - Custom error message.
 * @returns {Function} HOC rendering the Error page if canAccess returns false.
 */
export default (
  canAccess,
  {
    errorMessage,
  } = {},
) => branch(
  props => !canAccess(props),
  renderComponent(() => <ErrorPage code="403" errorMessage={errorMessage} />),
);
