import * as LocalOrganizationService from 'services/localOrganizations';
import { ACTIONS } from './constants';

export const GET_LOCAL_ORGANIZATION_CHILDREN = id => ({
  type: ACTIONS.GET_LOCAL_ORGANIZATION_CHILDREN,
  id,
});

export const GET_LOCAL_ORGANIZATION_CHILDREN_SUCCESS = (id, localDomains) => ({
  type: ACTIONS.GET_LOCAL_ORGANIZATION_CHILDREN_SUCCESS,
  id,
  localDomains,
});

export const GET_LOCAL_ORGANIZATION_CHILDREN_FAILURE = (id, error) => ({
  type: ACTIONS.GET_LOCAL_ORGANIZATION_CHILDREN_FAILURE,
  id,
  error,
});

export const getLocalOrganizationChildren = id => async (dispatch) => {
  dispatch(GET_LOCAL_ORGANIZATION_CHILDREN(id));

  try {
    const localDomains = await LocalOrganizationService.getLocalOrganizationChildren(
      id,
    );
    return dispatch(GET_LOCAL_ORGANIZATION_CHILDREN_SUCCESS(id, localDomains));
  } catch (err) {
    return dispatch(GET_LOCAL_ORGANIZATION_CHILDREN_FAILURE(id, err));
  }
};

export const GET_ALL_LOCAL_ORGANIZATION = () => ({
  type: ACTIONS.GET_ALL_LOCAL_ORGANIZATION,
});

export const GET_ALL_LOCAL_ORGANIZATION_SUCCESS = localOrgs => ({
  type: ACTIONS.GET_ALL_LOCAL_ORGANIZATION_SUCCESS,
  localOrgs,
});

export const GET_ALL_LOCAL_ORGANIZATION_FAILURE = error => ({
  type: ACTIONS.GET_ALL_LOCAL_ORGANIZATION_FAILURE,
  error,
});

export const getAllLocalOrganizations = () => async (dispatch) => {
  dispatch(GET_ALL_LOCAL_ORGANIZATION());

  try {
    const localOrgs = await LocalOrganizationService.getAllLocalOrganizationsAndDomains();
    return dispatch(GET_ALL_LOCAL_ORGANIZATION_SUCCESS(localOrgs));
  } catch (err) {
    return dispatch(GET_ALL_LOCAL_ORGANIZATION_FAILURE(err));
  }
};
