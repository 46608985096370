import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import ProductFormFeaturesForm from 'react/business/products/form/steps/step-2/FeaturesForm';
import ProductFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      PRODUCT_FORM_STEPS.PRESENTATION,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.LINKS),
  }),

  memo,
);

const ProductFormFeaturesStep = ({ onClickPreviousStep, onOpenPreview, onClickNextStep }) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.FEATURES}
    form={<ProductFormFeaturesForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormFeaturesStep.displayName = 'ProductFormFeaturesStep';

ProductFormFeaturesStep.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
  onClickNextStep: PropTypes.func,
};

ProductFormFeaturesStep.defaultProps = {
  onClickPreviousStep: noop,
  onOpenPreview: noop,
  onClickNextStep: noop,
};

export default enhancer(ProductFormFeaturesStep);
