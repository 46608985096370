import { Manifest } from 'services/products/products-form';

import {
  screenshots,
  tagsMisc,
} from 'react/business/products/form/fields';

export default new Manifest({
  fields: {
    screenshots,
    tagsMisc,
  },
});
