import React, { memo } from 'react';
import { compose } from 'recompose';

import { MAX_PRODUCT_NAME_LENGTH } from 'config/constants';
import { uppercase } from 'react/generic/form/reduxFormFormatter';
import { required, maxLength } from 'react/generic/form/validators';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { DebouncedReduxFormInput } from 'react/generic/form/input/Input';

import classNames from '../fields.module.scss';
import messages from './name.messages';

const rules = [
  required,
  maxLength(MAX_PRODUCT_NAME_LENGTH),
];

const enhancer = compose(
  memo,
);

const NameField = ({ isComponent }) => (
  <Field
    type="text"
    name="name"
    title={(
      <FormattedMessage
        {...(isComponent ? messages.COMPONENT_NAME_TITLE : messages.NAME_TITLE)}
      />
    )}
    tooltip={(
      <FormattedMessage
        {...(isComponent ? messages.COMPONENT_NAME_HINT : messages.NAME_HINT)}
      />
    )}
    component={DebouncedReduxFormInput}
    validate={rules}
    maxLength={MAX_PRODUCT_NAME_LENGTH}
    className={classNames.uppercaseField}
    normalize={uppercase}
  />
);

NameField.displayName = 'NameField';

export default enhancer(NameField);
