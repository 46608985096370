import { defineMessages } from 'react-intl';

export default defineMessages({
  GLOBAL_PRODUCT_TITLE: { id: 'product.form.fields.isGlobal.title', defaultMessage: 'Is a global product ? *' },

  GLOBAL_HINT: {
    id: 'product.form.fields.isGlobal.hint',
    defaultMessage: 'A global digital product is a digital product mandatory in any BU of the group. If you don\'t know, fill in "No"',
  },
});
