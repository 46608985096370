import React, { memo } from 'react';
import { compose } from 'recompose';

import { MAX_FEATURE_LENGTH } from 'config/constants';
import { maxLength } from 'react/generic/form/validators';

import { FieldArray } from 'redux-form';
import FeatureForm from 'react/business/features/form/FeaturesForm';

const rules = [
  maxLength(MAX_FEATURE_LENGTH),
];

const featureFieldProps = {
  validate: rules,
  maxLength: MAX_FEATURE_LENGTH,
};

const enhancer = compose(
  memo,
);

const MainFeaturesField = () => (
  <FieldArray
    name="mainFeatures"
    component={FeatureForm}
    fieldProps={featureFieldProps}
  />
);

MainFeaturesField.displayName = 'MainFeaturesField';

export default enhancer(MainFeaturesField);
