import { compose, withProps } from 'recompose';
import { selectUserPreferences } from 'redux/users/selectors';
import { setCurrentUserPreferences } from 'redux/users/actions';
import connect from 'react/hoc/connectProxy';
import { ACTIONS } from 'redux/users/constants';

export default compose(
  connect(
    state => ({
      userPreferences: selectUserPreferences(state),
    }),
    {
      setCurrentUserPreferences,
    },
  ),

  withProps(props => ({
    onSetNumberOfReviewsOnPage: async (value) => {
      const success
        = await props.setCurrentUserPreferences({
          ...props.userPreferences,
          reviewsOnPage: value,
        });

      if (success.type === ACTIONS.SET_CURRENT_USER_PREFERENCES_SUCCESS) {
        await props.getProductReviews(props.product.id, { ...props.filters, preserve: false });
      }
    },

    onSetNumberOfSuggestionsOnPage: async (value) => {
      const success
        = await props.setCurrentUserPreferences({
          ...props.userPreferences,
          suggestionsOnPage: value,
        });

      if (success.type === ACTIONS.SET_CURRENT_USER_PREFERENCES_SUCCESS) {
        await props.getProductSuggestions(props.product.id, { ...props.filters, preserve: false });
      }
    },
  })),
);
