import { compose, withPropsOnChange } from 'recompose';

import { getTagsStartingWith, createTag } from 'services/products';

import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

const mapTag = tag => ({
  value: tag.id,
  label: tag.label,
});

const searchTags = async (tagType, query) => {
  if (!query) {
    return [];
  }

  const results = await getTagsStartingWith(tagType, query);

  return results.map(mapTag);
};

export default compose(
  withPropsOnChange(
    ['tagType'],
    ({ tagType }) => ({
      isMulti: true,
      loadOptions: query => searchTags(tagType, query),
      onCreateOption: async (newLabel) => {
        const { id, label } = await createTag(tagType, newLabel);
        // Put the data in the react-select format before sending it back.
        return { value: id, label };
      },
    }),
  ),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,
)(Autocomplete);
