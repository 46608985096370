import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes } from 'redux-form';

import { ITEM_TYPES } from 'react/generic/form/ListField/constants';
import makeListField from 'react/generic/form/ListField/ListField';
import AddMoreButton from 'react/generic/form/ListField/add-more/AddMoreButton';

import { moduleFactory } from 'services/products/products-form';

import messages from './modules-form.messages';
import classNames from './modules-form.module.scss';
import ModulesFormItem from './modulesFormItem/ModulesFormItem';

const { shape } = PropTypes;
const ListField = makeListField(ITEM_TYPES.MODULES);

const ModuleFormPresentation = ({ modules, fieldProps }) => {
  const onAdd = useCallback(
    () => {
      modules.push(moduleFactory());
    },
    [modules],
  );

  return (
    <>
      <ListField
        fields={modules}
        fieldProps={fieldProps}
        hideDeleteButton
      >
        <ModulesFormItem />
      </ListField>
      <div className={classNames.buttonContainer}>
        <AddMoreButton onClick={onAdd} message={messages.ADD_NEW_MODULE} />
      </div>
    </>
  );
};

ModuleFormPresentation.displayName = 'ModuleFormPresentation';
ModuleFormPresentation.propTypes = {
  modules: shape(fieldArrayFieldsPropTypes).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldProps: PropTypes.object,
};

ModuleFormPresentation.defaultProps = {
  fieldProps: {},
};

export default ModuleFormPresentation;
