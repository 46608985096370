import { defineMessages } from 'react-intl';

export default defineMessages({
  LEAVE_TITLE: {
    id: 'product.form.dialog.leave.title',
    defaultMessage: 'Draft not saved',
  },
  LEAVE_BODY: {
    id: 'product.form.dialog.leave.body',
    defaultMessage: 'Your digital product can\'t be saved, are you sure you want to leave ?',
  },
});
