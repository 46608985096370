import { compose } from 'recompose';
import React, { memo } from 'react';
import { formatRoute } from 'react-router-named-routes';
import { componentShape } from '../../../../../../shapes/component';
import Card from '../../../../../generic/card/Card';
import classNames from './componentCard.module.scss';
import ProductStage from '../../summary/stage/ProductStage';
import ROUTES from '../../../../../routes';
import { PRODUCT_VIEW } from '../../../../../../config/constants';
import Link from '../../../../../generic/link/Link';

const enhancer = compose(
  memo,
);

const ComponentCard = ({
  component,
}) => (
  <Card className={classNames.cardWrap}>
    <div className={classNames.titleWrap}>
      <Link
        to={formatRoute(ROUTES.COMPONENT.PRESENTATION, {
          componentId: component._id,
          view: PRODUCT_VIEW.OVERVIEW,
        })}
      >
        <div className={classNames.title}>
          {component.title}
        </div>
      </Link>
    </div>
    <div className={classNames.description}>
      {component.description}
    </div>

    <div className={classNames.status}>
      <ProductStage stage={component.status} />
    </div>

  </Card>
);

ComponentCard.propTypes = {
  component: componentShape.isRequired,
};

export default enhancer(ComponentCard);
