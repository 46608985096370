import { compose, withProps } from 'recompose';

import { getRolesStartingWith } from 'services/products';

import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

const mapRole = tag => ({
  value: tag.id,
  label: tag.label,
  displayOnTop: tag.displayOnTop,
  isBuSuitable: tag.isBuSuitable,
});

const searchRoles = async (query) => {
  const { roles } = await getRolesStartingWith(query);

  return roles.map(mapRole);
};

export default compose(
  withProps(
    () => ({
      loadOptions: searchRoles,
    }),
  ),
  reduxFormInputAdapter,
  withReduxFormDefaultValue,
)(Autocomplete);
