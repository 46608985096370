import React, { memo } from 'react';
import { compose } from 'recompose';

import { FieldArray } from 'redux-form';

import { ManagersRequired } from 'react/generic/form/validators';
import ContactForm from '../../../../team/contact/form/ContactForm';

const enhancer = compose(
  memo,
);
const rules = [ManagersRequired];

const ContactField = () => (
  <FieldArray
    name="contactTeamMembers"
    component={ContactForm}
    validate={rules}
  />
);

ContactField.displayName = 'ContactField';

export default enhancer(ContactField);
