import connect from 'react/hoc/connectProxy';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import withBusinessUnitLazyLoading from 'react/business/businessUnits/lazyLoad/withBusinessUnitLazyLoading';
import { selectBusinessUnitsListByIds } from 'redux/businessUnits/selectors';
import withOnClickBusinessUnit from 'react/business/businessUnits/card/withOnClickBusinessUnit';

export const withBusinessUnitsLazyLoad = withBusinessUnitLazyLoading({
  getIdsToLoadFromProps: ({ businessUnitsIds }) => businessUnitsIds.filter(Boolean),
});

export const withBusinessUnitsInfo = connect(
  (state, props) => ({
    businessUnits: selectBusinessUnitsListByIds(
      state,
      props.businessUnitsIds,
    ),
  }),
);

export default compose(
  withRouter,
  withBusinessUnitsInfo,
  withBusinessUnitsLazyLoad,
  withOnClickBusinessUnit,
);
