import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import { replyShape } from 'shapes/product';

import classNames from './replyCardList.module.scss';

const enhancer = compose(
  memo,
);

const ReplyCardList = ({
  productId,
  replies,
  renderReply,
  compact,
  className,
  ...props
}) => (
  <ul
    {...props}
    className={cn(
      className,
      classNames.list,
      compact && classNames.compact,
    )}
  >
    { replies.map(reply => renderReply({ reply, compact, productId })) }
  </ul>
);

ReplyCardList.displayName = 'ReplyCardList';

ReplyCardList.propTypes = {
  productId: PropTypes.string,
  replies: PropTypes.arrayOf(replyShape),
  compact: PropTypes.bool,
  renderReply: PropTypes.func,
  className: PropTypes.string,
};

ReplyCardList.defaultProps = {
  productId: null,
  replies: [],
  compact: false,
  renderReply: noop,
  className: null,
};

export default enhancer(ReplyCardList);
