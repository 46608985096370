import { noop } from 'lodash';
import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { messageShape } from 'shapes/vendor';

import classNames from './tabs.module.scss';

import TabElement from './Tab';

export const tabShape = PropTypes.shape({
  id: PropTypes.string,
  label: messageShape,
});

export const Tab = TabElement;

const enhancer = compose(
  memo,
);

const Tabs = ({
  tabs,
  active,
  noWrap,
  className,
  renderTab,
  onClick,
}) => (
  <div
    className={cn(
      className,
      classNames.container,
      noWrap && classNames.noWrap,
    )}
  >
    {tabs.map(tab => renderTab(
      tab,
      active === tab.id,
      onClick,
      classNames.tabElement,
    ))}
  </div>
);

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(tabShape).isRequired,
  active: PropTypes.string,
  noWrap: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  renderTab: PropTypes.func,
};

Tabs.defaultProps = {
  active: null,
  noWrap: false,
  className: null,
  onClick: noop,
  renderTab: (tab, isActive, onClick, className) => (
    <Tab
      {...tab}
      key={tab.id}
      isActive={isActive}
      onClick={onClick}
      className={className}
    />
  ),
};

export default enhancer(Tabs);
