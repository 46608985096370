import React, { memo } from 'react';
import { compose } from 'recompose';
import { MAX_PURPOSE_LENGTH } from 'config/constants';

import connect from 'react/hoc/connectProxy';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { DebouncedReduxFormTextArea } from 'react/generic/form/input/TextArea';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import { selectDetailedProductById } from 'redux/products/selectors';
import { validationStatuses } from 'redux/products/constants';
import PropTypes from 'prop-types';
import { requiredPurpose } from 'react/generic/form/validators';
import messages from './purpose.messages';
import { withProductFormMode } from '../../Context';

const rules = [requiredPurpose];

const enhancer = compose(
  memo,
  withProductFormMode(),
  connect(
    (state, props) => ({
      validation: selectDetailedProductById(state, props.product.id)?.validation,
    }),
  ),
);

const isDisableDependingOnValidation = (status) => {
  const enablingStatuses = [
    validationStatuses.RBDL,
    validationStatuses.RBUA,
    validationStatuses.RBBUA,
    validationStatuses.RBCA,
  ];

  if (!status || enablingStatuses.includes(status)) return false;

  return true;
};

const Purpose = ({ formMode, validation }) => (
  <FormattedMessageChildren {...messages.PURPOSE_PLACEHOLDER}>
    {placeholder => (
      <Field
        type="text"
        name="purpose"
        title={<FormattedMessage {...messages.FIELD_TITLE} />}
        component={DebouncedReduxFormTextArea}
        placeholder={placeholder}
        rows={3}
        maxLength={MAX_PURPOSE_LENGTH}
        validate={rules}
        disabled={isDisableDependingOnValidation(validation?.status) && formMode === 'update'}
      />
    )}
  </FormattedMessageChildren>
);

Purpose.propTypes = {
  disabled: PropTypes.bool,
};

Purpose.defaultProps = {
  disabled: false,
};

Purpose.displayName = 'Purpose';

export default enhancer(Purpose);
