import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes } from 'redux-form';

import { ITEM_TYPES } from 'react/generic/form/ListField/constants';
import makeListField from 'react/generic/form/ListField/ListField';
import AddMoreButton from 'react/generic/form/ListField/add-more/AddMoreButton';

import { featureFactory } from 'services/products/products-form';

import FeaturesFormItem from './featuresFormItem/FeaturesFormItem';
import messages from './features-form.messages';
import classNames from './features-form.module.scss';

const { shape } = PropTypes;
const ListField = makeListField(ITEM_TYPES.FEATURES);

const FeatureFormPresentation = ({ features, fieldProps }) => {
  const onAdd = useCallback(
    () => {
      features.push(featureFactory());
    },
    [features],
  );

  return (
    <>
      <ListField
        fields={features}
        fieldProps={fieldProps}
      >
        <FeaturesFormItem />
      </ListField>
      <div className={classNames.buttonContainer}>
        <AddMoreButton onClick={onAdd} message={messages.ADD_NEW_FEATURE} />
      </div>
    </>
  );
};

FeatureFormPresentation.displayName = 'FeatureFormPresentation';
FeatureFormPresentation.propTypes = {
  features: shape(fieldArrayFieldsPropTypes).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldProps: PropTypes.object,
};

FeatureFormPresentation.defaultProps = {
  fieldProps: {},
};

export default FeatureFormPresentation;
