import { defineMessages } from 'react-intl';

export default defineMessages({
  SHORT_DESCRIPTION_TITLE: { id: 'product.form.fields.shortDescription.title', defaultMessage: 'Short description *' },

  SHORT_DESCRIPTION_PLACEHOLDER: {
    id: 'product.form.fields.shortDescription.placeholder',
    defaultMessage: 'The introduction will be present on the label. Maximum 255 characters.',
  },

  SHORT_DESC_HINT: {
    id: 'product.form.fields.shortDescription.hint',
    defaultMessage: '« Pitch » your digital product in one or two short sentences in english. Max length: {maxLength} characters.',
  },
});
