import React, { memo } from 'react';
import { compose } from 'recompose';

import { Field } from 'redux-form';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';

import ArchitectureBuildingBlocksSelector from 'react/business/architectureBuildingBlocks/selector/ArchitectureBuildingBlocksSelector';

const ConnectedArchitectureBuildingBlocksSelector = compose(
  reduxFormInputAdapter,

)(ArchitectureBuildingBlocksSelector);

const enhancer = compose(
  memo,
);

const ArchitectureBuildingBlocksField = () => (
  <Field
    name="architectureBuildingBlocks"
    component={ConnectedArchitectureBuildingBlocksSelector}
  />
);

ArchitectureBuildingBlocksField.displayName = 'ArchitectureBuildingBlocksField';

export default enhancer(ArchitectureBuildingBlocksField);
