import React, { memo } from 'react';
import { compose } from 'recompose';

import { PRODUCT_VERSION_STATUS_MAP } from 'config/constants';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import SafeHtml from 'react/generic/html/SafeHtml';
import { moduleShape } from 'shapes/module';

import classNames from './moduleListItem.module.scss';

const enhancer = compose(
  memo,
);

const ModuleListItem = ({ module }) => (
  <li className={classNames.item}>
    <div className={classNames.title}>
      <Asset
        name={ICON_MAP.check_icon}
        className={classNames.icon}
        aria-hidden
      />
      <div>
        {`${module.title} `}
        <span className={classNames.status}>({PRODUCT_VERSION_STATUS_MAP[module.status]})</span>
      </div>
    </div>
    <div className={classNames.description}>
      <SafeHtml
        unsafeHtml={module.description}
      />
    </div>
  </li>
);

ModuleListItem.displayName = 'ModuleListItem';

ModuleListItem.propTypes = {
  module: moduleShape.isRequired,
};

export default enhancer(ModuleListItem);
