import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { moduleShape } from 'shapes/module';

import { PRODUCT_STAGES } from 'config/constants';
import ModuleListItem from './list/ModuleListItem';

const enhancer = compose(
  memo,
);

const ProductModules = ({ modules }) => (
  <ul>
    {modules.map(module => (
      module.status !== PRODUCT_STAGES.ARCH ? <ModuleListItem module={module} key={module.id} /> : ''
    ))}
  </ul>
);

ProductModules.displayName = 'ProductModules';

ProductModules.propTypes = {
  modules: PropTypes.arrayOf(moduleShape),
};

ProductModules.defaultProps = {
  modules: [],
};

export default enhancer(ProductModules);
