import React from 'react';
import { FormattedMessage } from 'react-intl';

import { validationRejectedReasons } from 'redux/products/constants';
import _ from 'lodash';
import messages from './index.messages';

export const required = value => (
  _.isEmpty(value) ?
    <FormattedMessage {...messages.REQUIRED} />
    : undefined
);

export const requiredDraftJs = editorState => (
  !editorState?.getCurrentContent()?.hasText() ?
    <FormattedMessage {...messages.REQUIRED} />
    : undefined
);

export const maxLength = length => value => (
  value && value.length > length ?
    <FormattedMessage {...messages.MAX_LENGTH} values={{ maxLength: length }} />
    : undefined
);

export const isExternalUrl = value => (
  (value && !/^(http|https):\/\/(.+)\.(.+)/.test(value)) ?
    <FormattedMessage {...messages.EXTERNAL_URL} />
    : undefined
);

export const ManagersRequired = (value) => {
  // TODO: move magic ids to constants
  const hasBusinessProductManager
    = value && !!value.find(member => member && member.user && member.role?.value === '62e9130f-36c7-4173-85ff-ddb9dfadca98');
  const hasDigitalProductManager
    = value && !!value.find(member => member && member.user && member.role?.value === 'b668dcf8-dec0-47d6-b8a8-306a21c2c1f3');

  return !hasBusinessProductManager || !hasDigitalProductManager ?
    <FormattedMessage {...messages.MANAGERS_REQUIRED} />
    : undefined;
};

export const DPLRequired = (value, product) => {
  // If product has Managers, don't check for DPL
  if (!ManagersRequired(value)) {
    return undefined;
  }
  const doesntHaveDPL = value && !value.find(member => member && member.user && member.role?.value === '03f0db29-c2f0-433c-853c-6affb8553b95');
  const isArchived = (product.stage === 'ARCH');
  return (
    !isArchived && doesntHaveDPL ?
      <FormattedMessage {...messages.DCL_REQUIRED} />
      : undefined
  );
};

export const requiredRejectedReasonDescription = (value, product) => (
  ((product?.validation?.rejectedReason
    && product?.validation?.rejectedReason !== validationRejectedReasons.NA)
  && !value) ?
    <FormattedMessage {...messages.REQUIRED} />
    : undefined
);

export const requiredProposition = value => (value === undefined ?
  <FormattedMessage {...messages.REQUIRED} />
  : undefined);

export const requiredPurpose = (value, product) => (
  product.proposition === 5 && !value ?
    <FormattedMessage {...messages.REQUIRED} />
    : undefined
);
