import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import ConnectedReviewCard from '../card/connectedReviewCard';
import ConnectedReplyCardList from '../reply/cardList/connectedReplyCardList';
import withRepliesConnect from '../connect/withReplies.connect';
import withExpandReplies from './withExpandReplies';

const ConnectedReviewRepliesCardList = compose(
  withRepliesConnect,
)(ConnectedReplyCardList);

const enhancer = compose(
  withExpandReplies,
  memo,
);

const ReviewCardWithRepliesCard = ({
  repliesExpanded,
  onSetRepliesExpanded,
  onClickReply,
  ...props
}) => (
  <>
    <ConnectedReviewCard
      {...props}
      withReplies
      repliesExpanded={repliesExpanded}
      onSetRepliesExpanded={onSetRepliesExpanded}
      onClickReply={onClickReply}
    />

    { repliesExpanded
      && (
        <ConnectedReviewRepliesCardList
          {...props}
        />
      ) }
  </>
);

ReviewCardWithRepliesCard.displayName = 'ReviewCardWithRepliesCard';

ReviewCardWithRepliesCard.propTypes = {
  repliesExpanded: PropTypes.bool.isRequired,
  onSetRepliesExpanded: PropTypes.func.isRequired,
  onClickReply: PropTypes.func,
};

ReviewCardWithRepliesCard.defaultProps = {
  onClickReply: noop,
};

export default enhancer(ReviewCardWithRepliesCard);
