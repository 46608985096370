import { compose, lifecycle, withProps } from 'recompose';

import connect from 'react/hoc/connectProxy';
import withSuggestionsFilters from 'react/business/suggestion/filters/withFiltersControllerInQueryString';
import {
  selectProductSuggestionsListById,
  selectProductSuggestionsCount,
  selectSuggestionsCurrentCount,
} from 'redux/suggestions/selectors';
import { getProductSuggestions } from 'redux/suggestions/actions';
import { SUGGESTIONS_PAGINATION_ITEM_NUMBER } from 'config/constants';

export default compose(
  withSuggestionsFilters(props => props.product.id),

  connect(
    (state, props) => ({
      suggestions: selectProductSuggestionsListById(state, props.product.id, props.filters),
      totalCount: selectProductSuggestionsCount(state, props.product.id),
      currentCount: selectSuggestionsCurrentCount(state, props.product.id),
    }),
    {
      getProductSuggestions,
    },
  ),

  withProps(props => ({
    onLoadPage: (productId, { preserve = true }) => {
      props.getProductSuggestions(
        productId,
        {
          ...props.filters,
          skip: preserve ? props.currentCount : 0,
          limit: SUGGESTIONS_PAGINATION_ITEM_NUMBER,
          preserve,
        },
      );
    },
  })),

  lifecycle({
    /** Load reviews on mount. */
    componentDidMount() {
      if (this.props.product.id) {
        this.props.onLoadPage(this.props.product.id, { preserve: false });
      }
    },

    /**
     * Load reviews when filters update.
     *
     * @param {object} prevProps - Previous props.
     */
    componentDidUpdate(prevProps) {
      if ((this.props.product.id) && (prevProps.filters !== this.props.filters)) {
        this.props.onLoadPage(this.props.product.id, { preserve: false });
      }
    },
  }),
);
