import { PRODUCT_FORM_STEPS } from './form-steps.constants';
import requestManifest from './step-0-request/request.manifest';
import presentationManifest from './step-1/presentation.manifest';
import featuresManifest from './step-2/features.manifest';
import linksManifest from './step-5/links.manifest';

export const PRODUCT_FORM_STEPS_MANIFESTS = {
  [PRODUCT_FORM_STEPS.REQUEST]: requestManifest,
  [PRODUCT_FORM_STEPS.PRESENTATION]: presentationManifest,
  [PRODUCT_FORM_STEPS.FEATURES]: featuresManifest,
  [PRODUCT_FORM_STEPS.LINKS]: linksManifest,
};
