import connect from 'react/hoc/connectProxy';

import { createReply } from 'redux/suggestions/replies/actions';

/**
 * Required props:
 * - productId.
 * - suggestionId.
 *
 * Exposes a onCreateReply func bound to the productId and suggestionId props.
 */
export default connect(
  null,
  (dispatch, props) => ({
    onCreateReply: reply => dispatch(createReply(props.productId, props.suggestionId, null, reply)),
  }),
);
