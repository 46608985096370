import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import layoutClassNames from '../layout.module.scss';

const enhancer = compose(
  memo,
);

const Section = ({
  theme,
  className,
  children,
  pageType,
  ...props
}) => (
  <section
    {...props}
    className={cn(
      className,
      layoutClassNames[`theme--${theme}`],
      layoutClassNames[`theme--${theme}--${pageType}`],
      layoutClassNames.section,
    )}
  >
    <div className={layoutClassNames.containerContent}>{children}</div>
  </section>
);

Section.displayName = 'ProductSection';

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.oneOf(['dark', 'light']),
  pageType: PropTypes.string,
};

Section.defaultProps = {
  className: null,
  children: null,
  theme: 'light',
  pageType: '',
};

export default enhancer(Section);
