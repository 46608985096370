import React, { memo } from 'react';
import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';

import { propositionOptions } from 'react/generic/proposition/Proposition';

import { ReduxFormSelectWithValueSelector, withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { selectDetailedProductById } from 'redux/products/selectors';
import { validationStatuses } from 'redux/products/constants';
import { withProductFormMode, withProductFormName } from '../../Context';

import messages from './proposition.messages';
import { requiredProposition } from '../../../../../generic/form/validators';

const rules = [requiredProposition];

const enhancer = compose(
  withProductFormName(),
  withProductFormMode(),
  memo,
  withReduxFormDefaultValue,
  connect(
    (state, props) => ({
      validation: selectDetailedProductById(state, props.product.id)?.validation,
    }),
  ),
);

const isDisableDependingOnValidation = (status) => {
  const enablingStatuses = [
    validationStatuses.RBDL,
    validationStatuses.RBUA,
    validationStatuses.RBBUA,
    validationStatuses.RBCA,
  ];

  if (!status || enablingStatuses.includes(status)) return false;

  return true;
};

const PropositionField = ({ formMode, validation }) => (
  <Field
    type="text"
    name="proposition"
    title={<FormattedMessage {...messages.FIELD_TITLE} />}
    component={ReduxFormSelectWithValueSelector}
    options={propositionOptions}
    isSearchable={false}
    menuPlacement="bottom"
    validate={rules}
    isDisabled={isDisableDependingOnValidation(validation?.status) && formMode === 'update'}
  />
);

PropositionField.displayName = 'PropositionField';

export default enhancer(PropositionField);
