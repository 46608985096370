import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from './list.module.scss';

const enhancer = compose(
  memo,
);

const ProductFeaturesList = ({ features }) => (
  <>
    <ul className={classNames.list}>
      { features.map(feature => (
        <li
          key={feature}
          className={classNames.featureElement}
        >
          <Asset
            name={ICON_MAP.check_icon}
            className={classNames.icon}
            aria-hidden
          />
          <span className={classNames.label}>{feature}</span>
        </li>
      )) }
    </ul>
  </>
);

ProductFeaturesList.displayName = 'ProductFeaturesList';

ProductFeaturesList.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
};

ProductFeaturesList.defaultProps = {
  features: [],
};

export default enhancer(ProductFeaturesList);
