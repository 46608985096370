import { defineMessages } from 'react-intl';

export default defineMessages({
  IS_MAINTAINED_BY_PLATFORM_TITLE: { id: 'product.form.fields.isMaintainedByPlatform.title', defaultMessage: 'Is the digital product maintained by the platform ? *' },

  IS_MAINTAINED_BY_PLATFORM_HINT: {
    id: 'product.form.fields.isMaintainedByPlatform.hint',
    defaultMessage: 'Define if the platform maintain this digital product by itself or not. If you don\'t know, fill in "No". Disabled if your product is pending validation.',
  },

  UNLOCKER_TOOLTIP: {
    id: 'product.form.fields.isMaintainedByPlatform.unlocker.tooltip',
    defaultMessage: 'Click to unlock the field. ⚠️Making changes to this field while the product is a draft will reset the validation workflow.⚠️',
  },

  UNLOCKER_TOOLTIP_LOCK: {
    id: 'product.form.fields.isMaintainedByPlatform.unlocker.tooltipLock',
    defaultMessage: 'Click to lock the field. ⚠️Making changes to this field while the product is a draft will reset the validation workflow.⚠️',
  },
});
