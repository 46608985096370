import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { linksShape } from 'shapes/product';
import { PRODUCT_LINKS } from 'config/constants';

import ProductLink from './ProductLink';
import classNames from './product-links.module.scss';

const enhancer = compose(
  memo,
);

const productLinksKeys = [
  PRODUCT_LINKS.URL,
  PRODUCT_LINKS.MARKETING,
  PRODUCT_LINKS.ROADMAP,
  PRODUCT_LINKS.DOCUMENTATION,
  PRODUCT_LINKS.DISCUSSION,
  PRODUCT_LINKS.API_REFERENCE,
  PRODUCT_LINKS.SOURCE,
  PRODUCT_LINKS.BACKLOG,
  PRODUCT_LINKS.SUPPORT,
  PRODUCT_LINKS.DATASETS,
];

const ProductLinks = ({
  links = {},
  className,
  clickable,
  pageType,
}) => (
  <>
    <div className={cn(className, classNames.container)}>
      {productLinksKeys.slice(
        0,
        (productLinksKeys.length % 2) === 0 ?
          productLinksKeys.length / 2
          : (productLinksKeys.length / 2) + 1,
      )
        .map(linkKey => (
          <ProductLink
            key={linkKey}
            linkName={linkKey}
            links={links[linkKey]}
            disabled={!clickable}
            className={classNames.link}
            pageType={pageType}
          />
        ))}
    </div>
    <div className={cn(className, classNames.container)}>
      {productLinksKeys.slice(
        (productLinksKeys.length % 2) === 0 ?
          productLinksKeys.length / 2
          : (productLinksKeys.length / 2) + 1,
        productLinksKeys.length,
      )
        .map(linkKey => (
          <ProductLink
            key={linkKey}
            linkName={linkKey}
            links={links[linkKey]}
            disabled={!clickable}
            className={classNames.link}
            pageType={pageType}
          />
        )) }
    </div>
  </>
);

ProductLinks.displayName = 'ProductLinks';

ProductLinks.propTypes = {
  links: linksShape,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  pageType: PropTypes.string,
};

ProductLinks.defaultProps = {
  links: {},
  className: null,
  clickable: true,
  pageType: '',
};

export default enhancer(ProductLinks);
