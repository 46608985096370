export const mapCommunityFromServer = ({
  businessCommunityLeader,
  digitalCommunityLeader,
  ...community
}) => ({
  businessCommunityLeaderId: businessCommunityLeader,
  digitalCommunityLeaderId: digitalCommunityLeader,
  ...community,
});

export const mapDomainFromServer = domain => ({
  ...domain,
  community: mapCommunityFromServer(domain?.community),
});
