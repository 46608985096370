import { withProps } from 'recompose';
import {
  MAX_SIZE_SCREENSHOT_UPLOAD,
  ACCEPT_MIME_TYPES,
  ENABLE_MULTIPLE_UPLOAD,
} from 'config/constants';

export default withProps(() => ({
  maxSize: MAX_SIZE_SCREENSHOT_UPLOAD,
  accept: ACCEPT_MIME_TYPES,
  multiple: ENABLE_MULTIPLE_UPLOAD,
}));
