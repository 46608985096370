import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cn from 'classnames';

import { replyShape } from 'shapes/product';
import { userShape } from 'shapes/user';

import MessageCard from 'react/generic/message/messageCard/MessageCard';
import MessageCardHeader from 'react/generic/message/messageCard/layout/header/Header';
import MessageCardMain from 'react/generic/message/messageCard/layout/main/Main';
import MessageCardFooter from 'react/generic/message/messageCard/layout/footer/Footer';
import MessageCardAuthor from 'react/generic/message/messageCard/author/Author';
import MessageCardDate from 'react/generic/message/messageCard/date/Date';
import MessageCardContent from 'react/generic/message/messageCard/content/Content';
import MessageCardHelpful from 'react/generic/message/messageCard/helpful/Helpful';
import TranslationContentSwitch from 'react/generic/translation/TranslationContentSwitch.children';
import { isTranslatedContentEmpty } from 'react/generic/translation/translation.utils';

import classNames from './reply-card.module.scss';

export default class ReplyCard extends React.PureComponent {
  static propTypes = {
    reply: replyShape.isRequired,
    author: userShape,
    compact: PropTypes.bool,
    withTranslateButton: PropTypes.bool,
    className: PropTypes.string,
    onVote: PropTypes.func,
    /** Called when requesting to translate. () => ({success: bool}). */
    onTranslate: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    author: null,
    compact: false,
    withTranslateButton: false,
    onVote: noop,
    onTranslate: noop,
  };

  onVote = (replyId, vote) => this.props.onVote(this.props.reply.suggestion, replyId, vote);

  /** @returns {object} JSX. */
  render() {
    const {
      author,
      reply: {
        content,
        date,
        helpful,
        translatedContent,
        id: replyId,
      },
      className,
      compact,
      withTranslateButton,
      onTranslate,
    } = this.props;

    return (
      <MessageCard
        className={cn(
          className,
          classNames.card,
          compact && classNames.compact,
        )}
        compact={compact}
      >
        <MessageCardHeader className={classNames.header} compact={compact}>
          <MessageCardAuthor author={author} compact={compact} />
        </MessageCardHeader>

        <MessageCardMain
          compact={compact}
          className={classNames.main}
        >
          <MessageCardDate className={classNames.dateContainer} date={date} />

          <TranslationContentSwitch
            key={content}
            original={content}
            translated={translatedContent}
            onTranslate={onTranslate}
            isTranslatedContentEmpty={isTranslatedContentEmpty}
          >
            {(displayedContent, isTranslatedContentDisplayed, onRequestTranslate) => (
              <MessageCardContent
                withTranslateButton={withTranslateButton}
                content={displayedContent}
                translatedContent={translatedContent}
                isTranslatedContentDisplayed={isTranslatedContentDisplayed}
                onRequestTranslate={onRequestTranslate}
              />
            )}
          </TranslationContentSwitch>
        </MessageCardMain>

        { !compact
          && (
            <MessageCardFooter compact={compact}>
              <MessageCardHelpful
                messageId={replyId}
                helpful={helpful}
                onVote={this.onVote}
                className={classNames.helpful}
                votesContainerClassName={classNames.votesContainer}
              />
            </MessageCardFooter>
          ) }
      </MessageCard>
    );
  }
}
