import * as CommunitiesService from 'services/communities';

import { ACTIONS } from './constants';

export const GET_COMMUNITIES = query => ({
  type: ACTIONS.GET_COMMUNITIES,
  query,
});

export const GET_COMMUNITIES_SUCCESS = (communities, count, query) => ({
  type: ACTIONS.GET_COMMUNITIES_SUCCESS,
  communities,
  count,
  query,
});

export const GET_COMMUNITIES_FAILURE = error => ({
  type: ACTIONS.GET_COMMUNITIES_FAILURE,
  error,
});

export const getCommunities = query => async (dispatch) => {
  dispatch(GET_COMMUNITIES(query));

  try {
    const { communities, count } = await CommunitiesService.getCommunities(query);
    return dispatch(GET_COMMUNITIES_SUCCESS(communities, count, query));
  } catch (err) {
    return dispatch(GET_COMMUNITIES_FAILURE(err));
  }
};

export const GET_COMMUNITY_DOMAINS = communityId => ({
  type: ACTIONS.GET_COMMUNITY_DOMAINS,
  communityId,
});

export const GET_COMMUNITY_DOMAINS_SUCCESS = (communityId, domains, count) => ({
  type: ACTIONS.GET_COMMUNITY_DOMAINS_SUCCESS,
  communityId,
  domains,
  count,
});

export const GET_COMMUNITY_DOMAINS_FAILURE = error => ({
  type: ACTIONS.GET_COMMUNITY_DOMAINS_FAILURE,
  error,
});

export const getCommunityDomains = communityId => async (dispatch) => {
  dispatch(GET_COMMUNITY_DOMAINS(communityId));

  try {
    const { domains, count } = await CommunitiesService.getCommunityDomains(communityId);
    return dispatch(GET_COMMUNITY_DOMAINS_SUCCESS(communityId, domains, count));
  } catch (err) {
    return dispatch(GET_COMMUNITY_DOMAINS_FAILURE(err));
  }
};

export const GET_DOMAIN = (domainId, query) => ({
  type: ACTIONS.GET_DOMAIN,
  domainId,
  query,
});

export const GET_DOMAIN_SUCCESS = (domain, community) => ({
  type: ACTIONS.GET_DOMAIN_SUCCESS,
  domain,
  community,
});

export const GET_DOMAIN_FAILURE = error => ({
  type: ACTIONS.GET_DOMAIN_FAILURE,
  error,
});

export const getDomain = (domainId, query = {}) => async (dispatch) => {
  dispatch(GET_DOMAIN(domainId, query));

  try {
    const domain = await CommunitiesService.getDomain(domainId, query);

    const community = query.populateCommunity ?
      domain.community
      : null;

    return dispatch(GET_DOMAIN_SUCCESS(domain, community));
  } catch (err) {
    return dispatch(GET_DOMAIN_FAILURE(err));
  }
};
