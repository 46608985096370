import React, { memo } from 'react';
import { compose } from 'recompose';

import { MAX_MODULE_TITLE_LENGTH } from 'config/constants';
import { maxLength } from 'react/generic/form/validators';

import { FieldArray } from 'redux-form';
import ModulesForm from 'react/business/modules/form/ModulesForm';

const rules = [
  maxLength(MAX_MODULE_TITLE_LENGTH),
];

const moduleFieldsProps = {
  validate: rules,
  maxLength: MAX_MODULE_TITLE_LENGTH,
};

const enhancer = compose(
  memo,
);

const ModulesField = () => (
  <FieldArray
    hideFieldTitle
    name="modules"
    component={ModulesForm}
    fieldProps={moduleFieldsProps}
  />
);

ModulesField.displayName = 'ModulesField';

export default enhancer(ModulesField);
