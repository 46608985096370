import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import classNames from './product-subheader.module.scss';

const enhancer = compose(
  memo,
);

const ProductSubHeader = ({ children }) => (
  <div className={classNames.container}>
    <div className={classNames.content}>
      {children}
    </div>
  </div>
);

ProductSubHeader.displayName = 'ProductSubHeader';

ProductSubHeader.propTypes = {
  children: PropTypes.node,
};

ProductSubHeader.defaultProps = {
  children: null,
};

export default enhancer(ProductSubHeader);
