import { compose, withProps } from 'recompose';

import { getPublishersStartingWith, createPublisher } from 'services/products';

import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

const mapPublisher = publisher => ({
  value: publisher.id,
  label: publisher.label,
});

const searchPublishers = async (query) => {
  if (!query) {
    return [];
  }

  const results = await getPublishersStartingWith(query);

  return results.map(mapPublisher);
};

export default compose(
  withProps(
    () => ({
      isMulti: false,
      loadOptions: query => searchPublishers(query),
      onCreateOption: async (newPublisher) => {
        const { id, label } = await createPublisher(newPublisher);

        return {
          value: id,
          label,
        };
      },
    }),
  ),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,
)(Autocomplete);
