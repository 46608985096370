import { noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import createReplyForm from './createReplyForm.container';

export default (WrappedComponent) => {
  const enhancer = compose(
    createReplyForm,
  );

  const createReplyFormChildrenContainer = ({
    onSubmit,
    canSubmit,
    children,
    ...props
  }) => (
    <form onSubmit={onSubmit}>
      { children({
        form: (
          <WrappedComponent {...props} />
        ),
        canSubmit,
      }) }
    </form>
  );

  createReplyFormChildrenContainer.displayName = 'createReplyFormChildrenContainer';

  createReplyFormChildrenContainer.propTypes = {
    canSubmit: PropTypes.bool,
    onSubmit: PropTypes.func,
    /** Enforce function as child. */
    children: PropTypes.func,
  };

  createReplyFormChildrenContainer.defaultProps = {
    canSubmit: false,
    onSubmit: noop,
    children: noop,
  };

  return enhancer(createReplyFormChildrenContainer);
};
