import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cn from 'classnames';
import { compose } from 'recompose';

import { replyShape } from 'shapes/product';
import { userShape } from 'shapes/user';

import withDialogState from 'react/generic/dialog/withDialogState';

import DeleteIconButton from 'react/generic/button/buttons/DeleteIconButton';

import MessageCard from 'react/generic/message/messageCard/MessageCard';
import MessageCardHeader from 'react/generic/message/messageCard/layout/header/Header';
import MessageCardMain from 'react/generic/message/messageCard/layout/main/Main';
import MessageCardFooter from 'react/generic/message/messageCard/layout/footer/Footer';
import MessageCardAuthor from 'react/generic/message/messageCard/author/Author';
import MessageCardDate from 'react/generic/message/messageCard/date/Date';
import MessageCardContent from 'react/generic/message/messageCard/content/Content';
import MessageCardHelpful from 'react/generic/message/messageCard/helpful/Helpful';
import TranslationContentSwitch from 'react/generic/translation/TranslationContentSwitch.children';
import { isTranslatedContentEmpty } from 'react/generic/translation/translation.utils';
import DeleteConfirmationDialog from 'react/business/review/reply/delete/dialog/DeleteConfirmationDialog';

import classNames from './replyCard.module.scss';

const enhancer = compose(
  withDialogState('DeleteReply'),
);

class ReplyCard extends React.PureComponent {
  static propTypes = {
    reply: replyShape.isRequired,
    author: userShape,
    productId: PropTypes.string,
    compact: PropTypes.bool,
    withTranslateButton: PropTypes.bool,
    className: PropTypes.string,
    onVote: PropTypes.func,
    /** Called when requesting to translate. () => ({success: bool}). */
    onTranslate: PropTypes.func,
    /** Should display delete button. */
    canDeleteReply: PropTypes.bool,
    /** Should display delete dialog. */
    isDeleteReplyDialogOpen: PropTypes.bool,
    onOpenDeleteReplyDialog: PropTypes.func.isRequired,
    onCloseDeleteReplyDialog: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
    author: null,
    compact: false,
    productId: null,
    withTranslateButton: false,
    onVote: noop,
    onTranslate: noop,
    canDeleteReply: false,
    isDeleteReplyDialogOpen: false,
  };

  onVote = (replyId, vote) => this.props.onVote(this.props.reply.review, replyId, vote);

  /** @returns {object} JSX. */
  render() {
    const {
      author,
      reply: {
        content,
        date,
        helpful,
        review: reviewId,
        translatedContent,
        id: replyId,
      },
      className,
      productId,
      compact,
      withTranslateButton,
      onTranslate,
      canDeleteReply,
      isDeleteReplyDialogOpen,
      onOpenDeleteReplyDialog,
      onCloseDeleteReplyDialog,
    } = this.props;

    return (
      <>
        <MessageCard
          className={cn(
            className,
            classNames.card,
            compact && classNames.compact,
          )}
          compact={compact}
        >
          <MessageCardHeader className={classNames.header} compact={compact}>
            <MessageCardAuthor author={author} compact={compact} />
          </MessageCardHeader>

          <MessageCardMain
            compact={compact}
            className={classNames.main}
          >
            <MessageCardDate className={classNames.dateContainer} date={date} />

            <TranslationContentSwitch
              key={content}
              original={content}
              translated={translatedContent}
              onTranslate={onTranslate}
              isTranslatedContentEmpty={isTranslatedContentEmpty}
            >
              {(displayedContent, isTranslatedContentDisplayed, onRequestTranslate) => (
                <MessageCardContent
                  withTranslateButton={withTranslateButton}
                  content={displayedContent}
                  translatedContent={translatedContent}
                  isTranslatedContentDisplayed={isTranslatedContentDisplayed}
                  onRequestTranslate={onRequestTranslate}
                  compact
                />
              )}
            </TranslationContentSwitch>
          </MessageCardMain>

          {!compact
            && (
              <MessageCardFooter compact={compact}>
                <MessageCardHelpful
                  messageId={replyId}
                  helpful={helpful}
                  onVote={this.onVote}
                  className={classNames.helpful}
                  votesContainerClassName={classNames.votesContainer}
                />

                <div className={classNames.footerRight}>
                  {!compact && (
                    <div className={classNames.actionsContainer}>
                      { canDeleteReply
                        && (
                          <DeleteIconButton
                            onClickDelete={onOpenDeleteReplyDialog}
                          />
                        )}
                    </div>
                  )}
                </div>
              </MessageCardFooter>
            )}
        </MessageCard>

        <DeleteConfirmationDialog
          isOpen={isDeleteReplyDialogOpen}
          onRequestClose={onCloseDeleteReplyDialog}
          productId={productId}
          reviewId={reviewId}
          replyId={replyId}
          onDeleteSuccess={onCloseDeleteReplyDialog}
        />
      </>
    );
  }
}

export default enhancer(ReplyCard);
