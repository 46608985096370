import React, { memo } from 'react';
import { compose } from 'recompose';

import { FieldArray } from 'redux-form';
import UploadScreenshots from 'react/business/products/form/upload/UploadScreenshots';

const enhancer = compose(
  memo,
);

const ScreenshotsField = () => (
  <FieldArray
    hideFieldTitle
    name="screenshots"
    component={UploadScreenshots}
  />
);

ScreenshotsField.displayName = 'ScreenshotsField';

export default enhancer(ScreenshotsField);
