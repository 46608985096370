import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import {
  selectActivitiesMap,
  selectAtomicActivitiesMap,
  selectMacroActivitiesMap,
  selectActivitiesIsLoading,
  selectAtomicActivitiesIsLoading,
  selectMacroActivitiesIsLoading,
} from 'redux/businessActivities/selectors';
import { getAtomicActivities, getActivities, getMacroActivities } from 'redux/businessActivities/actions';

export default compose(
  // Connect business activities.
  connect(state => ({
    macroActivitiesMap: selectMacroActivitiesMap(state),
    activitiesMap: selectActivitiesMap(state),
    atomicActivitiesMap: selectAtomicActivitiesMap(state),

    isLoadingMacroActivity: selectMacroActivitiesIsLoading(state),
    isLoadingActivity: selectActivitiesIsLoading(state),
    isLoadingAtomicActivity: selectAtomicActivitiesIsLoading(state),
  }), dispatch => ({
    getMacroActivities: () => dispatch(getMacroActivities()),
    getActivities: () => dispatch(getActivities()),
    getAtomicActivities: () => dispatch(getAtomicActivities()),
    onSelectMacroActivity: macroActivity => dispatch(getActivities({
      macroActivityIds: macroActivity.id,
    })),
    onSelectActivity: activity => dispatch(getAtomicActivities({
      activityIds: activity.id,
    })),
  })),

  lifecycle({
    /**
     * Load all macro activities on first load.
     *
     * @async
     */
    async componentDidMount() {
      await Promise.all([this.props.getMacroActivities(),
        this.props.getActivities(), this.props.getAtomicActivities()]);
    },
  }),
);
