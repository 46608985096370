import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import classNames from './upload-container.module.scss';

const getContainerClassName = (
  isDragAccept,
  isDragReject,
) => {
  switch (true) {
    case isDragReject:
      return classNames.dropAreaError;
    case isDragAccept:
      return classNames.dropAreaValid;
    default:
      return '';
  }
};

const UploadContainer = ({
  isDragAccept,
  isDragReject,
  children,
}) => {
  const className = getContainerClassName(
    isDragAccept,
    isDragReject,
  );

  return (
    <div className={cn(classNames.container, className)}>
      {children}
    </div>
  );
};

UploadContainer.displayName = 'UploadContainer';

UploadContainer.propTypes = {
  isDragAccept: PropTypes.bool,
  isDragReject: PropTypes.bool,
  children: PropTypes.node,
};

UploadContainer.defaultProps = {
  isDragAccept: false,
  isDragReject: false,
  children: null,
};

export default memo(UploadContainer);
