import { defineMessages } from 'react-intl';

export default defineMessages({
  SUGGEST_MY_FEATURE: { id: 'product.suggestions.suggestMyFeature', defaultMessage: 'Add a suggestion' },
  NO_SUGGESTIONS: {
    id: 'product.suggestions.noSuggestions',
    defaultMessage: 'There are no suggestions for this digital product. Be the first!',
  },
  SUGGESTIONS_NO: { id: 'generic.filter.suggestions_no', defaultMessage: 'Suggestions on page : {suggestionsNo}' },
});
