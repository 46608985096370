import PropTypes from 'prop-types';
import { compose } from 'recompose';
import filesize from 'filesize';

import { injectIntl, intlShape } from 'react-intl';

const enhancer = compose(
  injectIntl,
);

// This does not handle localization for symbols (KB, MB, etc.).
// It can be done manually using the 'symbols' prop if we want to handle i18n later.
const FileSize = ({
  intl: { locale },
  size,
  ...props
}) => filesize(
  size,
  {
    locale,
    ...props,
  },
);

FileSize.propTypes = {
  /** From injectIntl. */
  intl: intlShape.isRequired,
  /** Size in bytes. */
  size: PropTypes.number.isRequired,
  /** Decimal place. */
  round: PropTypes.number,
};

FileSize.defaultProps = {
  round: 0,
};

export default enhancer(FileSize);
