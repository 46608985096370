import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from './placeholder.module.scss';

const Placeholder = ({
  getInputProps,
  getRootProps,
  onBlur,
}) => (
  <div
    {...getRootProps()}
    className={classNames.placeholder}
  >
    <input {...getInputProps()} onBlur={onBlur} />

    <Asset
      aria-hidden
      className={classNames.icon}
      name={ICON_MAP.cloud_upload_arrow}
    />
  </div>
);

Placeholder.displayName = 'Placeholder';

Placeholder.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

Placeholder.defaultProps = {
  onBlur: noop,
};

export default Placeholder;
