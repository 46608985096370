import { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, renameProp } from 'recompose';
import { fieldArrayFieldsPropTypes } from 'redux-form';

import FeatureFormPresentation from './FeaturesForm.presentation';

const { shape } = PropTypes;

export const enhancer = compose(
  renameProp('fields', 'features'),
  memo,
);

const FeaturesForm = enhancer(FeatureFormPresentation);

FeaturesForm.displayName = 'FeaturesForm';
FeaturesForm.propTypes = {
  fields: shape(fieldArrayFieldsPropTypes).isRequired,
};

export default FeaturesForm;
