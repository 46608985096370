import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import GenericRadio from 'react/generic/radio/Radio';
import { Tooltip } from 'antd';
import reduxFormInputAdapter from '../reduxFormInputAdapter';
import Field from '../field/Field';

import classNames from './radio.module.scss';
import Asset, { ICON_MAP } from '../../asset/Asset';

const enhancer = compose(
  memo,
);

const RadioField = ({
  className,
  title,
  tooltip,
  error,
  disabled,
  hasUnlocker,
  unlockerTooltip,
  unlockerTooltipLock,
  ...props
}) => {
  const [isLocked, setIsLocked] = useState(true);

  const toggleLock = () => {
    setIsLocked(disabled || !isLocked);
  };
  return (
    <Field
      title={title}
      tooltip={tooltip}
      error={error}
    >
      <div className={cn(classNames.optionsContainer, { [classNames.hasUnlocker]: hasUnlocker })}>
        <GenericRadio
          {...props}
          disabled={disabled || isLocked}
          className={cn(
            className,
            error && classNames.hasError,
          )}
        />
        {hasUnlocker && !disabled
          && (
            <Tooltip placement="bottom" title={isLocked ? unlockerTooltip : unlockerTooltipLock}>
              <Asset
                className={cn(classNames.lock, { [classNames.unlocked]: !isLocked })}
                name={isLocked ? ICON_MAP.lock : ICON_MAP.lockUnlocked}
                onClick={() => toggleLock()}
              />
            </Tooltip>
          )
        }
      </div>
    </Field>
  );
};

RadioField.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  tooltip: PropTypes.node,
  error: PropTypes.node,
  hasUnlocker: PropTypes.bool,
  unlockerTooltip: PropTypes.node,
  unlockerTooltipLock: PropTypes.node,
};

RadioField.defaultProps = {
  className: null,
  title: null,
  tooltip: null,
  error: null,
  hasUnlocker: false,
  unlockerTooltip: null,
  unlockerTooltipLock: null,
};

export default enhancer(RadioField);

export const ReduxFormRadio = compose(
  enhancer,
  reduxFormInputAdapter,
)(RadioField);
