import {
 compose,
 lifecycle,
 withProps,
 withPropsOnChange,
} from 'recompose';
import connect from 'react/hoc/connectProxy';
import { change as changeAction } from 'redux-form';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

import { getLocalOrganizationChildren } from 'redux/localOrganizations/actions';
import { getBusinessUnits } from 'redux/businessUnits/actions';
import withBusinessUnitsOptions from './withBusinessUnitsOptions';

export default compose(
  withBusinessUnitsOptions,

  connect(
    () => {},
    dispatch => ({
      dispatch,
      change: changeAction,
    }),
  ),

  withProps(() => ({
    isMulti: false,
  })),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,

  // If we only have the key (when the form is filled on edition),
  // we grab the label from the map.
  withPropsOnChange(
    ['businessUnitsMap', 'value'],
    ({ value, businessUnitsMap }) => ({
      // isInactive filtering could be done in withBusinessUnitsOptions as well
      value: value && !businessUnitsMap[value.value]?.isInactive ? {
        ...value,
        label: businessUnitsMap[value.value]?.name,
      } : null,
    }),
  ),

  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.value?.value !== this.props.value?.value) {
        if (this.props.value?.organizationEntityTechnicalUniqueId) {
          this.props.dispatch(
            getLocalOrganizationChildren(this.props.value?.organizationEntityTechnicalUniqueId),
          );
        }
      }
    },
    async componentDidMount() {
      if (this.props.value?.value) {
        const { businessUnits } = await this.props.dispatch(
          getBusinessUnits({ ids: [this.props.value?.value] }),
        );
        this.props.dispatch(
          getLocalOrganizationChildren(businessUnits[0]?.organizationEntityTechnicalUniqueId),
        );
      }
    },
  }),
)(Autocomplete);
