import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { compose, renameProp } from 'recompose';

import Card from 'react/generic/card/Card';
import Avatar from 'react/generic/avatar/Avatar';
import { teamMemberFormShape } from 'shapes/product';

import BusinessUnitsAutocomplete
  from 'react/business/products/form/autocompletes/businessUnits/BusinessUnitsUsingProduct';
import UsersAutocomplete from 'react/business/products/form/autocompletes/Users';

import businessMessages from 'config/business.messages';

import classNames from './team-member-fields.module.scss';
import messages from '../contact-form.messages';
import reduxFormInputAdapter from '../../../../../generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from '../../../../../generic/form/select/Select';
import Autocomplete from '../../../../../generic/form/autocomplete/Autocomplete';

const enhancer = compose(
  renameProp('field', 'name'),
  renameProp('item', 'teamMember'),
);

const FakeRolesAutocomplete = compose(
  reduxFormInputAdapter,
  withReduxFormDefaultValue,
)(Autocomplete);

const TeamMemberFields = ({
  name,
  teamMember,
  formName,
}) => {
  const isBuSuitable = teamMember?.role?.isBuSuitable;
  return (
    <Card className={classNames.card} key={name}>
      <Avatar
        className={classNames.avatar}
        src={teamMember?.user?.value?.avatar}
      />
      <div className={classNames.form}>
        <Field
          type="object"
          title={<FormattedMessage {...messages.ROLE} />}
          name={`${name}.role`}
          component={FakeRolesAutocomplete}
          isDisabled
        />
        { isBuSuitable && (
          <Field
            type="object"
            title={<FormattedMessage {...businessMessages.BUSINESS_UNIT} />}
            tooltip={<FormattedMessage {...messages.BUSINESS_UNIT_TOOLTIP} />}
            name={`${name}.buReference`}
            component={BusinessUnitsAutocomplete}
            formName={formName}
          />
        ) }
        <Field
          type="object"
          title={<FormattedMessage {...messages.MEMBER} />}
          name={`${name}.user`}
          component={UsersAutocomplete}
        />
      </div>
    </Card>
  );
};

TeamMemberFields.displayName = 'TeamMemberFields';

TeamMemberFields.propTypes = {
  name: PropTypes.string.isRequired,
  teamMember: teamMemberFormShape.isRequired,
  formName: PropTypes.string,
};

TeamMemberFields.defaultProps = {
  formName: '',
};

export default enhancer(TeamMemberFields);
