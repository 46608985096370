import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { tagShape } from 'shapes/product';
import cn from 'classnames';
import classNames from './tagList.module.scss';

const TagList = ({ list, pageType, theme }) => (
  <ul className={cn(
    classNames[`list--${theme}`],
    classNames[`list--${theme}--${pageType}`],
  )}
  >
    { list.map(element => (
      <li key={element.id}>{element.label}</li>
    )) }
  </ul>
);

TagList.displayName = 'TagList';

TagList.propTypes = {
  list: PropTypes.arrayOf(tagShape),
  pageType: PropTypes.string,
  theme: PropTypes.string,
};

TagList.defaultProps = {
  list: [],
  pageType: '',
  theme: 'light',
};

export default memo(TagList);
