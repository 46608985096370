import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import cn from 'classnames';

import { suggestionShape } from 'shapes/product';
import { userShape } from 'shapes/user';
import { isHtmlContentEmpty } from 'services/html';

import withDialogState from 'react/generic/dialog/withDialogState';

import EditIconButton from 'react/generic/button/buttons/EditIconButton';
import DeleteIconButton from 'react/generic/button/buttons/DeleteIconButton';

import MessageCard from 'react/generic/message/messageCard/MessageCard';
import MessageCardHeader from 'react/generic/message/messageCard/layout/header/Header';
import MessageCardMain from 'react/generic/message/messageCard/layout/main/Main';
import MessageCardFooter from 'react/generic/message/messageCard/layout/footer/Footer';
import MessageCardAuthor from 'react/generic/message/messageCard/author/Author';
import MessageCardDate from 'react/generic/message/messageCard/date/Date';
import MessageCardHelpful from 'react/generic/message/messageCard/helpful/Helpful';
import MessageCardReplies from 'react/generic/message/messageCard/replies/Replies';
import MessageCardContent from 'react/generic/message/messageCard/content/Content';

import EditSuggestionFormDialog from 'react/business/suggestion/form/dialog/edit/EditSuggestionFormDialog';
import DeleteConfirmationDialog from 'react/business/suggestion/delete/dialog/DeleteConfirmationDialog';
import CreateReplyFormDialog from 'react/business/suggestion/reply/createReplyForm/CreateReplyFormDialog';

import TranslationContentSwitch from 'react/generic/translation/TranslationContentSwitch.children';

import MessageCardShare from 'react/generic/message/messageCard/share/Share';
import MessageCardFlexWrapper from 'react/generic/message/messageCard/layout/flexWrapper/MessageCardFlexWrapper';

import classNames from './suggestion-card.module.scss';

const enhancer = compose(
  withDialogState('Reply'),
  withDialogState('EditSuggestion'),
  withDialogState('DeleteSuggestion'),
  withHandlers({
    onSubmitReply: ({ onCreateReply, onCloseReplyDialog }) => async (reply) => {
      // Submit reply.
      await onCreateReply?.(reply);
      onCloseReplyDialog();
    },
    isTranslatedContentEmpty: ({ title, content }) => () => !title && !content,
  }),
  withPropsOnChange(
    ['suggestion'],
    ({ suggestion }) => ({
      original: {
        title: suggestion.title,
        content: suggestion.content,
      },
      translated: {
        title: suggestion.translatedTitle,
        content: suggestion.translatedContent,
      },
    }),
  ),
  memo,
);

const SuggestionCard = ({
  suggestion,
  author,
  compact,
  className,
  onVote,
  // Translation
  withTranslateButton,
  onTranslate,
  isTranslatedContentEmpty,
  original,
  translated,
  // Edit dialog
  canEditSuggestion,
  onOpenEditSuggestionDialog,
  onCloseEditSuggestionDialog,
  isEditSuggestionDialogOpen,
  // Delete dialog
  canDeleteSuggestion,
  onOpenDeleteSuggestionDialog,
  onCloseDeleteSuggestionDialog,
  isDeleteSuggestionDialogOpen,
  // Replies
  withReplies,
  isReplyDialogOpen,
  onClickExpandReplies,
  onOpenReplyDialog,
  onCloseReplyDialog,
  repliesExpanded,
  onSubmitReply,
}) => (
  <>
    <TranslationContentSwitch
      key={`${suggestion.title}-${suggestion.content}`}
      original={original}
      translated={translated}
      onTranslate={onTranslate}
      isTranslatedContentEmpty={isTranslatedContentEmpty}
    >
      {(displayedContent, isTranslatedContentDisplayed, onRequestTranslate) => (
        <MessageCard
          className={cn(className, classNames.container)}
          compact={compact}
        >
          <MessageCardHeader compact={compact}>
            <MessageCardAuthor author={author} compact={compact} />
            { !compact
              && (
                <MessageCardHelpful
                  label={null}
                  messageId={suggestion.id}
                  helpful={suggestion.helpful}
                  onVote={onVote}
                  votesContainerClassName={classNames.noBorder}
                />
              ) }
          </MessageCardHeader>

          { !compact
              && <MessageCardDate className={classNames.dateContainer} date={suggestion.date} /> }

          <div className={classNames.titleContainer}>
            {displayedContent.title}
          </div>

          <MessageCardMain compact={compact}>

            { !isHtmlContentEmpty(suggestion.content)
              && (
                <MessageCardContent
                  className={classNames.contentContainer}
                  withTranslateButton={withTranslateButton}
                  content={displayedContent.content}
                  onRequestTranslate={onRequestTranslate}
                  isTranslatedContentDisplayed={isTranslatedContentDisplayed}
                  compact
                />
              ) }
          </MessageCardMain>

          <MessageCardFooter compact={compact}>
            <div className={classNames.footerRight}>
              { !compact && (
                <div className={classNames.actionsContainer}>
                  { canEditSuggestion
                    && (
                      <EditIconButton
                        onClickEdit={onOpenEditSuggestionDialog}
                      />
                    ) }

                  { canDeleteSuggestion
                    && (
                      <DeleteIconButton
                        onClickDelete={onOpenDeleteSuggestionDialog}
                      />
                    ) }
                </div>
              )}
              { withReplies
                && (
                <MessageCardFlexWrapper>
                  <MessageCardReplies
                    compact={compact}
                    suggestionId={suggestion.id}
                    repliesCount={suggestion.directRepliesCount}
                    repliesExpanded={repliesExpanded}
                    onClickReply={onOpenReplyDialog}
                    onClickExpandReplies={onClickExpandReplies}
                  />
                  <MessageCardShare appendText={suggestion.id.toString()} />
                </MessageCardFlexWrapper>
                ) }
            </div>
          </MessageCardFooter>

          <CreateReplyFormDialog
            isOpen={isReplyDialogOpen}
            onRequestClose={onCloseReplyDialog}
            onSubmit={onSubmitReply}
            content={displayedContent.content}
            title={displayedContent.title}
            author={author}
            suggestionDate={suggestion.date}
          />
        </MessageCard>
      )}
    </TranslationContentSwitch>

    <EditSuggestionFormDialog
      productId={suggestion.product}
      isOpen={isEditSuggestionDialogOpen}
      onRequestClose={onCloseEditSuggestionDialog}
      suggestion={suggestion}
    />

    <DeleteConfirmationDialog
      isOpen={isDeleteSuggestionDialogOpen}
      onRequestClose={onCloseDeleteSuggestionDialog}
      productId={suggestion.product}
      suggestionId={suggestion.id}
      onDeleteSuccess={onCloseDeleteSuggestionDialog}
    />
  </>
);

SuggestionCard.displayName = 'SuggestionCard';

const translateShape = PropTypes.shape({
  title: PropTypes.string,
  content: PropTypes.string,
});

SuggestionCard.propTypes = {
  suggestion: suggestionShape.isRequired,
  author: userShape,
  compact: PropTypes.bool,
  className: PropTypes.string,
  onVote: PropTypes.func,
  onSubmitReply: PropTypes.func,
  // Translation
  withTranslateButton: PropTypes.bool,
  onTranslate: PropTypes.func,
  isTranslatedContentEmpty: PropTypes.func,
  original: translateShape,
  translated: translateShape,
  // Edit dialog
  canEditSuggestion: PropTypes.bool,
  onOpenEditSuggestionDialog: PropTypes.func.isRequired,
  onCloseEditSuggestionDialog: PropTypes.func.isRequired,
  isEditSuggestionDialogOpen: PropTypes.bool,
  // Delete dialog
  canDeleteSuggestion: PropTypes.bool,
  onOpenDeleteSuggestionDialog: PropTypes.func.isRequired,
  onCloseDeleteSuggestionDialog: PropTypes.func.isRequired,
  isDeleteSuggestionDialogOpen: PropTypes.bool,
  // Replies
  withReplies: PropTypes.bool,
  isReplyDialogOpen: PropTypes.bool,
  onClickExpandReplies: PropTypes.func,
  onOpenReplyDialog: PropTypes.func,
  onCloseReplyDialog: PropTypes.func,
  repliesExpanded: PropTypes.bool,
};

SuggestionCard.defaultProps = {
  author: null,
  compact: false,
  className: null,
  onVote: noop,
  onSubmitReply: noop,
  withTranslateButton: false,
  onTranslate: noop,
  isTranslatedContentEmpty: noop,
  original: null,
  translated: null,
  canEditSuggestion: false,
  canDeleteSuggestion: false,
  isDeleteSuggestionDialogOpen: false,
  isEditSuggestionDialogOpen: false,
  withReplies: false,
  isReplyDialogOpen: false,
  onClickExpandReplies: noop,
  onOpenReplyDialog: noop,
  onCloseReplyDialog: noop,
  repliesExpanded: false,
};

export default enhancer(SuggestionCard);
