import { compose, withProps } from 'recompose';

import connect from 'react/hoc/connectProxy';

export default compose(
  connect(),

  withProps(
    ({ initialValues }) => ({
      initialValues: {
        ...initialValues,
        softwarePublisher: initialValues?.softwarePublisher,
      },
    }),
  ),
);
