import connect from 'react/hoc/connectProxy';
import { getFormValues, change as changeAction } from 'redux-form';
import {
  compose,
  lifecycle,
  withPropsOnChange,
  withProps,
} from 'recompose';

import { injectIntl } from 'react-intl';
import omitProps from 'react/hoc/omitProps';
import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import findLabelInOptions from 'react/generic/form/autocomplete/findLabelInOptions';
import { withProductFormName } from 'react/business/products/form/Context';

import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';

import { selectBusinessUnitById } from 'redux/businessUnits/selectors';
import { selectLocalOrganizationChidren } from 'redux/localOrganizations/selectors';
import messages from './localDomain.messages';

const mapLocalDomains = d => ({
  value: d.technicalUniqueId,
  label: d.name,
});

export default compose(
  injectIntl,

  withProductFormName(),

  connect(
    (state, { formName }) => {
      const buTechnicalId = selectBusinessUnitById(
        state,
        getFormValues(formName)(state).businessUnitInCharge?.value,
      )?.organizationEntityTechnicalUniqueId;
      const isMaintainedByPlatform = getFormValues(formName)(state).isMaintainedByPlatform === 'true';
      return {
        buTechnicalId,
        localDomains: buTechnicalId ? selectLocalOrganizationChidren(state, buTechnicalId) : [],
        isMaintainedByPlatform,
      };
    },
    {
      change: changeAction,
    },
  ),

  withProps(({
    intl,
    buTechnicalId,
    isMaintainedByPlatform,
    disabled,

  }) => ({
    isDisabled: !buTechnicalId || isMaintainedByPlatform || disabled,
    placeholder: !buTechnicalId || isMaintainedByPlatform ?
      intl.formatMessage(messages.LOCAL_PRODUCTS_ONLY)
      : undefined,
  })),

  omitProps(['intl']),

  withPropsOnChange(
    ['localDomains'],
    ({ localDomains }) => ({
      options: localDomains?.map(mapLocalDomains),
      hidden: !localDomains?.length,
     }),
  ),

  lifecycle({
    /**
     * Reset local domain when bu or 'maintained by platform' change.
     *
     * @param {object} prevProps - Previous props.
     */
    componentDidUpdate(prevProps) {
      if ((prevProps.buTechnicalId !== this.props.buTechnicalId)
        || (prevProps.isMaintainedByPlatform !== this.props.isMaintainedByPlatform)) {
        // Reset selected local domain.
        this.props.change(this.props.formName, 'localDomain', null);
      }
    },
  }),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,
  findLabelInOptions,
)(Autocomplete);
