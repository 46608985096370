import React, { memo } from 'react';
import { compose } from 'recompose';
import { Field } from 'redux-form';

import AutoCompleteLocalDomain from 'react/business/products/form/autocompletes/LocalDomain';
import { FormattedMessage } from 'react-intl';
import messages from './localDomain.messages';

const enhancer = compose(
  memo,
);

const LocalDomainField = () => (
  <Field
    name="localDomain"
    component={AutoCompleteLocalDomain}
    title={<FormattedMessage {...messages.FIELD_TITLE} />}
  />
);

LocalDomainField.displayName = 'LocalDomainField';

export default enhancer(LocalDomainField);
