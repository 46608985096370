import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { Tooltip, Checkbox } from 'antd';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { subsetsShape } from 'shapes/subsets';

import classNames from '../subset-selector.module.scss';

const enhancer = compose(
  withPropsOnChange(
    ['item', 'onToggleElement'],
    ({ item, onToggleElement }) => ({
      onToggleElement: event => onToggleElement(item.id, event.target.checked),
    }),
  ),

  memo,
);

const SubsetsListItem = ({
  item,
  checked,
  onToggleElement,
}) => (
  <div className={classNames.listElement}>
    <p className={classNames.elementTitleContainer}>
      {item.name}
      {item.description && (
      <Tooltip placement="top" title={item.description}>
        <span className={classNames.infoTooltip}>
          <Asset name={ICON_MAP.info} />
        </span>
      </Tooltip>
      )}
    </p>
    {checked !== undefined && <Checkbox checked={checked} onChange={onToggleElement} />}
  </div>
);

SubsetsListItem.displayName = 'SubsetsListItem';

SubsetsListItem.propTypes = {
  item: subsetsShape.isRequired,
  checked: PropTypes.bool,
  onToggleElement: PropTypes.func.isRequired,
};

SubsetsListItem.defaultProps = {
  checked: false,
};

export default enhancer(SubsetsListItem);
