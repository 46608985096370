import React, { memo } from 'react';
import { compose } from 'recompose';

import ProductLinks from 'react/business/products/links/ProductLinks';
import Rate from 'react/generic/rate/RateWithRoundedValue';
import RateLabel from 'react/generic/rate/RateLabel';
import Section from 'react/business/products/details/layout/section/Section';

import { productShape } from 'shapes/product';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import ProductStage from './stage/ProductStage';
import ProductShortDescription from './shortDescription/ShortDescription';
import classNames from './productSummary.module.scss';
import messages from '../../../../pages/product/productPage/productpage.messages';

const enhancer = compose(
  memo,
);

const ProductSummary = ({
  product,
  pageType,
}) => (
  <Section className={classNames.summary} pageType={pageType}>
    <div className={classNames.containerSummary}>
      <div className={classNames.leftContainer}>
        <ProductShortDescription
          shortDescription={product.shortDescription}
          softwarePublisher={product.softwarePublisher}
          isMaintainedByPlatform={product.isMaintainedByPlatform}
          hostingType={product.hostingType}
          parentDigitalProduct={product.parentDigitalProduct}
        />
      </div>
      <div className={classNames.rightContainer}>
        <ProductLinks links={product.links} className={classNames.links} pageType={pageType} />
        <div className={classNames.rightBottomContainer}>
          { product.marks?.count !== 0 && (
            <div className={classNames.positionMark}>
              <RateLabel
                className={classNames.sizeMark}
                mark={product.marks?.average}
              />
              <div className={classNames.stars}>
                <Rate value={product.marks?.average} disabled />
              </div>
              <div className={classNames.average}>({product.marks?.count || 0})</div>
            </div>
          )}
        </div>
        <div className={classNames.rightBottomContainer}>
          <ProductStage stage={product.stage} />
        </div>
        <div className={classNames.date}> <FormattedMessage
          {...messages.LAST_UPDATE}
        />{product.updated && moment(product.updated).format('L')}
        </div>
      </div>
    </div>
  </Section>
);

ProductSummary.displayName = 'ProductSummary';

ProductSummary.propTypes = {
  product: productShape.isRequired,
  pageType: PropTypes.string,
};

ProductSummary.defaultProps = {
  pageType: '',
};

export default enhancer(ProductSummary);
