import React, { memo } from 'react';
import { compose } from 'recompose';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { components } from 'react-select';

import SafeHtml from 'react/generic/html/SafeHtml';

import { ENUM_PLACEMENTS } from './tooltipOption.constants';

const enhancer = compose(
  memo,
);

const TooltipOption = ({ placement, ...props }) => (
  <Tooltip
    title={
      props?.data?.description ?
        <SafeHtml unsafeHtml={props?.data?.description} />
        : ''
    }
    placement={placement}
  >
    <div>
      <components.Option {...props} />
    </div>
  </Tooltip>
);

TooltipOption.displayName = 'TooltipOption';

TooltipOption.propTypes = {
  placement: PropTypes.string,
};

TooltipOption.defaultProps = {
  placement: ENUM_PLACEMENTS.TOP,
};

export default enhancer(TooltipOption);
