import { defineMessages } from 'react-intl';

export default defineMessages({
  DESCRIPTION: { id: 'product.titles.components', defaultMessage: 'Components' },
  ADD_A_COMPONENT: { id: 'product.titles.add_a_component', defaultMessage: 'Add a component' },
  ADD_THE_COMPONENT: { id: 'product.titles.add_the_component', defaultMessage: 'Add the component' },
  CREATE_A_COMPONENT: { id: 'product.titles.create_a_component', defaultMessage: 'Add a digital component' },
  LOOK_FOR_EXISTING_COMPONENT: { id: 'product.titles.look_for_existing_component', defaultMessage: 'Look for an existing component:' },
  OR_CREATE_NEW: { id: 'product.titles.create_new', defaultMessage: 'Or create a new one' },
  NEVER_MIND: { id: 'product.component.popup.nerver_mind', defaultMessage: 'Never mind' },
});
