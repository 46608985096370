import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';
import Link from 'react/generic/link/Link';
import { formatRoute } from 'react-router-named-routes';
import Button from '../../../../../generic/button/Button';
import classNames from './createComponentsButton.module.scss';
import Asset, { ICON_MAP } from '../../../../../generic/asset/Asset';
import ROUTES from '../../../../../routes';
import { productShape } from '../../../../../../shapes/product';

const enhancer = compose(
  memo,
);

const CreateComponentButton = ({ message, product }) => (
  <Link to={
    `${formatRoute(
      ROUTES.COMPONENT.CREATE_FORM.FORM,
      {
        step: COMPONENT_FORM_STEPS.PRESENTATION,
      },
    )}?parent=${product.id}`}
  >
    <Button
      overrideClassName={classNames.buttonCreate}
      overrideClassNames={classNames}
    >
      <Asset
        name={ICON_MAP.plus}
        className={classNames.icon}
      />
      <div>
        <FormattedMessage {...message} />
      </div>
    </Button>
  </Link>
);

CreateComponentButton.propTypes = {
  message: PropTypes.objectOf(PropTypes.string),
  product: productShape.isRequired,
};

CreateComponentButton.defaultProps = {
  message: {},
};

export default enhancer(CreateComponentButton);
