import { compose, withPropsOnChange } from 'recompose';
import { formValueSelector } from 'redux-form';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';

import { selectBusinessUnitsByIds } from 'redux/businessUnits/selectors';

import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';
import findLabelInOptions from 'react/generic/form/autocomplete/findLabelInOptions';

import { mapBu } from './businessUnits.constants';

export default compose(
  connect(
    (state, props) => ({
      businessUnitsUsingProduct: formValueSelector(props.formName)(state, 'businessUnitsUsingProduct'),
    }),
  ),

  connect(
    (state, props) => ({
      businessUnits: selectBusinessUnitsByIds(
        state,
        props.businessUnitsUsingProduct.map(bu => bu.value),
      ),
    }),
  ),

  withPropsOnChange(
    ['businessUnits'],
    ({ businessUnits }) => ({
      options: Object.values(businessUnits).map(mapBu),
    }),
  ),

  omitProps([
    'businessUnitsUsingProduct',
    'businessUnits',
  ]),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,

  findLabelInOptions,
)(Autocomplete);
