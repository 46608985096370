import { withHandlers, withPropsOnChange, compose } from 'recompose';
import queryString from 'query-string';
import { pick } from 'lodash';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router-dom';

import { updateQueryString } from 'services/querystring';
import ROUTES from 'react/routes';
import { PRODUCT_VIEW } from 'config/constants';

import { FILTERS } from '../reviews.constants';

// Ensure we always have an array.
const ensureArray = elt => (elt ? [].concat(elt) : []);

/**
 * Get filters value from the query string.
 * Also provides default values if needed.
 *
 * @param {object} location - React router location.
 * @returns {object} - Filter values.
 */
const getFiltersFromQueryString = ({ location }) => {
  const search = queryString.parse(location.search);
  const filters = pick(search, Object.values(FILTERS));

  return {
    ...filters,
    [FILTERS.ORDER_BY]: filters[FILTERS.ORDER_BY],
    [FILTERS.MARKS]: ensureArray(filters[FILTERS.MARKS]).map(Number),
  };
};

export const withFilters = withPropsOnChange(
  ['location'],
  ({ location }) => ({
    filters: getFiltersFromQueryString({ location }),
  }),
);

export const withOnSetFilters = (
  productIdSelector = props => props.productId,
) => withHandlers({
  onSetFilter: props => (key, value) => {
    const qs = updateQueryString(key, value, props.location.search);
    props.history.push(`${formatRoute(
      ROUTES.PRODUCT.PRESENTATION,
      {
        productId: productIdSelector(props),
        view: PRODUCT_VIEW.REVIEWS,
      },
    )}?${qs}`);
  },
});

export default productIdSelector => compose(
  withRouter,
  withFilters,
  withOnSetFilters(productIdSelector),
);
