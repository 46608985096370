import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { userShape } from 'shapes/user';
import { teamMemberShape } from 'shapes/product';

import UserName from 'react/generic/users/name/UserName';

import withUserNames from 'react/business/products/details/team/list/connect/team.connect';
import classNames from './validator-person.module.scss';
import ContactButton from '../../../../../business/products/details/team/list/button/ContactButton';

const enhancer = compose(
  withUserNames,
  memo,
);

const ValidatorPerson = ({
  teamMembers,
  users,
}) => {
  const teamMember = users[teamMembers[0].externalId];

  if (!teamMember) {
    return null;
  }
  return (

    <div className={classNames.teamMemberElement}>
      <div className={classNames.userName}>
        <UserName user={teamMember} />
      </div>

      {teamMember.externalId
        && (
          <div className={classNames.contact}>
            <ContactButton externalId={teamMember.externalId} theme="dark" />

          </div>
        )}
    </div>
  );
};

ValidatorPerson.displayName = 'ProductTeamMember';

ValidatorPerson.propTypes = {
  teamMembers: PropTypes.arrayOf(teamMemberShape).isRequired,
  // Users indexed by externalId
  users: PropTypes.objectOf(userShape),
};

ValidatorPerson.defaultProps = {
  users: {},
};

export default enhancer(ValidatorPerson);
