import React from 'react';
import PropTypes from 'prop-types';

import classNames from './error.module.scss';

const ErrorMessage = ({
  error,
}) => (
  <div className={classNames.error}>
    {error}
  </div>
);

ErrorMessage.displayName = 'ErrorMessage';

ErrorMessage.propTypes = {
  error: PropTypes.node.isRequired,
};

export default ErrorMessage;
