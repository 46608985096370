import React, { memo } from 'react';
import { compose, lifecycle } from 'recompose';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { productShape } from 'shapes/product';

import { FormattedMessage } from 'react-intl';
import connect from 'react/hoc/connectProxy';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';
import connectProductTeam from 'react/business/products/details/team/list/connect/team.connect';
import ProductTeamList from 'react/business/products/details/team/list/List';

import { selectBusinessUnitById } from 'redux/businessUnits/selectors';
import { getBusinessUnits } from 'redux/businessUnits/actions';
import { businessUnitShapeNotReq } from 'shapes/businessUnit';
import { getLocalOrganizationChildren } from 'redux/localOrganizations/actions';
import { selectLocalDomainById } from 'redux/localOrganizations/selectors';
import NoTeam from './list/NoTeam';
import messages from './product-team.messages';
import classNames from './product-team.module.scss';

const ConnectedProductTeamList = connectProductTeam(ProductTeamList);

const enhancer = compose(
  connect(
    (state, props) => ({
      businessUnitInCharge: selectBusinessUnitById(state, props.product.businessUnitInCharge),
      localDomainLabel: selectLocalDomainById(state, props.product.localDomain)?.name,
    }),
    {
      getBusinessUnits,
      getLocalOrganizationChildren,
    },
  ),
  lifecycle({
    /** Load Business unit on mount. */
    async componentDidMount() {
      if (this.props.product.businessUnitInCharge) {
        const result = await this.props.getBusinessUnits([this.props.product.businessUnitInCharge]);
        const buInCharge = result.businessUnits.find(
          bu => bu.id === this.props.product.businessUnitInCharge,
        );
        if (buInCharge?.organizationEntityTechnicalUniqueId) {
          this.props.getLocalOrganizationChildren(buInCharge.organizationEntityTechnicalUniqueId);
        }
      }
    },
  }),

  memo,
);

const ProductTeam = ({
  product,
  businessUnitInCharge,
  localDomainLabel,
  pageType,
}) => (
  <Section theme="dark" pageType={pageType}>
    <SectionTitle centered>
      {
        pageType === 'product' ?
          <FormattedMessage {...messages.PRODUCT_MANAGERS} />
        : <FormattedMessage {...messages.TEAM} />
      }
      { product.businessUnitInCharge && (
        <div className={classNames.subTitle}>
          <FormattedMessage
            {...messages.BUSINESS_UNIT_LEADER}
            values={{ name: businessUnitInCharge.name }}
          />
        </div>
      ) }
      { localDomainLabel && (
        <div className={classNames.subsubTitle}>
          <FormattedMessage
            {...messages.BUSINESS_UNIT_ORGANIZATIONAL_DOMAIN}
            values={{ name: localDomainLabel }}
          />
        </div>
      ) }
    </SectionTitle>

    {!isEmpty(product.teamMembers) ? (
      <div>
        <ConnectedProductTeamList
          teamMembers={product.teamMembers}
          theme="light"
          pageType={pageType}
        />
      </div>
    ) : (
      <NoTeam author={product.author} theme="light" />
    ) }
  </Section>
);

ProductTeam.displayName = 'ProductTeam';

ProductTeam.propTypes = {
  product: productShape.isRequired,
  businessUnitInCharge: businessUnitShapeNotReq,
  localDomainLabel: PropTypes.string,
  pageType: PropTypes.string,
};

ProductTeam.defaultProps = {
  businessUnitInCharge: {},
  localDomainLabel: null,
  pageType: '',
};

export default enhancer(ProductTeam);
