import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import manifest from './features.manifest';

import messages from './features.messages';

const {
  fields: {
    screenshots: { component: ScreenshotsField },
    tagsMisc: { component: TagsMiscField },
  },
} = manifest;

const enhancer = compose(
  memo,
);

const ProductFormFeaturesForm = () => (
  <>
    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_SCREENSHOTS} />}
        message={<FormattedMessage {...messages.SCREENSHOTS_HINT} />}
        isComponent={false}
      />

      <ScreenshotsField />
    </FieldsGroup>

    <TagsMiscField />
  </>
);

export default enhancer(ProductFormFeaturesForm);
