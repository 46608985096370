import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/suggestions/constants';
import { patchProductSuggestion } from 'redux/suggestions/actions';
import { isLoadingPatchProductSuggestion } from 'redux/suggestions/selectors';

export default compose(
  connect(
    state => ({
      isSubmitting: isLoadingPatchProductSuggestion(state),
    }),
    (dispatch, props) => ({
      onSubmit: async (suggestion) => {
        const suggestionPayload = {
          ...suggestion,
          id: props.suggestion.id,
        };
        const action = await dispatch(
          patchProductSuggestion(props.productId, suggestionPayload, true),
        );

        if (action.type === ACTIONS.PATCH_PRODUCT_SUGGESTION_SUCCESS) {
          return props.onSubmitSuccess?.(action.suggestion);
        }
        return props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
