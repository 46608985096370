import React, { memo } from 'react';
import { compose, withProps } from 'recompose';

import { FormattedMessage } from 'react-intl';

import SuggestionDialogStepForm from 'react/business/suggestion/form/dialog/base/steps/form/Form';

import messages from './form.messages';

export default compose(
  memo,

  withProps(() => ({
    title: <FormattedMessage {...messages.SUGGESTION_FORM_DIALOG_TITLE} />,
    submitMessage: <FormattedMessage {...messages.SUBMIT} />,
  })),
)(SuggestionDialogStepForm);
