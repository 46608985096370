import { defineMessages } from 'react-intl';

export default defineMessages({
  ADD_NEW_TEAM_MEMBER: {
    id: 'team.form.add.new.member.button.label',
    defaultMessage: 'Add a new team member',
  },
  ROLE: {
    id: 'team.form.input.role.label',
    defaultMessage: 'Role',
  },
  MEMBER: {
    id: 'team.form.input.member.label',
    defaultMessage: 'Team member',
  },
  BUSINESS_UNIT_TOOLTIP: {
    id: 'team.form.input.bu.tooltip',
    defaultMessage: 'This list is based on the list of BU declared as using this product on step 4.',
  },
});
